<!-- DetailCastPage -->
<template>
  <div class="detail-item-page-contaner">
    <div class="detail-item-page-contaner-left-container">
      <div class="detail-item-page-contaner-left-container-bottom-container">
        <div class="detail-item-page-contaner-left-container-bottom-container-info">
          <div class="detail-item-page-contaner-left-container-bottom-container-info-name">
            {{ directorInfo?.name }}
          </div>
          <div class="detail-item-page-contaner-left-container-bottom-container-info-born">
            {{directorInfo?.born}}
          </div>
          <div class="detail-item-page-contaner-left-container-bottom-container-info-introduction">
            {{directorInfo?.introduction}}
          </div>
        </div>
        <div class="detail-item-page-contaner-left-container-bottom-container-info-pic"
             v-show="imgList && imgList.length > 0 && !isMobile">
          <photoPage :dataList="imgList"></photoPage>
        </div>
        <div class="detail-item-page-contaner-left-container-bottom-container-discribe"
             style="white-space: pre-line;">
          {{directorInfo?.detail}}
        </div>
        <div class="detail-item-page-contaner-left-container-bottom-container-influence-container"
             v-show="influenceList && influenceList.length > 0">
          <div class="detail-item-page-contaner-left-container-bottom-container-influence-container-title">
            <img class="detail-item-page-contaner-left-container-bottom-container-influence-container-img"
                 src="@/assets/detail/influence.png"
                 alt="">
            <div class="detail-item-page-contaner-left-container-bottom-container-influence-container-influence">
              {{$t('detail_influence')}}
            </div>
          </div>
          <div class="detail-item-page-contaner-left-container-bottom-container-influence-container-scroll">
            <influence :influenceListData="influenceList"></influence>
          </div>
        </div>
      </div>
    </div>
    <div class="detail-item-page-contaner-right-container">
      <div class="detail-item-page-contaner-right-container-work-style-container"
           v-show="directorInfo?.workStyle && directorInfo?.workStyle.length > 0">
        <img src="@/assets/detail/work-style.png"
             alt="">
        <span>{{$t('detail_work_style')}}</span>
      </div>
      <div class="detail-item-page-contaner-right-container-work-style-container-detail"
           v-show="directorInfo?.workStyle && directorInfo?.workStyle.length > 0">
        <workStyle :workStyleList="directorInfo?.workStyle"></workStyle>
      </div>
      <div class="detail-item-page-contaner-right-container-masterpiece-container">
        <div :class="{
        'detail-item-page-contaner-right-container-masterpiece-item-select': masterpieceIsSelect,
        'detail-item-page-contaner-right-container-masterpiece-item-normal': !masterpieceIsSelect
              }"
             v-show="showMasterpiece"
             @click="masterpieceHandleClick()">
          {{$t('detail_masterpiece')}}
        </div>
        <div :class="{
        'detail-item-page-contaner-right-container-masterpiece-item-select': upcomingIsSelect,
        'detail-item-page-contaner-right-container-masterpiece-item-normal': !upcomingIsSelect
              }"
             v-show="showUpcoming"
             @click="upcomingHandleClick()">
          {{$t('detail_upcoming')}}
        </div>
      </div>

      <div class="detail-item-page-contaner-right-container-masterpiece-container-scroll-container"
           v-show="dataList && dataList.length > 0">
        <masterpiece :detailData="detailData"
                     :dataList="dataList"
                     @clickAndScrollToTop="handleScrollToTop"></masterpiece>

      </div>
    </div>
  </div>
</template>

<script>
import DetailWorkStylePage from '@/views/Detail/New/DetailWorkStylePage.vue'
import StoreDetailMasterpiecePage from '@/views/store/StoreDetailMasterpiecePage.vue'
import StoreDetailPhotoPage from '@/views/store/StoreDetailPhotoPage.vue'
import StoreDetailInfluencePage from '@/views/store/StoreDetailInfluencePage.vue'

import { castDetailApi, detailInfoApi, directorDetailApi, screenwriterDetailApi } from '@/api/detailApi.js'
import { getPresignedUrlApi } from '@/api/homeApi.js'
import { mapState, mapActions } from 'vuex';

export default {
  components: {
    workStyle: DetailWorkStylePage,
    masterpiece: StoreDetailMasterpiecePage,
    influence: StoreDetailInfluencePage,
    photoPage: StoreDetailPhotoPage
  },
  computed: {
    ...mapState('collectDialog', ['projectId']),
    ...mapState('deatilEra', ['eraId']),
    ...mapState('auth', ['isLoggedIn'])
  },
  props: {
    detailPageData: {
      type: Object,
      required: true
    },

    selectCastItem: {
      type: Object,
      required: true
    }
  },
  created () {
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    if (mediaQuery.matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()

    this.$store.watch(
      (state) => state.collectDialog.hasCollectSuccess,
      (newVal) => {
        if (newVal) {
          if (this.selectItem.collectionFlag == 0) {
            if (this.collectType == 0 && this.selectItem.id == this.projectId) {
              console.log('DetailPageTrailersCast-Banner-hasCollectSuccess:', newVal);

              this.dataList = this.dataList.map(item => {
                if (item.id === this.selectItem.id) {
                  console.log('Banner-匹配数据:', item.id === this.selectItem.id);
                  item.collectionFlag = 1;
                }
                return item;
              });
              this.setCollectNormalDialog()

            } else if (this.collectType == 1 && this.selectItem.id == this.projectId) {
              console.log('DetailPageTrailersCast-Recommend-hasCollectSuccess:', newVal);

              this.newRelatedList = this.newRelatedList.map(item => {
                if (item.id === this.selectItem.id) {
                  console.log('Recommend-匹配数据:', item.id === this.selectItem.id);
                  item.collectionFlag = 1;
                }
                return item;
              });
              this.setCollectNormalDialog()

            }
          }
        }
      }
    );
    this.resetData()
    // this.fetchDetailData(this.selectCastItem);
  },

  watch: {
    detailPageData (newValue) {
      if (newValue) {
        console.log("DetailCastPage-detailPageData:", newValue);
      }
    },
    selectCastItem (newValue) {
      if (newValue) {
        console.log("DetailCastPage-selectedItem:", newValue);
        this.resetData()
        console.log("DetailCastPage-handleSelectItemData:", newValue);
        this.fetchDetailData(newValue);
      }
    }
  },

  data () {
    return {
      detailData: {},
      newCastsList: [],
      newSelectedItem: undefined,
      selectedDirectorIndex: 0,
      newMenuList: [],
      directorInfo: {},
      influenceList: [],
      imgList: [],
      scrollPosition: 0,
      maxScroll: 0,
      showMasterpiece: false,
      showUpcoming: false,
      masterpieceIsSelect: false,
      upcomingIsSelect: false,
      bannerCurrentIndex: 0, //banner菜单栏
      dataList: [],
      masterpieceBannerList: [],

      upcomingBannerList: [],

      selectItem: {},
      collectType: 0, //0:BannerCollect 1:RecommendCollect
      newRelatedList: [],
      languageType: "",
      isMobile: false,
      showLeftScroll: false,
      showRightScroll: false,
    }
  },
  methods: {
    ...mapActions('collectDialog', ['showCollectOfWorksDialog', 'setProjectIdDialog', 'hasCollectSuccess', 'setCollectNormalDialog']),
    ...mapActions('dialog', ['showLoginDialog']),

    reloadLocationLanguageData () {
      const language = localStorage.getItem('locationLanguageType')
      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    resetData () {
      this.selectedDirectorIndex = 0
      this.newMenuList = []
      this.directorInfo = {}
      this.influenceList = []
      this.scrollPosition = 0
      this.maxScroll = 0
      this.showMasterpiece = false
      this.showUpcoming = false
      this.bannerCurrentIndex = 0
      this.dataList = []
      this.masterpieceBannerList = []
      this.upcomingBannerList = []
      this.selectItem = {}
      this.collectType = 0
      this.imgList = []
      this.masterpieceIsSelect = false
      this.upcomingIsSelect = false
    },

    gotoDetail (item) {
      if (this.detailData.id === item.id) {
        this.$emit('clickAndScrollToTop');
        return;
      }
      // 在详情页中再次导航到另一个详情页
      const targetPath = item.id + '-' + item.projectNameEn.trim().replace(/\s+/g, "-");
      if (this.$route.path !== targetPath) {
        console.log("eraId:", this.eraId);
        this.$router.push(targetPath);
      }
    },

    bannerTopCollectClick (item, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      if (this.isLoggedIn) {
        this.selectItem = item
        this.collectType = 0

        this.showCollectOfWorksDialog()
        this.setProjectIdDialog(item.id)
        console.log("projectId:", this.projectId);
      } else {
        this.showLoginDialog()
      }
    },

    topCollectClick (item, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      if (this.isLoggedIn) {
        this.selectItem = item
        this.collectType = 1

        console.log("topCollectClick:", item.id);
        this.showCollectOfWorksDialog()
        this.setProjectIdDialog(item.id)
        console.log("projectId:", this.projectId);
      } else {
        this.showLoginDialog()
      }
    },

    bannerClick (id, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      console.log("projectId:", id);

      // 在详情页中再次导航到另一个详情页
      const targetPath = '/detail/' + id;
      console.log("targetPath:", targetPath);
      console.log("this.$route.path:", this.$route.path);
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath);
      }
    },

    masterpieceHandleClick () {
      this.dataList = this.masterpieceBannerList
      this.masterpieceIsSelect = true;
      this.upcomingIsSelect = false;
    },

    upcomingHandleClick () {
      this.dataList = this.upcomingBannerList
      this.masterpieceIsSelect = false;
      this.upcomingIsSelect = true;
    },

    //获取主演数据
    async fetchDetailData (item) {
      const data = {
        name: item.name,
        language: this.languageType
      };
      try {
        const apiMap = {
          [this.$t('detail_director')]: directorDetailApi,
          [this.$t('detail_script_writer')]: screenwriterDetailApi,
          [this.$t('detail_cast')]: castDetailApi
        };


        const selectedApi = apiMap[item.selectItem];
        const res = await selectedApi(data);
        if (res.data.code === 200) {
          const resultData = res.data.data;
          console.log("获取演员数据:", resultData);
          this.directorInfo = resultData;
          if (this.directorInfo.workStyle) {
            this.directorInfo.workStyle = this.directorInfo.workStyle.sort((a, b) => parseInt(a.number) - parseInt(b.number));
          }
          this.influenceList = resultData.influence

          //头像
          if (resultData.img.mode === 2) {
            this.directorInfo.imageUrl = resultData.img.imgUrl;
          } else if (resultData.img.mode === 1) {
            try {
              const imageRes = await getPresignedUrlApi(resultData.img.fileId);
              if (imageRes.data.code === 200) {
                this.directorInfo.imageUrl = imageRes.data.data.url;
              }
            } catch (error) {
              // 错误处理
            }
            console.log("获取-头像-URL数据:", this.directorInfo.imageUrl);
          }

          //照片
          const imgListResultData = await Promise.all(
            resultData.img.map(async (element) => {
              if (element.mode === 1) {
                try {
                  const imageRes = await getPresignedUrlApi(element.fileId);
                  if (imageRes.data.code === 200) {
                    element.imgUrl = imageRes.data.data.url;
                    return element;
                  }
                } catch (error) {
                  // 错误处理
                }
              } else if (element.mode === 2) {
                return element;
              }
            })
          );
          this.imgList = imgListResultData.filter(Boolean); // 过滤掉可能为 undefined 的元素
          console.log("获取主演-imgList数据:", this.imgList);

          //Masterpiece
          const masterpieceBannerListDataResultData = await Promise.all(
            resultData.masterpieceList.map(async (element) => {
              try {
                const imageRes = await getPresignedUrlApi(element.imageId[0]);
                if (imageRes.data.code === 200) {
                  element.imageUrl = imageRes.data.data.url;
                  return element;
                }
              } catch (error) {
                // 错误处理
              }
            })
          );
          this.masterpieceBannerList = masterpieceBannerListDataResultData.filter(Boolean); // 过滤掉可能为 undefined 的元素
          console.log("获取主演-Masterpiece-banner数据:", this.masterpieceBannerList);


          //Upcoming
          const upcomingBannerListDataResultData = await Promise.all(
            resultData.upcomingList.map(async (element) => {
              try {
                const imageRes = await getPresignedUrlApi(element.imageId[0]);
                if (imageRes.data.code === 200) {
                  element.imageUrl = imageRes.data.data.url;
                  return element;
                }
              } catch (error) {
                // 错误处理
              }
            })
          );
          this.upcomingBannerList = upcomingBannerListDataResultData.filter(Boolean); // 过滤掉可能为 undefined 的元素
          console.log("获取主演-Upcoming-banner数据:", this.upcomingBannerList);

          this.masterpieceIsSelect = false;
          this.upcomingIsSelect = false;

          if (this.masterpieceBannerList && this.masterpieceBannerList.length > 0) {
            this.showMasterpiece = true
            this.masterpieceIsSelect = true
          } else {
            this.showMasterpiece = false
            this.masterpieceIsSelect = false
          }
          if (this.upcomingBannerList && this.upcomingBannerList.length > 0) {
            this.showUpcoming = true
            if (this.showMasterpiece === false) {
              this.upcomingIsSelect = true
            }
          } else {
            this.showUpcoming = false
            this.upcomingIsSelect = false
          }

          if (this.masterpieceBannerList && this.masterpieceBannerList.length > 0) {
            this.dataList = this.masterpieceBannerList
          } else {
            this.dataList = this.upcomingBannerList
          }
        }
      } catch (error) {
        // 处理错误，可以在这里记录日志或者进行其他处理
      }
    },
    handleScrollToTop () {
      this.$emit('clickAndScrollToTop');
    },
  }
}
</script>

<style scoped>
.detail-item-page-contaner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.detail-item-page-contaner-left-container {
  width: 52%;
  height: 100%;
  margin-top: 20px;
}

.detail-item-page-contaner-left-container-top-container {
  width: 95%;
  height: 22%;
}

.detail-item-page-contaner-left-container-bottom-container {
  height: 70%;
  margin-left: 5%;
  margin-right: 5%;
}

.detail-item-page-contaner-left-container-bottom-container-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}

.detail-item-page-contaner-left-container-bottom-container-info-name {
  color: #f5f5f5;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
  margin-right: 2.5%;
  white-space: nowrap;
}

.detail-item-page-contaner-left-container-bottom-container-info-born {
  color: #a8a8a8;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  margin-right: 5%;
}

.detail-item-page-contaner-left-container-bottom-container-info-introduction {
  color: #a8a8a8;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.detail-item-page-contaner-left-container-bottom-container-info-pic {
  width: 100%;
  height: 140px;
  margin-top: 15px;
  margin-right: 40px;
}

.detail-item-page-contaner-left-container-bottom-container-discribe {
  margin-top: 10px;
  overflow: auto;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  line-height: 1.8;
  height: 106px;
}
.detail-item-page-contaner-left-container-bottom-container-influence-container {
  width: 100%;
  margin-top: 20px;
}

.detail-item-page-contaner-left-container-bottom-container-influence-container-title {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detail-item-page-contaner-left-container-bottom-container-influence-container-img {
  width: 4%;
  height: 4%;
  margin-right: 3%;
}

.detail-item-page-contaner-left-container-bottom-container-influence-container-influence {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
}

.detail-item-page-contaner-left-container-bottom-container-influence-container-scroll {
  width: 100%;
  height: 90px;
  margin-top: 15px;
}

.detail-item-page-contaner-right-container {
  width: 48%;
  height: 100%;
  display: flex;
  flex-direction: column;
  margin-left: 2%;
  margin-top: 35px;
  margin-right: 2%;
}

.detail-item-page-contaner-right-container-work-style-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.detail-item-page-contaner-right-container-work-style-container img {
  width: 22px;
  height: auto;
  margin-right: 17px;
}

.detail-item-page-contaner-right-container-work-style-container span {
  color: #f5f5f5;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
}

.detail-item-page-contaner-right-container-work-style-container-detail {
  width: 100%;
  height: 200px;
}

.detail-item-page-contaner-right-container-masterpiece-container {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
}

.detail-item-page-contaner-right-container-masterpiece-item-normal {
  color: #f5f5f5;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  border: 1px solid #fff;
  width: 95px;
  height: 22px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-right: 5%;
  cursor: pointer;
  padding-bottom: 3px;
}

.detail-item-page-contaner-right-container-masterpiece-item-select {
  color: #f5f5f5;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  width: 95px;
  height: 24px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  margin-right: 5%;
  cursor: pointer;
  padding-bottom: 3px;
}

.detail-item-page-contaner-right-container-masterpiece-container-scroll-container {
  margin-top: 10px;
  height: 180px;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-right: 40px;
  width: 92%;
  overflow: hidden;
}

::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 3px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 6px;
}
</style>

<style scoped>
/* PC设备 */
@media only screen and (min-width: 1920px) {
  .detail-item-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-left-container {
    width: calc(100% - 680px);
    height: 100%;
    margin-top: 20px;
  }

  .detail-item-page-contaner-left-container-top-container {
    width: 95%;
    height: 22%;
  }

  .detail-item-page-contaner-left-container-bottom-container {
    height: 100%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-name {
    color: #f5f5f5;
    font-size: 18px;
    font-family: Source Han Sans SC-Medium;
    margin-right: 2.5%;
    white-space: nowrap;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-born {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-introduction {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-pic {
    width: 100%;
    height: 200px;
    margin-top: 15px;
    margin-right: 40px;
  }

  .detail-item-page-contaner-left-container-bottom-container-discribe {
    margin-top: 10px;
    overflow: auto;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.8;
    height: 136px;
  }
  .detail-item-page-contaner-left-container-bottom-container-influence-container {
    width: 100%;
    margin-top: 20px;
  }

  .detail-item-page-contaner-left-container-bottom-container-influence-container-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detail-item-page-contaner-left-container-bottom-container-influence-container-img {
    width: 20px;
    margin-right: 15px;
  }

  .detail-item-page-contaner-left-container-bottom-container-influence-container-influence {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
  }

  .detail-item-page-contaner-left-container-bottom-container-influence-container-scroll {
    width: 100%;
    height: 100px;
    margin-top: 10px;
  }

  .detail-item-page-contaner-right-container {
    width: 680px;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-top: 35px;
    margin-right: 2%;
  }

  .detail-item-page-contaner-right-container-work-style-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detail-item-page-contaner-right-container-work-style-container img {
    width: 22px;
    height: auto;
    margin-right: 17px;
  }

  .detail-item-page-contaner-right-container-work-style-container span {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
  }

  .detail-item-page-contaner-right-container-work-style-container-detail {
    width: 100%;
    height: 230px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-normal {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    border: 1px solid #fff;
    width: 95px;
    height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-select {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    width: 95px;
    height: 24px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container-scroll-container {
    margin-top: 20px;
    height: 230px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-right: 40px;
    width: 92%;
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  .detail-item-page-contaner {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .detail-item-page-contaner-left-container {
    width: 52%;
    height: 100%;
    margin-top: 0px;
  }

  .detail-item-page-contaner-left-container-top-container {
    width: 95%;
    height: 22%;
  }

  .detail-item-page-contaner-left-container-bottom-container {
    height: 70%;
    margin-left: 5%;
    margin-right: 5%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 10px;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-name {
    color: #f5f5f5;
    font-size: 16px;
    font-family: Source Han Sans SC-Medium;
    margin-right: 2.5%;
    white-space: nowrap;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-born {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5%;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-introduction {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .detail-item-page-contaner-left-container-bottom-container-info-pic {
    width: 100%;
    height: 140px;
    margin-top: 15px;
    margin-right: 40px;
  }

  .detail-item-page-contaner-left-container-bottom-container-discribe {
    margin-top: 10px;
    overflow: auto;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.4;
    height: 196px;
  }
  .detail-item-page-contaner-left-container-bottom-container-influence-container {
    width: 100%;
    margin-top: 20px;
  }

  .detail-item-page-contaner-left-container-bottom-container-influence-container-title {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detail-item-page-contaner-left-container-bottom-container-influence-container-img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .detail-item-page-contaner-left-container-bottom-container-influence-container-influence {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
  }

  .detail-item-page-contaner-left-container-bottom-container-influence-container-scroll {
    width: 100%;
    height: 80px;
    margin-top: 15px;
  }

  .detail-item-page-contaner-right-container {
    width: 48%;
    height: 100%;
    display: flex;
    flex-direction: column;
    margin-left: 2%;
    margin-top: 15px;
    margin-right: 2%;
  }

  .detail-item-page-contaner-right-container-work-style-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .detail-item-page-contaner-right-container-work-style-container img {
    width: 22px;
    height: auto;
    margin-right: 17px;
    margin-top: 5px;
  }

  .detail-item-page-contaner-right-container-work-style-container span {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
  }

  .detail-item-page-contaner-right-container-work-style-container-detail {
    width: 100%;
    height: 110px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container {
    display: flex;
    flex-direction: row;
    margin-top: 5px;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-normal {
    color: #f5f5f5;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    border: 1px solid #fff;
    width: 95px;
    height: 20px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-item-select {
    color: #f5f5f5;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    width: 95px;
    height: 22px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-right: 5%;
    cursor: pointer;
    padding-bottom: 3px;
  }

  .detail-item-page-contaner-right-container-masterpiece-container-scroll-container {
    margin-top: 10px;
    height: 180px;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    margin-right: 40px;
    width: 92%;
    overflow: hidden;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>