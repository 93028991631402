<!-- Contact Seller弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="show">
    <div class="pop-mask"
         @click="close"></div>
    <div class="pop-dialog-bg">
      <div class="pop-dialog">
        <div class="pop-close">
          <span> </span>
          <img src="@/assets/login/close-no-cicle.png"
               @click="close"
               alt="" />
        </div>
        <span class="pop-title">{{$t('detail_contact') + '：' + issuerInfo.nickname}}</span>
        <div class="contact-container">
          <div v-if="issuerInfo.wechat"
               @click="clickWechat">
            <img src="@/assets/store/contact_wechat.png"
                 class="contact-container-head"
                 alt="">
            <span> {{ issuerInfo.wechat }}
            </span>
            <img src="@/assets/store/contact_copy.png"
                 class="contact-container-copy"
                 alt="">
          </div>
          <div v-if="issuerInfo.whatsapp"
               @click="clickWhatsapp">
            <img src="@/assets/store/contact_whatsapp.png"
                 class="contact-container-head"
                 alt="">
            <span> {{ issuerInfo.whatsapp }}
            </span>
            <img src="@/assets/store/contact_copy.png"
                 class="contact-container-copy"
                 alt="">
          </div>

          <div v-if="issuerInfo.mobile && isMobile"
               @click="mobileClickPhone">
            <img src="@/assets/store/contact_mobile_phone.png"
                 class="contact-container-head"
                 alt="">
            <span class="contact-container-phone"> {{ issuerInfo.mobile }}
            </span>
          </div>

          <div v-else-if="issuerInfo.mobile && !isMobile"
               @click="clickPhone">
            <img src="@/assets/store/contact_web_phone.png"
                 class="contact-container-head"
                 alt="">
            <span> {{ issuerInfo.mobile }}
            </span>
            <img src="@/assets/store/contact_copy.png"
                 class="contact-container-copy"
                 alt="">
          </div>
          <div v-if="issuerInfo.tel && isMobile"
               @click="mobileClickTel">
            <img src="@/assets/store/contact_mobile_tel.png"
                 class="contact-container-head"
                 alt="">
            <span class="contact-container-tel"> {{ issuerInfo.tel }}
            </span>
          </div>
          <div v-else-if="issuerInfo.tel && !isMobile"
               @click="clickTel">
            <img src="@/assets/store/contact_web_tel.png"
                 class="contact-container-head"
                 alt="">
            <span> {{ issuerInfo.tel }}
            </span>
            <img src="@/assets/store/contact_copy.png"
                 class="contact-container-copy"
                 alt="">
          </div>

          <div v-if="issuerInfo.email"
               @click="clickEmail">
            <img src="@/assets/store/contact_email.png"
                 class="contact-container-head"
                 alt="">
            <span class="contact-container-email"> {{ issuerInfo.email }}
            </span>
          </div>
        </div>
        <div class="pop-dialog-tips"
             v-show="showTips">
          <div class="pop-dialog-tips-text">
            {{ $t('successful_operation')}}
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {

  components: {

  },
  props: {
    issuerInfo: {},
  },
  data () {
    return {
      inputContent: '',
      show: false,
      userInfo: {},
      languageType: "",
      isMobile: false,
      showTips: false,
    }
  },

  created () {
    // 创建一个 MediaQueryList 对象，传入媒体查询条件
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    // 检查是否匹配指定的媒体查询条件
    if (mediaQuery.matches) {
      // 在此处执行适应小屏幕的代码
      console.log('屏幕宽度小于等于 1024px');
      this.isMobile = true;
    } else {
      // 在此处执行适应大屏幕的代码
      console.log('屏幕宽度大于 1024px');
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()
  },

  methods: {
    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },
    open () {
      this.show = true
      document.body.style.overflow = 'hidden'
      this.name = this.customerName
    },
    close () {
      document.body.style.overflow = 'auto'
      this.$el.addEventListener(
        'animationend',
        () => {
          this.show = false
        },
        { once: true }
      )
      this.$el.classList.remove('fade-in')
      this.$el.classList.add('fade-out')
    },
    clickWechat () {
      console.log('点击Wechat');

      this.$copyText(this.issuerInfo.wechat).then(
        // 成功复制时的回调
        () => {
          console.log('wechat已复制成功！');
          this.showTips = true;
          setTimeout(() => {
            this.showTips = false;
          }, 2000);
        },
        // 复制失败时的回调
        () => {
          console.log('wechat复制失败，请手动复制！');
        }
      );
    },
    clickWhatsapp () {
      console.log('点击Whatsapp');
      this.$copyText(this.issuerInfo.whatsapp).then(
        // 成功复制时的回调
        () => {
          console.log('Whatsapp已复制成功！');
          this.showTips = true;
          setTimeout(() => {
            this.showTips = false;
          }, 2000);
        },
        // 复制失败时的回调
        () => {
          console.log('Whatsapp复制失败，请手动复制！');
        }
      );

    },
    mobileClickPhone () {
      console.log('点击手机端 Phone');
      // 使用window.open打开电话拨号页面
      // window.open('tel:' + this.issuerInfo.mobile);
      // 使用tel协议直接拨打电话
      window.location.href = 'tel:' + this.issuerInfo.mobile;
    },
    clickPhone () {
      console.log('点击 Phone');
      this.$copyText(this.issuerInfo.mobile).then(
        // 成功复制时的回调
        () => {
          console.log('手机号已复制成功！');
          this.showTips = true;
          setTimeout(() => {
            this.showTips = false;
          }, 2000);
        },
        // 复制失败时的回调
        () => {
          console.log('手机号复制失败，请手动复制！');
        }
      );
    },
    mobileClickTel () {
      console.log('点击手机端 Tel');

      // 使用window.open打开电话拨号页面
      // window.open('tel:' + this.issuerInfo.tel);
      // 使用tel协议直接拨打电话
      window.location.href = 'tel:' + this.issuerInfo.tel;
    },
    clickTel () {
      console.log('点击 Tel');
      this.$copyText(this.issuerInfo.tel).then(
        // 成功复制时的回调
        () => {
          console.log('tel已复制成功！');
          this.showTips = true;
          setTimeout(() => {
            this.showTips = false;
          }, 2000);
        },
        // 复制失败时的回调
        () => {
          console.log('tel复制失败，请手动复制！');
        }
      );

    },
    clickEmail () {
      console.log('点击 email');
      const email = this.issuerInfo.email;
      const subject = '';
      const body = '';
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink);
    }
  }
}
</script>


<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  animation: fadeIn 0.5s ease-out;
}

.pop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.pop-dialog-bg {
  margin-top: -100px;
  width: 426px;
  height: 221px;
  border-radius: 8px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
  background: rgba(27, 60, 70, 0.5);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(11.5px);
  -webkit-backdrop-filter: blur(11.5px);
  /* border: 1px solid rgba(255, 255, 255, 0.18); */
}

.pop-dialog {
  width: 426px;
  height: 221px;
  border-radius: 8px;
  margin: 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.pop-dialog-tips {
  position: absolute;
  top: 85px;
  left: 0;
  width: 100%;
  height: 20px;
  background: rgba(255, 255, 255, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
}

.pop-dialog-tips-text {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  border-radius: 20px;
  height: 20px;
  background-color: rgba(255, 255, 255, 0.3);
  padding: 5px 10px;
}

.pop-close {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-top: 15px;
  width: 100%;
  cursor: pointer;
}

.pop-close img {
  width: 10px;
  height: auto;
  margin-right: 20px;
  cursor: pointer;
}

.pop-title {
  margin-top: -5px;
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Normal;
  width: 70%;
  text-align: center;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

.contact-container {
  display: flex;
  flex-wrap: wrap;
  gap: 0px;
  margin-top: 20px;
  margin-left: 56px;
  width: 360px;
}

.contact-container > div {
  width: 50%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  margin-bottom: 20px;
}

.contact-container-head {
  width: 14px;
  height: auto;
  margin-right: 10px;
  margin-top: 3px;
}

.contact-container-copy {
  width: 10px;
  height: auto;
  margin-left: 10px;
  margin-top: 3px;
  cursor: pointer;
}

.contact-container-email {
  position: relative;
  text-decoration: none;
  cursor: pointer;
}

.contact-container-email::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.contact-container-phone {
  position: relative;
  text-decoration: none;
}

.contact-container-phone::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 1px;
  background-color: #fff;
}

.contact-container-tel {
  position: relative;
  text-decoration: none;
}

.contact-container-tel::after {
  content: "";
  position: absolute;
  left: 0;
  bottom: -3px;
  width: 100%;
  height: 1px;
  background-color: #fff;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>