<!-- 添加收藏夹 弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="isCreatCollectDialogVisible">
    <div class="pop-mask"
         @click="close"></div>
    <div class="pop-dialog-bg">
      <div class="pop-dialog">
        <div class="pop-close">
          <span> </span>
          <img src="@/assets/login/close-no-cicle.png"
               @click="close"
               alt="" />
        </div>
        <span class="pop-title">{{$t('collection_create')}}</span>

        <div class="contact-container">
          <CustomLoginInput v-model="inputContent"
                            value=""
                            :placeholder="$t('collection_create_name')"
                            @input="handleEmailInput" />
        </div>

        <div class="action">
          <div class="action-cancel"
               @click="handleCancelClick">
            {{$t('collect_cancel')}}
          </div>
          <div class="action-confirm"
               @click="handleConfirmClick">
            {{$t('login_reset_password_confirm')}}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { addCollectionApi } from '@/api/collectApi.js'

import CustomLoginInput from '@/components/CustomLoginInput.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    CustomLoginInput,
  },
  computed: {
    ...mapState('collectDialog', ['isCreatCollectDialogVisible', 'creatCollectType'])
  },

  data () {
    return {
      inputContent: '',
      show: false,
    }
  },

  methods: {
    ...mapActions('collectDialog', [
      'showCreatCollectDialog',
      'hideCreatCollectDialog',
      'showCollectOfWorksDialog',
      'showCollectOfWorksSearchDialog'
    ]),

    close () {
      this.hideCreatCollectDialog()
      this.handleRemove()
    },

    handleEmailInput (value) {
      this.inputContent = value
    },

    handleConfirmClick () {
      this.submitData()
    },
    handleCancelClick () {
      this.inputContent = ''
      this.close()
    },
    handleRemove () {
      this.inputContent = ''
    },
    //提交数据
    submitData () {
      if (!this.inputContent) {
        return
      }
      const data = {
        name: this.inputContent,
      }
      addCollectionApi(data)
        .then((res) => {
          if (res.data.code === 200) {
            console.log("creatCollectType:", this.creatCollectType);
            if (this.creatCollectType == 1) {
              this.showCollectOfWorksDialog()
            } else if (this.creatCollectType == 2) {
              this.showCollectOfWorksSearchDialog()
            }
            this.close()
          }
        })
        .catch((error) => {
        })
    }
  }
}
</script>

<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  animation: fadeIn 0.5s ease-out;
}

.pop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.pop-dialog-bg {
  width: 522px;
  height: 274px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

.pop-dialog {
  width: 520px;
  height: 272px;
  border-radius: 29px;
  margin: 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.pop-close {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.pop-close img {
  width: 15px;
  height: auto;
  margin-right: 25px;
  cursor: pointer;
}

.pop-title {
  margin-top: 10px;
  color: #fff;
  font-size: 22px;
  font-family: Source Han Sans SC-Normal;
  width: 70%;
  text-align: center;
}

.contact-container {
  margin-top: 40px;
  width: 420px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.action {
  display: flex;
  flex-direction: row;
}

.action-cancel {
  margin-right: 20px;
  margin-top: 40px;
  color: #fff;
  font-size: 15px;
  font-family: Source Han Sans SC-Regular;
  padding: 3px 30px;
  background: #8a8a8a;
  border-radius: 18px;
  cursor: pointer;
}

.action-confirm {
  margin-top: 40px;
  color: #fff;
  font-size: 15px;
  font-family: Source Han Sans SC-Regular;
  padding: 3px 30px;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  border-radius: 18px;
  cursor: pointer;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>


<style scoped>
@media only screen and (max-width: 1024px) {
  .pop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
    animation: fadeIn 0.5s ease-out;
  }

  .pop-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
  }

  .pop-dialog-bg {
    width: 422px;
    height: 214px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 50px;
  }

  .pop-dialog {
    width: 420px;
    height: 212px;
    border-radius: 29px;
    margin: 1px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .pop-close {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .pop-close img {
    width: 15px;
    height: auto;
    margin-right: 25px;
    cursor: pointer;
  }

  .pop-title {
    margin-top: 0px;
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Normal;
    width: 70%;
    text-align: center;
  }

  .contact-container {
    margin-top: 20px;
    width: 320px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .action {
    display: flex;
    flex-direction: row;
  }

  .action-cancel {
    margin-right: 20px;
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    padding: 3px 20px;
    background: #8a8a8a;
    border-radius: 18px;
    cursor: pointer;
  }

  .action-confirm {
    margin-top: 20px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    padding: 3px 20px;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    border-radius: 18px;
    cursor: pointer;
  }

  .pop-wrapper.fade-out {
    animation: fadeOut 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>