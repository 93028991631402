/*
 * Collect API
 */

import request from '@/utils/request';

//收藏夹-列表接口
export function collectionListApi (data) {
    return request({
      url: '/api/interlib-content/collection/folder/list',
      method: 'POST',
      data
    });
}

//新增收藏夹接口
export function addCollectionApi(data) {
    return request({
      url: '/api/interlib-content/collection/folder/add',
      method: 'POST',
      data
    });
}

//删除收藏夹接口
export function deleteCollectionApi(id) {
    return request({
      url: `/api/interlib-content/collection/folder/delete/${id}`,
      method: 'GET',
    });
}

//添加到收藏夹接口
export function addCollectionFolderApi(data) {
    return request({
      url: '/api/interlib-content/collection/collect_to_folder',
      method: 'POST',
      data
    });
}

//收藏夹内容接口
export function getCollectionDetailApi(id) {
    return request({
      url: `/api/interlib-content/collection/get_folder_project/${id}`,
      method: 'GET',
    });
}

//从收藏夹中删除接口
export function deleteCollectionFolderApi(data) {
    return request({
      url: '/api/interlib-content/collection/move_to_folder',
      method: 'POST',
      data
    });
}


//从收藏夹中移动到另一个收藏夹接口
export function cutCollectionFolderApi(data) {
    return request({
      url: '/api/interlib-content/collection/cut_to_folder',
      method: 'POST',
      data
    });
}

//修改收藏夹名称接口
export function renameCollectionFolderApi(data) {
    return request({
      url: '/api/interlib-content/collection/folder/submit',
      method: 'POST',
      data
    });
}

