<!-- 重置密码弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="isForgetPasswordDialogVisible">
    <div class="pop-mask"
         @click="close"></div>
    <div class="pop-dialog-bg">
      <div class="pop-dialog">
        <div class="pop-close">
          <span> </span>
          <img src="@/assets/login/close-no-cicle.png"
               @click="close"
               alt="" />
        </div>

        <img class="pop-title-img"
             src="@/assets/home/interlib-logo.png"
             alt="" />

        <span class="show-tips"> {{  showTips }}</span>
        <span class="pop-tips">{{ $t('login_reset_password') }}</span>

        <CustomLoginInput class="item-border"
                          v-model="inputEmailValue"
                          value=""
                          :type="'email'"
                          :placeholder="$t('login_reset_password_email_placeholder')"
                          @input="handleInput" />
        <CustomLoginInputHasCountdown class="item-border password"
                                      v-model="inputVerficationCodeValue"
                                      value=""
                                      :placeholder="$t('login_reset_password_verfication_code_placeholder')"
                                      @get-code="handleGetCodeFromChild"
                                      @noEmail="handleGetCodeNoEmail" />
        <CustomLoginInputPassword class="item-border"
                                  v-model="inputPasswordValue"
                                  value=""
                                  :placeholder="$t('login_reset_password_password_placeholder')"
                                  @input="handleInput" />
        <CustomLoginInputPassword class="item-border"
                                  v-model="inputConfirmPasswordValue"
                                  value=""
                                  :placeholder="$t('login_reset_password_confirm_password_placeholder')"
                                  @input="handleInput" />
        <div class="makesure"
             @click="handleMakeSureClick">
          {{$t('login_reset_password_confirm')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CustomLoginInput from '@/components/CustomLoginInput.vue';
import CustomLoginInputPassword from '@/components/CustomLoginInputPassword.vue';
import CustomLoginInputHasCountdown from '@/components/CustomLoginInputHasCountdown.vue';
import { mapState, mapActions } from 'vuex';
import { sendCodeApi, forgetPasswordApi } from '@/api/userApi.js'

export default {
  components: {
    CustomLoginInput,
    CustomLoginInputPassword,
    CustomLoginInputHasCountdown
  },
  computed: {
    ...mapState('dialog', ['isForgetPasswordDialogVisible']),
  },
  watch: {
    // 监听 isLoginDialogVisible 变化，在变化时执行相应的逻辑
    isForgetPasswordDialogVisible (newValue) {
      if (newValue) {
        // 弹窗显示的逻辑
        this.reloadLocationData()
      }
    },
  },
  data () {
    return {
      inputEmailValue: '',
      inputVerficationCodeValue: '',
      inputPasswordValue: '',
      inputConfirmPasswordValue: '',
      showTips: ''
    }
  },
  created () {
    this.reloadLocationData()
  },
  methods: {
    ...mapActions('dialog', ['showLoginDialog', 'hideLoginDialog', 'showForgetPasswordDialog', 'hideForgetPasswordDialog', 'showSignupDialog', 'hideSignupDialog']),

    close () {
      this.hideForgetPasswordDialog()
      this.handleRemove()
    },
    reloadLocationData () {
      // 检查本地存储中的账号和密码
      const username = localStorage.getItem('interlibEmail')

      console.log(`interlibEmail + ${username}`)

      if (username) {
        // 如果本地存储中存在账号和密码，则将其填充到填写框中，并将 rememberPassword 设置为 true
        this.inputEmailValue = username
        localStorage.setItem('signUpInputEmail', username)
      } else {
        localStorage.setItem('signUpInputEmail', '')
      }
    },
    handleMakeSureClick () {
      this.submitData()
    },
    handleRemove () {
      this.inputEmailValue = ''
      this.inputVerficationCodeValue = ''
      this.inputPasswordValue = ''
      this.inputConfirmPasswordValue = ''
    },
    //提交数据
    submitData () {
      if (!this.inputEmailValue) {
        this.showTips = this.$t('tips_input_email');

        setTimeout(() => {
          this.showTips = '';
        }, 4000);
        return
      }

      if (!this.inputVerficationCodeValue) {
        this.showTips = this.$t('tips_input_email_code');

        setTimeout(() => {
          this.showTips = '';
        }, 4000);
        return
      }

      if (!this.inputPasswordValue) {
        this.showTips = this.$t('tips_input_password');

        setTimeout(() => {
          this.showTips = '';
        }, 4000);
        return
      }

      if (!this.inputConfirmPasswordValue) {
        this.showTips = this.$t('tips_input_password_again');

        setTimeout(() => {
          this.showTips = '';
        }, 4000);
        return
      }

      if (this.inputPasswordValue != this.inputConfirmPasswordValue) {
        this.showTips = this.$t('tips_input_password_not_same');

        setTimeout(() => {
          this.showTips = '';
        }, 4000);
        return
      }
      const data = {
        email: this.inputEmailValue,
        code: this.inputVerficationCodeValue,
        newPassword: this.inputPasswordValue
      }

      console.log(data)
      // 发送忘记密码请求
      forgetPasswordApi(data)
        .then((res) => {
          console.log(res)
          console.log(`code + ${res.data.code}`)
          if (res.data.code === 200) {
            console.log("es.data.code === 200");
            this.close()
            this.showLoginDialog()
          }
        })
        .catch((error) => {
          console.error(error)
          console.log("error.data.message", error.data.message);
          this.showTips = error.data.message;
          setTimeout(() => {
            this.showTips = '';
          }, 4000);
        })

    },
    handleGetCodeNoEmail () {
      this.showTips = this.$t('tips_input_email');

      setTimeout(() => {
        this.showTips = '';
      }, 4000);
    },
    handleInput (value, type) {
      if (type == 'email') {
        this.inputEmailValue = value
        if (!value) {
          localStorage.setItem('signUpInputEmail', '')
        } else {
          localStorage.setItem('signUpInputEmail', value)
        }
      }
    },
    handleGetCodeFromChild (countdown) {
      // 在这里可以获取到倒计时时间
      console.log("获取到倒计时时间：" + countdown + "s");
      const params = {
        email: this.inputEmailValue,
      }
      console.log(params)
      // 发送获取验证吗请求
      sendCodeApi(params)
        .then((res) => {
          console.log(res)
          console.log(`code + ${res.data.code}`)
          if (res.data.code === 200) {
            console.log("res.data.code === 200");
            this.showTips = res.data.message
          }
        })
        .catch((error) => {
          console.error(error)
          this.showTips = error.data.message;
        })
      setTimeout(() => {
        this.showTips = '';
      }, 4000);
    },
  }
}
</script>


<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  animation: fadeIn 0.5s ease-out;
}

.pop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.pop-dialog-bg {
  width: 522px;
  height: 514px;
  /* background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe); */
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

.pop-dialog {
  width: 520px;
  height: 512px;
  border-radius: 29px;
  margin: 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.pop-close {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.pop-close img {
  width: 15px;
  height: auto;
  margin-right: 25px;
  cursor: pointer;
}

.pop-title-img {
  margin-top: 10px;
  width: 190px;
  height: auto;
}

.show-tips {
  margin-top: 15px;
  color: #ff036a;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  height: 12px;
}

.pop-tips {
  margin-top: 15px;
  color: #fff;
  font-size: 22px;
  font-family: Source Han Sans SC-Medium;
  height: 12px;
  width: 70%;
  margin-bottom: 25px;
}

.item-border {
  margin-top: 15px;
  width: 70%;
}

.item-border.password {
  margin-top: 20px;
}

.password-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin-top: 20px;
}

.remember-password {
  display: flex;
  flex-direction: row;
  justify-content: left;
  cursor: pointer;
}

.remember-password img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.remember-password span {
  color: #a8a8a8;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
}

.forget-password {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  cursor: pointer;
}

.forget-password img {
  width: 7px;
  height: 10px;
  margin-left: 10px;
  margin-top: 3px;
}

.forget-password span {
  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
}

.makesure {
  margin-top: 40px;
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
  padding: 8px 80px;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  border-radius: 18px;
  cursor: pointer;
}

.sign-up-container {
  margin-top: 25px;
}

.no-account {
  color: #a8a8a8;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
  margin-right: 19px;
}

.sign-up {
  color: #1cc405;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
  cursor: pointer;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>

<style scoped>
@media only screen and (max-width: 1024px) {
  .pop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
    animation: fadeIn 0.5s ease-out;
  }

  .pop-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
  }

  .pop-dialog-bg {
    width: 482px;
    height: 284px;
    /* background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe); */
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 80px;
  }

  .pop-dialog {
    width: 480px;
    height: 282px;
    border-radius: 29px;
    margin: 1px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: auto;
  }

  .pop-close {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    margin-top: 15px;
    width: 100%;
  }

  .pop-close img {
    width: 15px;
    height: auto;
    margin-right: 20px;
    cursor: pointer;
  }

  .pop-title-img {
    margin-top: 0px;
    width: 120px;
    height: auto;
  }

  .show-tips {
    margin-top: 5px;
    color: #ff036a;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    height: 12px;
  }

  .pop-tips {
    margin-top: 5px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    height: 12px;
    width: 70%;
    margin-bottom: 10px;
  }

  .item-border {
    margin-top: 10px;
    width: 70%;
  }

  .item-border.password {
    margin-top: 15px;
  }

  .password-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin-top: 15px;
  }

  .remember-password {
    display: flex;
    flex-direction: row;
    justify-content: left;
    cursor: pointer;
  }

  .remember-password img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

  .remember-password span {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
  }

  .forget-password {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    cursor: pointer;
  }

  .forget-password img {
    width: 7px;
    height: 10px;
    margin-left: 10px;
    margin-top: 3px;
  }

  .forget-password span {
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Normal;
  }

  .makesure {
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Medium;
    padding: 5px 50px;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    border-radius: 18px;
    cursor: pointer;
    margin-bottom: 20px;
  }

  .sign-up-container {
    margin-top: 10px;
  }

  .no-account {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    margin-right: 19px;
  }

  .sign-up {
    color: #1cc405;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    cursor: pointer;
  }

  .pop-wrapper.fade-out {
    animation: fadeOut 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>