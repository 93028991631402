/*
 * Home API
 */

import request from '@/utils/request';

//banner接口
export function bannerApi(data) {
    return request({
      url: `/api/interlib-content/banner/${data}`,
      method: 'GET',
    });
}

// 获取图片地址
export function getPresignedUrlApi(data) {
    return request({
      url: `/api/interlib-sys/sys/file/getPresignedUrl/${data}`,
      method: 'GET',
    });
}

//获取热门推荐
export function hotListApi() {
    return request({
        url: '/api/interlib-content/hot/list',
        method: 'GET',
    });
}


//RECOMMEND接口
export function homeRelateApi(data) {
    return request({
        url: '/api/interlib-content/content/homeRelate',
        method: 'POST',
        data,
    });
}

//Rankings
export function rankListApi() {
    return request({
        url: '/api/interlib-content/rank/list',
        method: 'GET',
    });
}

