<!-- StoreDetailBottomPage -->
<template>
  <div>
    <div class="footer">
      <div class="footer-logo"
           @click="jumpInterlibHome">
        <img src="@/assets/home/interlib-logo.png"
             alt="" />
      </div>
      <div class="footer-title">{{ $t('footer_title') }}</div>
      <div class="footer-detail">
        {{ $t('footer_detail') }}
      </div>
      <div class="footer-third-contact">
        <img src="@/assets/home/email.png"
             class="footer-third-contact-img"
             @click="gotoEmail"
             alt="" />
        <img src="@/assets/home/instagram.png"
             class="footer-third-contact-img"
             @click="gotoInsgram"
             alt="" />
        <img src="@/assets/home/twitter.png"
             class="footer-third-contact-twitter"
             @click="gotoTwitter"
             alt="" />
        <img src="@/assets/home/facebook.png"
             class="footer-third-contact-img"
             @click="gotoFacebook"
             alt="" />
      </div>

      <div class="footer-address">
        <div class="footer-address-info">
          {{ $t('footer_address_info') }}
        </div>
        <!-- <div class="footer-address-contact"
             @click="contactUsClick">{{ $t('contact_us') }}</div> -->
      </div>
    </div>
  </div>
</template>

<script>

export default {
  created () {
    // 创建一个 MediaQueryList 对象，传入媒体查询条件
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    // 检查是否匹配指定的媒体查询条件
    if (mediaQuery.matches) {
      // 在此处执行适应小屏幕的代码
      console.log('屏幕宽度小于等于 1024px');
      this.isMobile = true;
    } else {
      // 在此处执行适应大屏幕的代码
      console.log('屏幕宽度大于 1024px');
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()
  },

  data () {
    return {
      languageType: "",
      isMobile: false,
    }
  },
  methods: {

    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    resetData () {

    },

    //Contact Us
    contactUsClick () {
      this.$nextTick(() => {
        this.$router.push({ name: 'language-contact-us', params: { language: this.languageType === 'zh' ? 'cn' : 'en' } })
      })
    },

    gotoEmail () {
      const email = 'info@interlib.asia';
      const subject = '';
      const body = '';
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
      window.open(mailtoLink);
    },
    gotoInsgram () {
      window.open('https://www.instagram.com/interlib_asia/', "_blank");
    },
    gotoTwitter () {
      window.open('https://twitter.com/AInterlib95465', "_blank");
    },
    gotoFacebook () {
      window.open('https://www.facebook.com/profile.php?id=61551731825749', "_blank");
    },
    jumpInterlibHome () {
      // 在新标签页打开
      window.open(this.$router.resolve({ path: '/en/' }).href, '_blank');
    }
  },
}
</script>

<style scoped>
.movies-title {
  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Bold;
}

.movies-detail {
  color: #a6a6a6;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
}

.movies-time {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
}

.movies-collect {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
}

.top-collect {
  position: absolute;
  top: 0;
  right: 0;

  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 15px 15px;
  z-index: 100;
}

/* Footer---start */
.footer {
  margin-left: 30px;
  margin-right: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-logo {
  margin-top: 50px;
  height: 32px;
  cursor: pointer;
}

.footer-logo img {
  height: 100%;
}

.footer-title {
  margin-top: 30px;
  color: #8e8e8e;
  font-size: 13px;
  font-family: Source Han Sans SC-Normal;
}

.footer-detail {
  color: #8e8e8e;
  font-size: 13px;
  font-family: Source Han Sans SC-Normal;
}

.footer-third-contact {
  margin-top: 70px;
  height: 40px;
  align-content: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-third-contact-img {
  margin: auto 10px;
  width: 20px;
  cursor: pointer;
}

.footer-third-contact-twitter {
  margin: auto 10px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.footer-address {
  margin-top: 15px;
  color: #fff;
  margin-bottom: 70px;
  font-size: 13px;
  font-family: Source Han Sans SC-Normal;
  display: flex;
  flex-direction: row;
  cursor: pointer;
}

.footer-address-contact {
  margin-left: 30px;
  text-decoration: underline;
  text-underline-offset: 0.5em;
  cursor: pointer;
}
/* Footer---end */

::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 3px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 6px;
}
</style>


<style scoped>
@media only screen and (max-width: 1024px) {
  .movies-title {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
  }

  .movies-detail {
    color: #a6a6a6;
    font-size: 0px;
    font-family: Source Han Sans SC-Normal;
  }

  .movies-time {
    color: #fff;
    font-size: 0px;
    font-family: Source Han Sans SC-Normal;
  }

  .movies-collect {
    color: #fff;
    font-size: 0px;
    font-family: Source Han Sans SC-Normal;
  }

  .top-collect {
    position: absolute;
    top: 0;
    right: 0;

    width: 25px;
    height: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  /* Footer---start */
  .footer {
    margin-left: 14px;
    margin-right: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .footer-logo {
    margin-top: 50px;
    height: 22px;
    cursor: pointer;
  }

  .footer-logo img {
    height: 100%;
  }

  .footer-title {
    margin-top: 30px;
    color: #8e8e8e;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
  }

  .footer-detail {
    color: #8e8e8e;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    text-align: center;
  }

  .footer-third-contact {
    margin-top: 50px;
    height: 30px;
    align-content: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  .footer-third-contact-img {
    margin: auto 10px;
    width: 16px;
    cursor: pointer;
  }

  .footer-third-contact-twitter {
    margin: auto 10px;
    width: 13px;
    height: 13px;
    cursor: pointer;
  }

  .footer-address {
    margin-top: 15px;
    color: #fff;
    margin-bottom: 50px;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    display: flex;
    flex-direction: row;
  }

  .footer-address-contact {
    margin-left: 30px;
    text-decoration: underline;
    text-underline-offset: 0.5em;
    cursor: pointer;
  }
  /* Footer---end */

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>
