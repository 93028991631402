<template>
    <!-- 店铺模块 首页 -->
    <div class="shop-container" style="color: red">
        <!-- 移动端 -->
        <div v-if="isMobile">
           <!-- <span>移动端</span> -->
           <shopMobile />
        </div>
        <!-- PC端 -->
        <div v-else-if="!isMobile">
            PC端/笔记本端
        </div>
    </div>
</template>

<script lang="jsx">
import shopMobile from "./mobileView/shop"
export default {
  name: 'shop',
  data(){
    return {
        isMobile: false, // 是否是移动端
        languageType: '', // 中英语言类型
    }
  },
  components: {
    shopMobile, // 移动端 首页
  },
  methods:{
    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

  },
  mounted() {
    
  },
  created () {
    // 创建一个 MediaQueryList 对象，传入媒体查询条件
    let mediaQuery = window.matchMedia('(max-width: 1024px)');
    // 检查是否匹配指定的媒体查询条件
    if (mediaQuery.matches) {
      // 在此处执行适应小屏幕的代码
      console.log('屏幕宽度小于等于 1024px');
      this.isMobile = true;
    } else {
      // 在此处执行适应大屏幕的代码
      console.log('屏幕宽度大于 1024px');
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()
  }
}
</script>

<style   scoped>

</style>