<!-- 搜索收藏夹弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="isCollectOfWorksSearchDialogVisible">
    <div class="pop-mask"
         @click="close"></div>
    <div class="pop-dialog-bg">
      <div class="pop-dialog">
        <div class="pop-close">
          <span> </span>
          <img src="@/assets/login/close-no-cicle.png"
               @click="close"
               alt="" />
        </div>
        <span class="pop-title">{{ $t('collection_title') }}</span>

        <div class="search-container">
          <CustomLoginInput v-model="inputContent"
                            value=""
                            :placeholder="$t('collection_create_name')"
                            @input="handleInput" />
        </div>
        <div class="collect-container">

          <div v-for="(item, index) in collectList"
               :key="index"
               class="collect-item">
            <div class="collect-item-container">
              <img :src="item.imageUrl"
                   alt="">
              <span class="collect-item-container-title">{{ item.name }}</span>
              <span v-if="item.tips"
                    class="recently-used">{{ item.tips }}</span>
            </div>
            <div class="action-confirm"
                 @click="handleConfirmClick(item.id)">
              {{$t('collection_add_to')}}
            </div>
          </div>
        </div>
        <div class="create-collection-container"
             @click="addCollect">
          <img src="@/assets/collect/add-collect.png"
               alt="" />
          <span class="create-collection">{{$t('collection_create_collection')}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { collectionListApi, addCollectionFolderApi } from '@/api/collectApi.js'
import { getPresignedUrlApi } from '@/api/homeApi.js'

import CustomLoginInput from '@/components/CustomLoginInput.vue'
import { mapState, mapActions } from 'vuex'

export default {
  components: {
    CustomLoginInput
  },
  computed: {
    ...mapState('collectDialog', ['isCollectOfWorksSearchDialogVisible', 'projectId', 'creatCollectType', 'hasCollectSuccess'])
  },
  watch: {
    // 监听 isCollectOfWorksSearchDialogVisible 变化，在变化时执行相应的逻辑
    isCollectOfWorksSearchDialogVisible (newValue) {
      if (newValue) {
        // 弹窗显示的逻辑
        this.reloadCollectOfWorksData()
      }
    }
  },
  created () {
    this.reloadCollectOfWorksData()
  },
  data () {
    return {
      show: false,
      inputContent: '',
      defaultCollectList: [],
      collectList: []

    }
  },

  methods: {
    ...mapActions('collectDialog', [
      'showCreatCollectDialog',
      'hideCreatCollectDialog',
      'showCollectOfWorksDialog',
      'hideCollectOfWorksDialog',
      'showCollectOfWorksSearchDialog',
      'hideCollectOfWorksSearchDialog',
      'setCreatCollectTypeDialog',
      'setCollectSuccessDialog'
    ]),


    close () {
      this.hideCollectOfWorksSearchDialog()
      this.handleRemove()
    },

    reloadCollectOfWorksData () {
      this.fetchCollectListData();
    },

    handleInput (value) {
      console.log('CollectOfWorksSearchPop:', value);
      value = value.toLowerCase(); // 将输入的值转换为小写

      this.collectList = value
        ? this.defaultCollectList.filter(item => item.name.toLowerCase().includes(value))
        : this.defaultCollectList;
    },

    handleConfirmClick (id) {
      this.fetchAddCollectionFolderData(id)
    },
    handleCancelClick () {
      this.close()
    },
    handleRemove () {
      this.inputContent = ''
    },

    //添加收藏夹
    addCollect () {
      this.setCreatCollectTypeDialog(2)
      this.showCreatCollectDialog()
      this.hideCollectOfWorksSearchDialog()
      console.log("creatCollectType:", this.creatCollectType);
    },
    //提交数据
    submitData () {
      if (!this.inputContent) {
        return
      }
      const data = {
        inputContent: this.inputContent,
      }

      forgetPasswordApi(data)
        .then((res) => {
          if (res.data.code === 200) {
            this.close()
          }
        })
        .catch((error) => {
        })
    },
    //获取收藏列表数据
    fetchCollectListData () {
      this.collectList = []
      const resultData = []
      const data = { "queryMode": "select" }
      collectionListApi(data)
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.forEach((item) => {
              item.active = false
              resultData.push(item)
            })

            const childrenResultData = Promise.all(
              resultData.map(async (element) => {
                if (element.poster && element.poster.length > 0) {
                  try {
                    const imageRes = await getPresignedUrlApi(element.poster[element.poster.length - 1]);
                    if (imageRes.data.code === 200) {
                      element.imageUrl = imageRes.data.data.url;
                      return element;
                    }
                  } catch (error) {
                    // 错误处理
                  }
                } else {
                  element.imageUrl = require("@/assets/bg/default_bg.jpg");
                  return element;
                }
              })
            );
            // 在Promise.all的.then回调中处理结果
            childrenResultData.then((data) => {
              this.defaultCollectList = data.filter(Boolean);
              this.collectList = this.defaultCollectList;
            });
          }
        })
        .catch((error) => {
        })
    },
    //添加到收藏夹数据
    fetchAddCollectionFolderData (folderId) {
      console.log("projectId:", this.projectId);
      const data = {
        folderId: folderId,
        projectId: this.projectId
      }
      addCollectionFolderApi(data)
        .then((res) => {
          if (res.data.code === 200) {
            this.setCollectSuccessDialog()
            console.log("collectSuccessDialog:", this.hasCollectSuccess);
            this.close()
          }
        })
        .catch((error) => {
        })
    },
  }
}
</script>

<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  animation: fadeIn 0.5s ease-out;
}

.pop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.pop-dialog-bg {
  width: 622px;
  height: 464px;
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

.pop-dialog {
  width: 620px;
  height: 462px;
  border-radius: 29px;
  margin: 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.pop-close {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.pop-close img {
  width: 15px;
  height: auto;
  margin-right: 25px;
  cursor: pointer;
}

.pop-title-container {
  display: flex;
  flex-direction: row;
  justify-content: baseline;
  margin-top: 10px;
  width: 70%;
  justify-content: center;
  align-items: center;
}

.pop-title {
  color: #fff;
  font-size: 22px;
  font-family: Source Han Sans SC-Normal;
  text-align: center;
}

.search-container {
  margin-top: 40px;
  width: 530px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.pop-title-container img {
  width: 30px;
  height: 30px;
  margin-left: 60px;
  color: #fff;
  cursor: pointer;
}

.collect-container {
  margin-top: 40px;
  padding-right: 10px;
  width: 480px;
  height: 190px;
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.collect-item {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  margin-bottom: 20px;
}
.collect-item-container {
  display: flex;
  flex-direction: row;
  flex-direction: row;
  align-items: center;
  align-content: center;
}

.collect-item-container img {
  width: 60px;
  height: 61px;
  border-radius: 10px;
  object-fit: cover;
}

.collect-item-container-title {
  margin-left: 15px;
  color: #fff;
  font-size: 15px;
  font-family: Source Han Sans SC-Regular;
}

.recently-used {
  margin-left: 30px;
  background-color: #ffc901;
  color: #000;
  font-size: 12px;
  border-radius: 20px;
  padding: 0px 6px;
  font-family: Source Han Sans SC-Regular;
}

.action-confirm {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  padding: 6px 25px;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  border-radius: 18px;
  cursor: pointer;
}

.create-collection-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  cursor: pointer;
}

.create-collection-container img {
  width: 24px;
  height: 24px;
}

.create-collection-container span {
  margin-left: 15px;
  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Regular;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 1.5px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 1.5px;
}
</style>


<style scoped>
@media only screen and (max-width: 1024px) {
  .pop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
    animation: fadeIn 0.5s ease-out;
  }

  .pop-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
  }

  .pop-dialog-bg {
    width: 462px;
    height: 294px;
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 60px;
  }

  .pop-dialog {
    width: 460px;
    height: 292px;
    border-radius: 29px;
    margin: 1px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .pop-close {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .pop-close img {
    width: 15px;
    height: auto;
    margin-right: 25px;
    cursor: pointer;
  }

  .pop-title-container {
    display: flex;
    flex-direction: row;
    justify-content: baseline;
    margin-top: 0px;
    width: 70%;
    justify-content: center;
    align-items: center;
  }

  .pop-title {
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Normal;
    text-align: center;
    margin-top: -20px;
  }

  .search-container {
    margin-top: 15px;
    width: 390px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .pop-title-container img {
    width: 30px;
    height: 30px;
    margin-left: 60px;
    color: #fff;
    cursor: pointer;
  }

  .collect-container {
    margin-top: 10px;
    padding-right: 0px;
    width: 390px;
    height: 140px;
    display: flex;
    flex-direction: column;
    overflow: auto;
  }

  .collect-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    margin-bottom: 10px;
  }
  .collect-item-container {
    display: flex;
    flex-direction: row;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  .collect-item-container img {
    width: 50px;
    height: 51px;
    border-radius: 10px;
    object-fit: cover;
  }

  .collect-item-container-title {
    margin-left: 15px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .recently-used {
    margin-left: 30px;
    background-color: #ffc901;
    color: #000;
    font-size: 12px;
    border-radius: 20px;
    padding: 0px 6px;
    font-family: Source Han Sans SC-Regular;
  }

  .action-confirm {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    padding: 3px 15px;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    border-radius: 18px;
    cursor: pointer;
    margin-right: 10px;
  }

  .create-collection-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    cursor: pointer;
  }

  .create-collection-container img {
    width: 20px;
    height: 20px;
  }

  .create-collection-container span {
    margin-left: 10px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .pop-wrapper.fade-out {
    animation: fadeOut 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 1.5px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 1.5px;
  }
}
</style>