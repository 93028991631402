<template>
  <div id="app">
    <div class="app-hint"
         v-if="isScreen && !isCard && !isShop">
         <!-- 【竖屏】 展示强制横屏提示 -->
      <div class="rotate">
        <img class="rotate-img"
             src="@/assets/common/mobile_bg-logo.png"
             alt="" />
        <img class="rotate-img-turn"
             src="@/assets/common/mobile_turn.gif"
             alt=""
             style="opacity: 0.5;" />
        <span class="rotate-text">{{ $t('mobile_tips') }}</span>
      </div>
    </div>
    <!-- 【横竖屏均可】 -->
     <!-- 电子名片  -->
    <!-- <BusinessCardPage v-if="currentURL.includes('/card')"></BusinessCardPage> -->
    <BusinessCardPage v-else-if="isCard" />
    

    <!-- 非电子名片 -->
     <!-- 店铺 -->
    <div v-else-if="isShop">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
    </div>
    <div v-else>
      <div v-show="!isScreen">
        <!-- 电子邮件片单 -->
        <StorePage v-if="currentURL.includes('/store')">
        </StorePage>

        <!-- 默认官网 interlib -->
        <default-layout v-else></default-layout>
        <LoginManager></LoginManager>
        <CollectPopManager></CollectPopManager>
      </div>
    </div>
    
   
    
  </div>
</template>

<script>
import DefaultLayout from './layouts/Default.vue'
import StorePage from './views/store/StorePage.vue'
import ShopPage from './views/shop/index.vue'
import LoginManager from './views/login/LoginManager.vue'
import CollectPopManager from './views/Collect/Pop/CollectPopManager.vue'
import BusinessCardPage from './views/businessCard/BusinessCardPage.vue'

export default {
  name: 'app',
  data () {
    return {
      screenHW: window.orientation, // 判断横竖屏
      isScreen: false, // 竖屏
      isCard: false, // 电子名片
      isShop: false, // 店铺
      currentURL: ''
    };
  },
  components: {
    ShopPage, // 店铺
    StorePage, // 电子邮件片单
    DefaultLayout, // 默认官网layout
    LoginManager,
    CollectPopManager,
    BusinessCardPage,
  },
  mounted () {
    this.currentURL = window.location.href;
    console.log('this.currentURL :', this.currentURL);
    // 访问card_id参数
    const cardId = this.$route.query.card_id
    console.log('card_id:', cardId)
    if (cardId) {
      localStorage.setItem('locationCardId', cardId)
    }
    // 访问mail_film_id参数(店铺相关)
    const mailFilmId = this.$route.query.mail_film_id
    console.log('mail_film_id:', mailFilmId)
    if (mailFilmId) {
      localStorage.setItem('locationStoreMailFilmId', mailFilmId)
    }

    // 访问click_interlib_content_id参数(店铺相关)
    const clickInterlibContentId = this.$route.query.click_interlib_content_id
    console.log('click_interlib_content_id:', clickInterlibContentId)
    if (clickInterlibContentId) {
      localStorage.setItem('locationStoreClickInterlibContentId', clickInterlibContentId)
    }

    //解析店铺语言
    if (this.currentURL.includes('/store')) {
      localStorage.setItem('joinStorePage', true)
      if (this.currentURL.includes('/en/')) {
        localStorage.setItem('locationLanguageType', 'en')
        this.$i18n.locale = 'en';
        // 改变浏览器的地址但不重新加载页面
        history.pushState({}, '', '/en/store');
      } else {
        localStorage.setItem('locationLanguageType', 'zh')
        this.$i18n.locale = 'zh';
        // 改变浏览器的地址但不重新加载页面
        history.pushState({}, '', '/cn/store');
      }
      this.isCard = false
    } else if (this.currentURL.includes('/card')) {
      const cardUserChangeLanguage = localStorage.getItem('cardUserChangeLocationLanguageType')
      this.isCard = true
      if (this.currentURL.includes('/en/')) {
        localStorage.setItem('locationLanguageType', 'en')
        this.$i18n.locale = 'en';
        // 改变浏览器的地址但不重新加载页面
        history.pushState({}, '', '/en/card');

        if (cardUserChangeLanguage == 'zh') {
          localStorage.setItem('locationLanguageType', 'zh')
          this.$i18n.locale = 'zh';
          // 改变浏览器的地址但不重新加载页面
          history.pushState({}, '', '/cn/card');
        }
      } else {
        localStorage.setItem('locationLanguageType', 'zh')
        this.$i18n.locale = 'zh';
        // 改变浏览器的地址但不重新加载页面
        history.pushState({}, '', '/cn/card');

        if (cardUserChangeLanguage == 'en') {
          localStorage.setItem('locationLanguageType', 'en')
          this.$i18n.locale = 'en';
          // 改变浏览器的地址但不重新加载页面
          history.pushState({}, '', '/en/card');
        }

      }
    } else if (this.currentURL.includes('/shop')) {
      
      // const shopUserChangeLanguage = localStorage.getItem('shopUserChangeLocationLanguageType')
      this.isShop = true
      // if (this.currentURL.includes('/en/')) {
      //   localStorage.setItem('locationLanguageType', 'en')
      //   this.$i18n.locale = 'en';
      //   // 改变浏览器的地址但不重新加载页面
      //   history.pushState({}, '', '/en/shop');

      //   if (shopUserChangeLanguage == 'zh') {
      //     localStorage.setItem('locationLanguageType', 'zh')
      //     this.$i18n.locale = 'zh';
      //     // 改变浏览器的地址但不重新加载页面
      //     history.pushState({}, '', '/cn/shop');
      //   }
      // } else {
      //   localStorage.setItem('locationLanguageType', 'zh')
      //   this.$i18n.locale = 'zh';
      //   // 改变浏览器的地址但不重新加载页面
      //   history.pushState({}, '', '/cn/shop');

      //   if (shopUserChangeLanguage == 'en') {
      //     localStorage.setItem('locationLanguageType', 'en')
      //     this.$i18n.locale = 'en';
      //     // 改变浏览器的地址但不重新加载页面
      //     history.pushState({}, '', '/en/shop');
      //   }

      // }
    } else {
      this.isCard = false
      this.isShop = false
      localStorage.setItem('joinStorePage', false)
    }

    this.screenHW = window.orientation  //保证刷新时获取状态准确
    // 监听窗口大小
    window.onresize = () => {
      return (() => {
        this.screenHW = window.orientation   // 把横竖屏的变化赋值
      })()
    }

    if (this.isMobileDevice()) {
      window.addEventListener("orientationchange", this.handleOrientationChange);
    }
  },

  watch: {
    screenHW: {
      immediate: true,
      handler (newval, old) {
        this.rotate()
      }

    }
  },
  beforeDestroy () {
    if (this.isMobileDevice()) {
      window.removeEventListener("orientationchange", this.handleOrientationChange);
    }
  },
  methods: {
    isMobileDevice () {
      return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    },
    handleOrientationChange () {
      if (this.currentURL.includes('/card')) {
        this.isScreen = false
      } else {
        if (window.orientation === 90 || window.orientation === -90) {
          // 横屏展示
          this.isScreen = false
          window.location.reload();
        } else {
          // 竖屏展示
          // alert('请将设备横过来！');
          this.isScreen = true
        }
      }
    },
    // 判断横竖屏
    rotate () {
      if (this.currentURL.includes('/card')) {
        this.isScreen = false
      } else if (this.currentURL.includes('/shop')) {
        this.isScreen = true
      } else {
        if (this.screenHW == 180 || this.screenHW == 0) {
          // console.log('竖屏')
          this.isScreen = true
        } else if (this.screenHW == 90 || this.screenHW == -90) {
          // console.log('横屏')
          this.isScreen = false
        }
      }
    },
  },
}
</script>

<style>
body {
  /* padding: env(safe-area-inset-top) env(safe-area-inset-bottom); */
  background-color: #2b2d31;
}
.rotate {
  background-color: #2b2d31;
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 150px;
  background-image: url("@/assets/common/mobile_bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.rotate-img {
  align-items: center;
  width: 110px;
  /* box-shadow: 10px 13px 18px rgba(0, 0, 0, 0.3); */
}

.rotate-img-turn {
  margin-top: 130px;
  align-items: center;
  width: 110px;
}

.rotate-text {
  margin-top: 0px;
  font-size: 14px;
  color: #fff;
  font-family: Source Han Sans SC-Normal;
  text-align: center;
}
</style>