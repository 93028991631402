
import Vuex from 'vuex';
import Vue from 'vue';
import dialog from '@/store/dialog';
import auth from '@/store/auth';
import collectDialog from '@/store/collectDialog';
import deatilEra from '@/store/deatilEra';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    dialog,
    collectDialog,
    deatilEra,
    auth
  },
});

export default store;