/*
 * @Descripttion:axios封装
 */
// 导入axios
import axios from 'axios';
import moment from "moment-timezone";
import Cookies from 'js-cookie';
import store from '@/store/index';

// 允许axios携带cookies
axios.defaults.withCredentials = true;
// 1. 创建新的axios实例，
const service = axios.create({
    // 公共接口--这里注意后面会讲
    baseURL: process.env.VUE_APP_BASE_URL,
    // 超时时间 单位是ms，这里设置了10s的超时时间
    timeout: 10 * 1000,
});
// 2.请求拦截器
service.interceptors.request.use(
    config => {
        // 在发送请求之前做些什么
        // 如果我们的使用的接口需要配置headers请求头或者body请求，可以再改部分添加
        // headers请求头:config.headers["字段名"]="字段值" + token值
        const token = Cookies.get('token');
        const lang = localStorage.getItem("locationLanguageType") || "en";
        if (token) {
          config.headers["Authorization"] = token;
        }
        config.headers["Content-Type"] = 'application/json;charset=UTF-8';

        config.headers["Accept-Language"] = lang;
        config.headers["Time-Zone"] = moment.tz.guess();
        config.headers["App-Id"] = "INTERLIB-FRONT";
        return config;
    },
    error => {
        Promise.reject(error);
    }
);

// 3.响应拦截器
service.interceptors.response.use(
    response => {
    // 接收到响应数据并成功后的一些共有的处理，关闭loading等
     if (response.data.code === 402) {
            // 401 表示未授权，跳转到登录页面
            Cookies.remove('token');
       localStorage.removeItem('userInfo');
       store.dispatch('auth/logout');
            // window.location.reload()
        }

        if (!response.data || response.data.code !== 200) {
            return Promise.reject(response);
        }
        return Promise.resolve(response);
    },
    error => {
        if (error.response && error.response.status === 401) {
            // 401 表示未授权，跳转到登录页面
            Cookies.remove('token');
            localStorage.removeItem('userInfo');
            window.location.reload()
        }
        // this.$message.error(error.message);
        // this.$toast(error.message)
        /** *** 处理结束 *****/
        // 如果不需要错误处理，以上的处理过程都可省略
        return Promise.resolve(error.response);
    }
);
// 4.导入文件
export default service;
