<!-- 注册成功登录后，填写个人偏好弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="showPersonPreferencePop">
    <div class="pop-mask"
         @click="close"></div>
    <div class="pop-dialog-bg">
      <div class="pop-dialog">
        <div class="pop-dialog-title">
          <span>{{ $t('person_preference_pop_title') }}</span>
        </div>
        <div class="category">
          <div class="category-item"
               v-for="item in categories"
               :key="item.id.toString()"
               @click="selectCategory(item.id)">
            <span :class="{ active: currentIndexId === item.id }">{{ languageType == 'en' ? item.nameEn : item.name }}</span>
            <div class="underline"
                 :class="{ active: currentIndexId === item.id }"></div>
          </div>
        </div>
        <div class="preferences-items">
          <div v-for="item in itemList"
               :key="item.id.toString()"
               class="item"
               :class="{ 'selected': item.isSelected}"
               @click="toggleSelection(item)">
            <span class="item-title">{{ languageType == 'en' ? item.nameEn :item.name }}</span>
          </div>
        </div>

        <div class="makesure"
             @click="handleMakeSureClick">
          {{$t('login_reset_password_confirm')}}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { categoryListApi, genresListApi } from '@/api/discoverApi.js'
import { userPreferenceApi, allPreferenceListApi } from '@/api/userApi.js'

export default {

  computed: {
    ...mapState('auth', ['showPersonPreferencePop', 'isLoggedIn'])
  },
  data () {
    return {
      currentIndexId: undefined,
      currentIndexPreferenceData: [],
      categories: [],
      itemList: [],
      selectedItems: [],
      userPreference: {},
      languageType: "",
    }
  },

  watch: {
    showPersonPreferencePop (newVal, oldVal) {
      // 在这里处理 showPersonPreferencePop 的变化
      console.log('showPersonPreferencePop 变化了，新值为：', newVal);
      if (newVal == true) {
        this.userPreferenceData() //个人偏好->获分类数据->1：获题材数据 2：获题材数据 ...
      }
    }
  },

  created () {
    this.reloadLocationLanguageData()
    if (this.isLoggedIn) {
      this.userPreferenceData() //个人偏好->获分类数据->1：获题材数据 2：获题材数据 ...
    }
  },
  methods: {

    ...mapActions('auth', ['setShowPersonPreference']),

    ...mapActions('dialog', [
      'hideLoginDialog',
    ]),

    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    selectCategory (id) {
      this.currentIndexId = id
      for (const item of this.categories) {
        if (item.id === this.currentIndexId) {
          this.itemList = item.genresList;
          break;
        }
      }
      console.log("selectCategory itemList:", this.itemList);
    },

    toggleSelection (item) {
      item.isSelected = !item.isSelected
    },

    //偏好标签
    userPreferenceData () {
      userPreferenceApi()
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            this.userPreference = res.data.data
            this.fetchCategoryListData()
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    //获分类数据
    fetchCategoryListData () {
      this.categories = []
      const resultData = []
      categoryListApi()
        .then((res) => {
          if (res.data.code === 200) {
            res.data.data.forEach((item) => {
              if (item.showFlag === 1) {
                resultData.push(item)
              }
            })
            this.categories = resultData
            this.currentIndexId = this.categories[0].id

            //遍历分类-获取题材数据
            Promise.all(this.categories.map(async (item) => {
              item.genresList = await this.fetchGenresListData(item.id);
            })).then(() => {
              console.log("遍历分类-获取题材数据 categories:", this.categories);
              // this.categories.forEach((item) => {
              // const itemGenresList = item.genresList;

              // if (this.userPreference.typeGenres.hasOwnProperty(item.id)) {
              //   const targetGenresList = this.userPreference.typeGenres[item.id];

              //   if (itemGenresList) {
              //     itemGenresList.forEach((genre) => {
              //       if (targetGenresList.includes(genre.id)) {
              //         genre.isSelected = true; // 匹配成功，设置 isCheck 为 true
              //       } else {
              //         genre.isSelected = false; // 匹配不成功，设置 isCheck 为 false
              //       }
              //     });
              //   }
              // }
              // });
              console.log("遍历分类-获取题材数据-匹配后的数据 categories:", this.categories);

              for (const item of this.categories) {
                if (item.id === this.currentIndexId) {
                  this.itemList = item.genresList;
                  break;
                }
              }
              console.log("遍历分类-获取题材数据-匹配后的数据 itemList:", this.itemList);
            });
          }
        })
        .catch((error) => {
        })
    },

    //获题材数据
    fetchGenresListData (typeId) {
      return new Promise((resolve, reject) => {
        const resultData = []
        const params = {
          typeId: typeId
        };
        genresListApi(params)
          .then((res) => {
            if (res.data.code === 200) {
              res.data.data.forEach((item) => {
                if (item.showFlag === 1) {
                  item.isSelected = false
                  resultData.push(item)
                }
              })
              resolve(resultData); // 返回处理后的结果数据
            } else {
              reject(new Error("请求失败")); // 返回错误信息
            }
          })
          .catch((error) => {
            reject(error); // 返回错误信息
          });
      });
    },

    handleMakeSureClick () {
      //个人偏好
      this.preference()
    },


    //个人偏好
    preference () {
      this.categories.forEach((item) => {
        let selectedItems = [];
        const genresList = item.genresList;
        const selectedGenres = genresList.filter((genre) => genre.isSelected === true);

        selectedGenres.forEach((genre) => {
          selectedItems.push(genre.id);
        });
        console.log("selectedItems:", selectedItems)

        const typeId = item.id; // 获取类型ID
        const typeGenres = selectedItems; // 获取isSelect为true的对象的id

        const data = {
          status: [0, 1, 2, 3],
          typeId,
          typeGenres
        };

        allPreferenceListApi(data)
          .then((res) => {
            console.log(res);
            if (res.data.code === 200) {
              this.setShowPersonPreference(false)
              this.handleRemove()
            }
          })
          .catch((error) => {
            console.error(error);
          });

      });
    },

    handleRemove () {
      this.currentIndexId = undefined
      this.currentIndexPreferenceData = []
      this.categories = []
      this.itemList = []
      this.selectedItems = []
      this.userPreference = {}
    },
    close () {

    },
  }
}
</script>


<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100000;
  animation: fadeIn 0.5s ease-out;
}

.pop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.pop-dialog-bg {
  width: 622px;
  height: 714px;
  /* background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe); */
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

.pop-dialog {
  width: 620px;
  height: 712px;
  border-radius: 29px;
  margin: 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.pop-dialog-title {
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
  margin: 30px 20px;
}

/* Category---start */
.category {
  display: flex;
  justify-content: left;
  align-items: start;
  align-content: start;
  height: 65px;
  overflow: hidden;
  width: calc(100% - 100px);
  margin-top: 0px;
  margin-left: 50px;
  margin-right: 50px;
}

.category-item {
  position: relative;
  /* flex-grow: 1; */
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  cursor: pointer;
  font-size: 20px;
  color: #fff;
  font-family: Source Han Sans SC-Regular;
  margin-right: 15px;
}

.category-item span.active {
  color: #fff;
  font-family: Source Han Sans SC-Medium;
  font-size: 20px;
}

.category-item .underline {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  width: 17px;
  height: 3px;
  background-color: #fff;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;
}

.category-item .underline.active {
  transform: translateX(-50%) translateY(-100%);
}
/* Category---end */
.active {
  font-size: 20px;
  font-family: Source Han Sans SC-Bold;
}

.indicator {
  width: 380px;
  height: 3px;
  background-color: transparent;
  transition: transform 0.3s ease-in-out;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  border-radius: 1.5px;
  margin-top: 18px;
  z-index: 10;
}

.indicator.active {
  background-color: #ff036a;
}

.line {
  background-color: #ddd;
  width: 100%;
  height: 1px;
  z-index: 3;
  margin-top: -12px;
}

.preferences-items {
  height: 100%;
  margin: 20px 50px;
  overflow: auto;
}

.item {
  display: inline-block;
  width: calc(100% / 3 - 12px);
  height: 70px;
  text-align: center;
  margin-right: 10px;
  margin-bottom: 10px;
  border: 1px dashed #777;
  cursor: pointer;
  border-radius: 15px;
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
}

.item-title {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
}

.item.selected {
  border: 1px solid white;
  background-color: transparent;
}

.makesure {
  margin-bottom: 30px;

  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Medium;
  padding: 4px 50px;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  border-radius: 18px;
  cursor: pointer;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>

<style scoped>
@media only screen and (max-width: 1024px) {
  .pop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 100000;
    animation: fadeIn 0.5s ease-out;
  }

  .pop-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
  }

  .pop-dialog-bg {
    width: 602px;
    height: 274px;
    /* background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe); */
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 80px;
  }

  .pop-dialog {
    width: 600px;
    height: 272px;
    border-radius: 29px;
    margin: 1px 1px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: url("@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .pop-dialog-title {
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Medium;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 15px;
  }

  /* Category---start */
  .category {
    display: flex;
    justify-content: left;
    align-items: start;
    align-content: start;
    height: 150px;
    overflow: hidden;
    width: calc(100% - 100px);
    margin-top: -40px;
    margin-left: 50px;
    margin-right: 50px;
  }

  .category-item {
    position: relative;
    /* flex-grow: 1; */
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    cursor: pointer;
    font-size: 18px;
    color: #fff;
    font-family: Source Han Sans SC-Regular;
    margin-right: 15px;
  }

  .category-item span.active {
    color: #fff;
    font-family: Source Han Sans SC-Medium;
    font-size: 14px;
  }

  .category-item .underline {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) translateY(100%);
    width: 17px;
    height: 3px;
    background-color: #fff;
    border-radius: 2px;
    transition: all 0.3s ease-in-out;
  }

  .category-item .underline.active {
    transform: translateX(-50%) translateY(-100%);
  }
  /* Category---end */
  .active {
    font-size: 14px;
    font-family: Source Han Sans SC-Bold;
  }

  .indicator {
    width: 380px;
    height: 3px;
    background-color: transparent;
    transition: transform 0.3s ease-in-out;
    display: flex;
    justify-content: center;
    align-content: center;
    align-items: center;
    border-radius: 1.5px;
    margin-top: 18px;
    z-index: 10;
  }

  .indicator.active {
    background-color: #ff036a;
  }

  .line {
    background-color: #ddd;
    width: 100%;
    height: 1px;
    z-index: 3;
    margin-top: -12px;
  }

  .preferences-items {
    height: 100%;
    margin: 20px 50px;
    overflow: auto;
  }

  .item {
    display: inline-block;
    width: calc(100% / 4 - 12px);
    height: 50px;
    text-align: center;
    margin-right: 10px;
    margin-bottom: 10px;
    border: 1px dashed #777;
    cursor: pointer;
    border-radius: 10px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
  }

  .item-title {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    align-content: center;
  }

  .item.selected {
    border: 1px solid white;
    background-color: transparent;
  }

  .makesure {
    margin-bottom: 20px;
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Medium;
    padding: 4px 40px;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    border-radius: 18px;
    cursor: pointer;
  }

  .pop-wrapper.fade-out {
    animation: fadeOut 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>