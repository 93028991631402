<template>
  <div>

    <CreatCollectPop></CreatCollectPop>

    <CollectOfWorksPop></CollectOfWorksPop>

    <CollectOfWorksSearchPop></CollectOfWorksSearchPop>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import CreatCollectPop from '@/views/Collect/Pop/CreatCollectPop.vue';
import CollectOfWorksPop from '@/views/Collect/Pop/CollectOfWorksPop.vue';
import CollectOfWorksSearchPop from '@/views/Collect/Pop/CollectOfWorksSearchPop.vue';

export default {
  components: {
    CreatCollectPop,
    CollectOfWorksPop,
    CollectOfWorksSearchPop
  },
  computed: {
    ...mapState('collectDialog', ['isCollectOfWorksDialogVisible', 'isCreatCollectDialogVisible']),
  },

  mounted () {
    if (this.isCollectOfWorksDialogVisible) {
      this.handleOpenCollectOfWorksDialog();
    }

    if (this.isCreatCollectDialogVisible) {
      this.handleOpenCreatCollectDialog();
    }
  },

  methods: {
    ...mapActions('collectDialog', ['showCreatCollectDialog', 'hideCreatCollectDialog', 'showCollectOfWorksDialog', 'hideCollectOfWorksDialog', 'showCollectOfWorksSearchDialog', 'hideCollectOfWorksSearchDialog']),

    handleOpenCollectOfWorksDialog () {
      this.showCollectOfWorksDialog();
    },

    handleCloseCollectOfWorksDialog () {
      this.hideCollectOfWorksDialog();
    },

    handleOpenCreatCollectDialog () {
      this.showCreatCollectDialog();
    },

    handleCloseCreatCollectDialog () {
      this.hideCreatCollectDialog();
    },
  }
};
</script>
