// store/dialog.js

const state = {
  isLoginDialogVisible: false, // 登录弹窗的显示状态
  isForgetPasswordDialogVisible: false, // 忘记密码弹窗的显示状态
  isSignUpDialogVisible: false, // 注册弹窗的显示状态
  isTryForFreeDialogVisible: false, // Try for free 弹窗的显示状态
};

const mutations = {
  SET_LOGIN_DIALOG_VISIBLE(state, isVisible) {
    state.isLoginDialogVisible = isVisible;
  },
  SET_FORGET_PASSWORD_DIALOG_VISIBLE(state, isVisible) {
    state.isForgetPasswordDialogVisible = isVisible;
  },
  SET_SIGNUP_DIALOG_VISIBLE(state, isVisible) {
    state.isSignUpDialogVisible = isVisible;
  },
  SET_TRY_FOR_FRE_DIALOG_VISIBLE(state, isTryForFree) {
    state.isTryForFreeDialogVisible = isTryForFree;
  },
};

const actions = {
  showLoginDialog({ commit }) {
    commit('SET_LOGIN_DIALOG_VISIBLE', true);
  },
  hideLoginDialog({ commit }) {
    commit('SET_LOGIN_DIALOG_VISIBLE', false);
  },
  showForgetPasswordDialog({ commit }) {
    commit('SET_FORGET_PASSWORD_DIALOG_VISIBLE', true);
  },
  hideForgetPasswordDialog({ commit }) {
    commit('SET_FORGET_PASSWORD_DIALOG_VISIBLE', false);
  },
  showSignupDialog({ commit }) {
    commit('SET_SIGNUP_DIALOG_VISIBLE', true);
  },
  hideSignupDialog({ commit }) {
    commit('SET_SIGNUP_DIALOG_VISIBLE', false);
  },
  showTryForFreeDialog({ commit }) {
    commit('SET_TRY_FOR_FRE_DIALOG_VISIBLE', true);
  },
  hideTryForFreeDialog({ commit }) {
    commit('SET_TRY_FOR_FRE_DIALOG_VISIBLE', false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
