import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

const router = new VueRouter({
    mode: 'history', //去掉url中的#

    routes: [
      // home首页
       {
            path: '/:language/',
            name: 'language-home',
            component: () => import('../views/Home.vue'), //异步加载组件
            meta: {
              keepAlive: true 
            }
        },
        // 发现
        {
            path: '/:language/discover',
            name: 'language-discover',
            component: () => import('../views/Discover.vue'),
            meta: {
              keepAlive: true 
            }
        },
        // 收藏夹
        {
            path: '/:language/collect',
            name: 'language-collect',
            component: () => import('../views/Collect/Collect.vue'),
        },
        // 关于我们
        {
            path: '/about',
            name: 'about',
            component: () => import('../views/About.vue'),
        },
        // 我的
        {
            path: '/:language/mine',
            name: 'language-mine',
            component: () => import('../views/Mine.vue'),
        },
        // 收藏夹详情
        {
            path: '/:language/collectDetail/:id',
            name: 'collect-detail',
            component: () => import('../views/Collect/CollectDetail.vue'),
        },
        // 通用详情
        {
            path: '/:language/:id-:name',
            name: 'detail',
            component: () => import('../views/Detail/DetailNewPage.vue'),
      },
         {
            path: '/:language/DEV',
            name: 'DEV',
            component: () => import('../views/DEV.vue'),
      },
      // 联系我们
         {
            path: '/:language/contactUs',
            name: 'language-contact-us',
            component: () => import('../views/ContactUs.vue'),
      },
      // 工具
         {
            path: '/:language/tools',
            name: 'language-tools',
            component: () => import('../views/Tools.vue'),
      },
      // 店铺
          {
            path: '/:language/shop',
            name: 'shop',
            component: () => import('../views/shop/index.vue'),
      },
      // 店铺 接待大厅
          {
            path: '/:language/shopSaloon',
            name: 'shopSaloon',
            component: () => import('../views/shop/shopSaloon.vue'),
      },
      // 店铺 详情
          {
            path: '/:language/shopDramaDetail',
            name: 'shopDramaDetail',
            component: () => import('../views/shop/shopDramaDetail.vue'),
      },
    ],
});

router.afterEach((to, from) => {
  setTimeout(() => {
    (function () {
      console.log(window.location)
      const hostname = window.location.hostname;
      console.log("hostname:", hostname);
      
      // 判断是否为测试环境
      const isQa= hostname.includes("qa");
      console.log("hostname:", isQa);

      const isLocalhost= hostname.includes("localhost");
      console.log("hostname:", isLocalhost);

      const is192= hostname.includes("192");
      console.log("hostname:", is192);
      
      // 如果是测试环境，不执行百度统计代码
      if (isQa || isLocalhost || is192) {
        console.log("如果是测试环境，不执行百度统计代码");
        return;
      }
      
      document.getElementById('baidu_tj') && document.getElementById('baidu_tj').remove();
      var hm = document.createElement("script");
      hm.src = "https://hm.baidu.com/hm.js?8df1a81e8eb205adb7276faa222e44f4";
      hm.id = "baidu_tj";
      var s = document.getElementsByTagName("script")[0];
      s.parentNode.insertBefore(hm, s);
    })();
  }, 0);
});

export default router;
