<!-- DetailMenuPage -->
<template>
  <div class="detail-menu-page-contaner">
    <div class="menu-left-arrow"
         v-show="showLeftScroll">
      <img class="menu-left-scroll"
           src="@/assets/detail/right.png"
           @click="menuSlideLeft"
           alt="" />
    </div>
    <div class="menu-container"
         v-show="tempMenuList && tempMenuList.length > 0">
      <div class="menu-container-item"
           v-for="(item, index) in tempMenuList"
           :key="index"
           @click="selectItemAction(item)"
           @mouseover="handleMouseOver(item)"
           @mouseout="handleMouseOut(item)">
        <img v-if="item.mode === 1 || item.mode === 2"
             :src="item.imgUrl"
             alt="" />
        <img v-else
             :src=avatar
             alt="" />
        <div class="menu-container-item-detail">
          <div :class="{'menu-container-item-detail-container-select': item.isSelect, 'menu-container-item-detail-container-normal': !item.isSelect,}">
            <div :class="{'menu-container-item-detail-container-top-enable': item.isClick, 'menu-container-item-detail-container-top-disable': !item.isClick,}">
              {{ item.name }}
            </div>
          </div>
          <div v-show="item.isSelect"
               class="menu-container-item-detail-container-bottom">
          </div>
        </div>
        <div v-show="!item.isSelect && !item.hover"
             class="menu-container-item-top"></div>

      </div>
    </div>
    <div class="menu-right-arrow"
         v-show="showRightScroll">
      <img class="menu-right-scroll"
           src="@/assets/detail/left.png"
           @click="menuSlideRight"
           alt="" />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    menuList: {
      type: [],
      required: true
    }
  },
  created () {
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    if (mediaQuery.matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()
  },

  watch: {
    menuList (newVal) {
      this.foundSelected = false;
      if (newVal && newVal.length > 0) {
        console.log("头像菜单列表：", newVal);
        this.tempMenuList = newVal.map((item, index) => {
          if (item.isClick === 1 && this.foundSelected === false) {
            this.foundSelected = true;
            return {
              ...item,
              hover: false,
              isSelect: true,
            };
          } else {
            return {
              ...item,
              hover: false,
              isSelect: false,
            };
          }
        }).map((item, index) => {
          if (item.isSelect === true) {
            this.selectItem = item;
            this.$emit('selectItemData', this.selectItem);
          }
          return item;
        });

        // if (this.isMobile) {
        //   if (this.newRelatedList && this.newRelatedList.length > 5) {
        //     this.newRelatedList = this.newRelatedList.slice(0, 5);
        //   }
        // }
        setTimeout(() => {
          this.updateScrollButtonVisibility();
        }, 2500);
      } else {
        console.log('menuList is empty or undefined');
      }
    }
  },

  data () {
    return {
      tempMenuList: [],
      selectItem: {},
      languageType: "",
      isMobile: false,
      showLeftScroll: false,
      showRightScroll: false,
      avatar: require('@/assets/detail/detail_default_avatar.jpg'),
      foundSelected: false
    }
  },

  methods: {
    reloadLocationLanguageData () {
      const language = localStorage.getItem('locationLanguageType')
      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },
    resetData () {
      this.newMenuList = []
      this.influenceList = []
      this.tempMenuList = []
      this.selectItem = {}
    },
    selectItemAction (item) {
      if (!item.isClick) {
        return;
      }
      this.tempMenuList.forEach(menuItem => {
        menuItem.isSelect = menuItem === item;
      });
      this.selectItem = item;
      this.$emit('selectItemData', this.selectItem);
    },

    menuSlideLeft () {
      const container = document.querySelector('.menu-container');
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth;

      container.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },
    menuSlideRight () {
      const container = document.querySelector('.menu-container');
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth;

      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },
    updateScrollButtonVisibility () {
      const container = document.querySelector('.menu-container');
      const maxScrollLeft = container?.scrollWidth - container?.clientWidth;
      this.showLeftScroll = container?.scrollLeft > 0;
      this.showRightScroll = (container?.scrollLeft + 30) < maxScrollLeft;
    },
    handleMouseOver (item) {
      if (!item.isClick) {
        return
      }
      item.hover = true;
    },
    handleMouseOut (item) {
      if (!item.isClick) {
        return
      }
      item.hover = false;
    },
  },

  mounted () {
    const container = document.querySelector('.menu-container');
    container.addEventListener('scroll', this.updateScrollButtonVisibility);
    setTimeout(() => {
      this.updateScrollButtonVisibility();
    }, 2500);
  },

  beforeDestroy () {
    const container = document.querySelector('.menu-container');
    container?.removeEventListener('scroll', this.updateScrollButtonVisibility);
  },
}
</script>

<style scoped>
.detail-menu-page-contaner {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.menu-left-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
}

.menu-right-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-bottom: 5px;
}

.menu-right-scroll {
  cursor: pointer;
  width: 15px;
}

.menu-left-scroll {
  cursor: pointer;
  width: 15px;
}

.menu-container {
  width: 100%;
  margin: 0;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
}

.menu-container-item {
  flex-shrink: 0;
  width: 26%;
  height: 100%;
  margin-right: 20px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.menu-container-item-top {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 1;
  margin-left: 0;
  transition: opacity 0.3s ease;
  border-radius: 10px;
}

/* .menu-container-item:hover::before {
  opacity: 0;
} */
.menu-container-item img {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  border: none;
  object-fit: cover;
  border-radius: 10px;
}

.menu-container-item-detail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0.4vw;
  height: 2.4vw;
}

.menu-container-item-detail-container-select {
  width: 3.8vw;
  margin-left: 0.2vw;
  display: flex;
  flex-direction: column;
  padding: 8% 5% 3% 5%;
  border-radius: 5px;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
}

.menu-container-item-detail-container-normal {
  width: 3.8vw;
  margin-left: 0.2vw;
  display: flex;
  flex-direction: column;
  padding: 8% 5% 3% 5%;
  border-radius: 5px;
  background-image: none;
}

.menu-container-item-detail-container-top-enable {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;
  text-align: center;
}

.menu-container-item-detail-container-top-disable {
  color: #8a8a8a;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;
  text-align: center;
}

.menu-container-item-detail-container-bottom {
  margin-top: 10%;
  transform: translateX(2vw);
  background-color: transparent;
  width: 0;
  height: 0;
  border-right: 5px solid transparent;
  border-left: 5px solid transparent;
  border-top: 5px solid #fff;
}

::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 3px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 6px;
}
</style>