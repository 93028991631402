<template>
  <div>

    <LoginPop></LoginPop>

    <ResetPasswordPop></ResetPasswordPop>

    <SignUpPop></SignUpPop>

    <PersonPreferencePop></PersonPreferencePop>

  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import LoginPop from '@/views/login/LoginPop.vue';
import ResetPasswordPop from '@/views/login/ResetPasswordPop.vue';
import SignUpPop from '@/views/login/SignUpPop.vue';
import PersonPreferencePop from '@/views/login/PersonPreferencePop.vue';

export default {
  components: {
    LoginPop,
    ResetPasswordPop,
    SignUpPop,
    PersonPreferencePop
  },
  computed: {
    ...mapState('dialog', ['isLoginDialogVisible']),
    ...mapState('auth', ['isLoggedIn']),
  },

  mounted () {
    if (this.isLoginDialogVisible) {
      this.handleOpenLogin();
    }
  },

  methods: {
    ...mapActions('dialog', ['showLoginDialog', 'hideLoginDialog', 'showForgetPasswordDialog', 'hideForgetPasswordDialog', 'showSignupDialog', 'hideSignupDialog']),
    ...mapActions('auth', ['login', 'logout']),

    handleOpenLogin () {
      this.showLoginDialog();
      // this.$router.push({ name: 'discover' });
    },

    handleCloseLogin () {
      this.hideLoginDialog();
    },

    loginSuccess () {
      this.$refs.loginPop.close();
      console.log("loginSuccess");
      this.handleLogin();
    },

    handleLogin () {
      this.login()
        .then(() => {
          this.hideLoginDialog();
        })
        .catch((error) => {
          console.error('登录失败:', error);
        });
    },

    handleLogout () {
      // 调用注销请求
      this.logout()
        .then(() => {
          // 注销成功后的处理逻辑
        })
        .catch((error) => {
          console.error('注销失败:', error);
          // 处理注销失败的逻辑
        });
    },
  },
};
</script>
