/*
 * Collect API
 */

import request from '@/utils/request';

//详情信息接口
export function detailInfoApi(data, language) {
    return request({
      url: `/api/interlib-content/content/detail/${data}?language=${language}`,
      method: 'GET',
    });
}

// 类别接口
export function categoryApi(id) {
    return request({
      url: `/api/interlib-content/category/type/${id}`,
      method: 'GET',
    });
}

// 导演详情接口
export function directorDetailApi(data) {
    return request({
      url: '/api/interlib-content/content/director/detail',
      method: 'POST',
      data
    });
}

// 编剧详情接口
export function screenwriterDetailApi(data) {
    return request({
      url: '/api/interlib-content/content/screenwriter/detail',
      method: 'POST',
      data
    });
}

// 演员详情接口
export function castDetailApi(data) {
    return request({
      url: '/api/interlib-content/content/cast/detail',
      method: 'POST',
      data
    });
}

// 题材详情接口
export function genresDetailApi(data) {
    return request({
      url: '/api/interlib-content/content/genres/detail',
      method: 'POST',
      data
    });
}

// 制片公司详情接口
export function productionCompanyDetailApi(data) {
    return request({
      url: '/api/interlib-content/content/production_company/detail',
      method: 'POST',
      data
    });
}

//RECOMMEND接口
export function detailRelateApi(data) {
    return request({
        url: '/api/interlib-content/content/detailRelate',
        method: 'POST',
        data,
    });
}