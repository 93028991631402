<!-- 详情查看视频弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="show"
       @click="close">
    <div class="pop-dialog">
      <div class="pop-dialog-container">
        <div class="pop-dialog-container-banner"
             @click="bannerClick($event)">
          <el-carousel ref="carousel"
                       :interval="5000"
                       :autoplay="false"
                       indicator-position="none"
                       :loop="false"
                       arrow="always"
                       class="carousel-wrapper"
                       @change="handleCarouselChange"
                       :initial-index="currentIndex">
            <el-carousel-item v-for="(item, index) in newVideoListData"
                              :key="index">
              <div class="main-img">
                <div class="mouseover-video-container"
                     v-if="currentVideoItem !== item">
                  <video class="video-container"
                         :src="item.mode === 2 ? item.link : item.videoUrl"
                         style="width: 100%; height: 100%; object-fit: cover; border-radius: 10px;"
                         alt=""
                         :poster="currentVideoImgURL"
                         @loadedmetadata="setVideoCurrentTime($event, item)" />
                  <div class="play-button"
                       @click="playVideo(item, index)">
                    <img src="@/assets/detail/pause.png"
                         alt="">
                  </div>
                </div>

                <div class="mouseover-current-video-container"
                     v-else-if="currentVideoItem === item">
                  <video class="video-container"
                         ref="video-current-player"
                         :src="item.mode === 2 ? item.link : item.videoUrl"
                         style="width: 100%; height: 100%;"
                         alt=""
                         controls
                         preload="metadata"
                         :poster="currentVideoImgURL"
                         autoplay
                         type="video/mp4">
                  </video>
                </div>
              </div>
            </el-carousel-item>
          </el-carousel>
          <div class="custom-indicator">
            {{ currentIndex + 1+"/"+ newVideoListData.length}}
          </div>
        </div>

      </div>
      <div class="pop-close">
        <img src="@/assets/detail/pop_close.png"
             @click="close"
             alt="" />
        <span> </span>
      </div>
    </div>
    <div class="discover"
         @click="bottomClick($event)">
      <div class="image-bottom"
           v-show="newVideoListData && newVideoListData.length > 0">
        <div class="image-left-arrow"
             v-show="showLeftScroll">
          <img class="image-left-scroll"
               src="@/assets/detail/right.png"
               @click="imageSlideLeft($event)"
               alt="" />
        </div>

        <div class="image-container"
             v-scroll="updateScrollButtonVisibility"
             v-show="newVideoListData && newVideoListData.length > 0">
          <div class="image-container-item"
               v-for="(item, index) in newVideoListData"
               :key="index"
               @click="itemClick($event, item)">
            <video class="video-container"
                   :src="item.mode === 2 ? item.link : item.videoUrl"
                   style="width: 100%; height: 100%; object-fit: cover; border-radius: 20px;"
                   alt=""
                   :poster="currentVideoImgURL"
                   @loadedmetadata="setVideoCurrentTime($event, item)" />
            <div class="play-button-small">
              <img src="@/assets/detail/pause.png"
                   alt="">
            </div>
            <div class="image-container-item-detail">
              <div class="image-container-item-detail-container">
                <div class="image-container-item-detail-container-top">
                  {{ index + 1 }}
                </div>
              </div>
            </div>

            <div class="image-container-item-detail-top">

            </div>

            <div class="image-container-item-select"
                 v-show="item.isSelect">
              <video class="video-container"
                     :src="item.mode === 2 ? item.link : item.videoUrl"
                     style="width: 100%; height: 100%; object-fit: cover; border-radius: 20px;"
                     alt=""
                     :poster="currentVideoImgURL"
                     @loadedmetadata="setVideoCurrentTime($event, item)" />
              <div class="image-container-item-select-detail">
                <div class="image-container-item-select-detail-container">
                  <div class="image-container-item-select-detail-container-top">
                    {{ index + 1 }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="image-right-arrow"
             v-show="showRightScroll">
          <img class="image-right-scroll"
               src="@/assets/detail/left.png"
               @click="imageSlideRight($event)"
               alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>

import { recordStoreLogActionApi } from '@/api/userLogApi.js'

export default {
  props: {
    videoListData: {
      type: Array,
      default: () => []
    },
  },
  data () {
    return {
      show: false,
      languageType: "",
      isMobile: false,
      newVideoListData: [],
      currentIndex: 0,
      currentVideoImgURL: '',
      currentVideoItem: undefined,
      changeIndexCount: 0

    }
  },
  directives: {
    scroll: {
      inserted: function (el, binding) {
        el.addEventListener('scroll', binding.value);
      },
      unbind: function (el, binding) {
        el.removeEventListener('scroll', binding.value);
      }
    }
  },
  watch: {
    videoListData (newVal) {
      if (newVal) {
        console.log("DetaiVideoPop:", newVal);
        this.newVideoListData = newVal

        let selectIndex = this.newVideoListData.findIndex(item => item.isSelect);
        this.currentIndex = selectIndex !== -1 ? selectIndex : 0;

        const selectedVideo = this.newVideoListData.find(item => item.isSelect);
        if (selectedVideo && selectedVideo.mode === 1) {
          this.currentVideoImgURL = selectedVideo.imgUrl;
        } else if (selectedVideo && selectedVideo.mode === 2) {
          this.currentVideoImgURL = selectedVideo.link;
        }
      }
    }
  },

  created () {
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    if (mediaQuery.matches) {
      this.isMobile = true;
    } else {
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()
  },

  mounted () {
    this.refrashData();
  },

  methods: {
    reloadLocationLanguageData () {
      const language = localStorage.getItem('locationLanguageType')
      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },
    open () {
      this.show = true
      document.body.style.overflow = 'hidden'
      this.refrashData()
    },
    close () {
      // 获取当前视频播放器的引用
      const videoPlayer = this.$refs['video-current-player'];
      if (videoPlayer && videoPlayer.length > 0) {
        const playedTime = videoPlayer[0].currentTime; // 获取当前视频的播放时长
        const duration = videoPlayer[0].duration // 获取当前视频的总时长

        console.log('Current played time of the video:', parseInt(playedTime));
        console.log('Current total time of the video:', parseInt(duration));

        this.fetchStoreEndFlayVideoRecordLogData(parseInt(duration), parseInt(playedTime));
      }


      document.body.style.overflow = 'auto'
      this.$el.addEventListener(
        'animationend',
        () => {
          this.show = false
        },
        { once: true }
      )
      this.$el.classList.remove('fade-in')
      this.$el.classList.add('fade-out')
      this.languageType = ""
      this.isMobile = false
      this.newVideoListData = []
      this.currentIndex = 0
      this.currentVideoImgURL = ''
      this.currentVideoItem = undefined
      this.changeIndexCount = 0
    },
    bottomClick (event) {
      event?.stopPropagation();
    },
    bannerClick (event) {
      event.stopPropagation();
    },
    handleCarouselChange (index) {

      console.log("handleCarouselChange:", index);

      if (this.currentIndex < index) {
        this.changeIndexCount = this.changeIndexCount + 1
      } else {
        this.changeIndexCount = this.changeIndexCount - 1
      }

      if (this.changeIndexCount > 2) {
        this.imageSlideRight();
        this.changeIndexCount = 0;
      } else if (this.changeIndexCount < -2) {
        this.imageSlideLeft();
        this.changeIndexCount = 0;
      }

      this.currentIndex = index;

      this.newVideoListData.forEach((item, i) => {
        if (i === index) {
          item.isSelect = true;
        } else {
          item.isSelect = false;
        }
      });

      this.currentVideoItem = undefined;
      const videoPlayer = this.$refs['video-current-player'];
      console.log("handleCarouselChange-videoPlayer:", videoPlayer);
      if (videoPlayer && videoPlayer.length > 0) {
        videoPlayer[0].pause();
      }
    },
    setVideoCurrentTime (event, item) {
      if (item.mode === 2) {
        const videoElement = event.target;
        videoElement.currentTime = 5;
      }
    },

    playVideo (videoItem, index) {
      const joinStorePage = localStorage.getItem('joinStorePage')
      console.log("joinStorePage:", joinStorePage);
      console.log("Play");
      this.currentVideoItem = videoItem;
      this.$nextTick(() => {
        const videoPlayer = this.$refs['video-current-player'];
        console.log("videoPlayer:", videoPlayer);
        if (videoPlayer[0]) {
          videoPlayer[0].src = videoItem.mode === 2 ? videoItem.link : videoItem.videoUrl; // 设置视频的 URL
          videoPlayer[0].addEventListener('canplay', () => {
            videoPlayer[0]?.play(); // 当视频准备好播放时，执行播放操作
          });
        }
      });
      this.fetchStoreFlayVideoRecordLogData()
    },

    imageSlideLeft (event) {
      event?.stopPropagation();

      const container = document.querySelector('.image-container');
      const itemWidth = container?.offsetWidth;
      const scrollAmount = itemWidth;

      container?.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },


    imageSlideLeft (event) {
      event?.stopPropagation();

      const container = document.querySelector('.image-container');
      const itemWidth = container?.offsetWidth;
      const scrollAmount = itemWidth;

      container?.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },

    imageSlideRight (event) {
      event?.stopPropagation();

      const container = document.querySelector('.image-container');
      const itemWidth = container?.offsetWidth;
      const scrollAmount = itemWidth;

      container?.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
      setTimeout(this.updateScrollButtonVisibility, 500);
    },
    itemClick (event, item) {
      event?.stopPropagation();
      this.newVideoListData.forEach(element => {
        element.isSelect = element === item;
      });
      this.currentIndex = this.newVideoListData.findIndex(i => i.isSelect);

      this.$refs.carousel.setActiveItem(this.currentIndex);
    },

    updateScrollButtonVisibility () {
      const container = document.querySelector('.image-container');
      const maxScrollLeft = container?.scrollWidth - container?.clientWidth;
      console.log("container.scrollLeft", container?.scrollLeft);
      console.log("maxScrollLeft", maxScrollLeft);
      this.showLeftScroll = container?.scrollLeft > 0;
      this.showRightScroll = (container?.scrollLeft + 20) < maxScrollLeft;
    },

    refrashData () {
      const container = document.querySelector('.image-container');
      setTimeout(() => {
        this.updateScrollButtonVisibility();
      }, 1000);

      setTimeout(() => {
        if (this.currentIndex > 5) {
          this.imageSlideRight();
        }
      }, 1000);
    },
    //获取店铺详情视频开始播放埋点数据
    fetchStoreFlayVideoRecordLogData () {
      const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')
      console.log('Current index of the video:', this.currentIndex + 1);
      console.log('Current length of the video:', this.newVideoListData.length);
      recordStoreLogActionApi('3', locationStoreClickInterlibContentId, this.newVideoListData.length, this.currentIndex + 1)
        .then((res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },

    //获取店铺详情视频结束播放埋点数据
    fetchStoreEndFlayVideoRecordLogData (trailersLength, trailersPlayLength) {
      const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')
      console.log('Current index of the video:', this.currentIndex + 1);
      console.log('Current length of the video:', this.newVideoListData.length);
      recordStoreLogActionApi('4', locationStoreClickInterlibContentId, this.newVideoListData.length, this.currentIndex + 1, trailersLength, trailersPlayLength)
        .then((res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },
  }
}
</script>

<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000000;
  animation: fadeIn 0.5s ease-out;
  background-color: rgba(0, 0, 0, 0.8);
  flex-direction: column;
}

.pop-dialog {
  width: 70%;
  height: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: -3%;
  margin-left: 4%;
}

.pop-close {
  display: flex;
  flex-direction: column;
  align-items: end;
  justify-content: space-between;
  width: 5%;
  height: 100%;
}

.pop-close img {
  width: 35px;
  height: auto;
  cursor: pointer;
}

.pop-dialog-container {
  width: 100%;
  height: 100%;
}

.pop-dialog-container-banner {
  width: 100%;
  height: 97%;
  margin-top: 3%;
  border-radius: 25px;
  position: relative;
}

.carousel-wrapper {
  width: 100%;
  height: 100%;
}

::v-deep .el-carousel__container {
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 1);
  border-radius: 25px;
  overflow: hidden;
}

.main-img {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.custom-indicator {
  position: absolute;
  bottom: 0;
  left: 0;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  width: 20%;
  margin-left: 40%;
  text-align: center;
  z-index: 10000;
  margin-bottom: 3%;
}

.mouseover-video-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-in-out;
  background-color: #000;
}

.mouseover-current-video-container {
  height: 100%;
  position: relative;
  display: flex;
  flex-grow: 1;
  background-color: transparent;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.2s ease-in-out;
  background-color: #000;
}

.video-container {
  width: 100%;
  height: 100%;
}

.mouseover-video-container img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.play-button {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.play-button-small {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.discover {
  width: 100%;
  height: 160px;
}

/* image---start */
.image-bottom {
  margin-left: 10%;
  margin-right: 10%;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  margin-top: 30px;
  background-color: rgba(42, 45, 49, 0.75);
  border-radius: 20px;
}

.image-left-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
  margin-bottom: 5px;
  height: 100%;
  width: 30px;
  z-index: 110;
}

.image-right-arrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 5px;
  margin-bottom: 5px;
  height: 100%;
  width: 30px;
  z-index: 110;
}

.image-right-scroll {
  cursor: pointer;
  width: 8px;
}

.image-left-scroll {
  cursor: pointer;
  width: 8px;
}

.image-container {
  width: 100%;
  margin: 0;
  height: 140px;
  display: flex;
  flex-direction: row;
  overflow-x: scroll;
  overflow-y: hidden;
  padding: 0 30px;
  border-radius: 20px;
}

.image-container-item {
  flex-shrink: 0;
  width: 135px;
  height: 90px;
  margin-right: 20px;
  margin-top: 25px;
  padding: 0px;
  box-sizing: border-box;
  position: relative;
  border-radius: 20px;
  /* overflow: hidden; */
  cursor: pointer;
  transition: transform 0.5s ease;
}

.image-container-item img {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  border: none;
  object-fit: cover;
  border-radius: 20px;
}

.image-container-item-detail {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 32px;
}

.image-container-item-detail-top {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  border-radius: 20px;
  background-color: rgba(0, 0, 0, 0.5);
}

.image-container-item-detail-container {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
}

.image-container-item-detail-container-top {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;
}

.image-container-item-select {
  width: 210px;
  height: 130px;
  position: absolute;
  left: -30px;
  top: -20px;
  z-index: 100;
  border-radius: 20px;
  border: 1px solid #fff;
  background-color: rgba(42, 45, 49);
}

.image-container-item-select img {
  width: 100%;
  height: 100%;
  position: absolute;
  display: block;
  border: none;
  object-fit: cover;
  border-radius: 20px;
}

.image-container-item-select-detail {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 32px;
}

.image-container-item-select-detail-container {
  display: flex;
  flex-direction: column;
  padding: 10px 15px 10px 15px;
}

.image-container-item-select-detail-container-top {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-top: -5px;
}
/* image---end */
::-webkit-scrollbar {
  background-color: transparent;
  height: 0px;
}

::v-deep .el-carousel__arrow {
  font-size: 18px !important;
  font-weight: 800 !important;
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  .pop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000000;
    animation: fadeIn 0.5s ease-out;
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
  }

  .pop-dialog {
    width: 70%;
    height: 70%;
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 0;
    margin-left: 4%;
  }

  .pop-close {
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: space-between;
    width: 5%;
    height: 100%;
  }

  .pop-close img {
    width: 35px;
    height: auto;
    cursor: pointer;
  }

  .pop-dialog-container {
    width: 100%;
    height: 100%;
  }

  .pop-dialog-container-banner {
    width: 100%;
    height: 97%;
    margin-top: 3%;
    border-radius: 25px;
    position: relative;
  }

  .carousel-wrapper {
    width: 100%;
    height: 100%;
  }

  ::v-deep .el-carousel__container {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, 1);
    border-radius: 25px;
    overflow: hidden;
  }

  .main-img {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .custom-indicator {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    width: 20%;
    margin-left: 40%;
    text-align: center;
    z-index: 10000;
    margin-bottom: 3%;
  }

  .mouseover-video-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-grow: 1;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.2s ease-in-out;
    background-color: #000;
  }

  .mouseover-current-video-container {
    height: 100%;
    position: relative;
    display: flex;
    flex-grow: 1;
    background-color: transparent;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    transition: all 0.2s ease-in-out;
    background-color: #000;
  }

  .video-container {
    width: 100%;
    height: 100%;
  }

  .mouseover-video-container img {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .play-button {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .play-button-small {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
  }

  .pop-wrapper.fade-out {
    animation: fadeOut 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  .discover {
    width: 100%;
    height: 150px;
  }

  /* image---start */
  .image-bottom {
    margin-left: 10%;
    margin-right: 10%;
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    /* margin-top: 30px; */
    background-color: rgba(42, 45, 49, 0.75);
    border-radius: 20px;
    margin-bottom: 150px;
  }

  .image-left-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
    margin-bottom: 5px;
    height: 100%;
    width: 30px;
    z-index: 110;
  }

  .image-right-arrow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    margin-bottom: 5px;
    height: 100%;
    width: 30px;
    z-index: 110;
  }

  .image-right-scroll {
    cursor: pointer;
    width: 8px;
  }

  .image-left-scroll {
    cursor: pointer;
    width: 8px;
  }

  .image-container {
    width: 100%;
    margin: 0;
    height: 120px;
    display: flex;
    flex-direction: row;
    overflow-x: scroll;
    overflow-y: hidden;
    padding: 0 30px;
    border-radius: 20px;
  }

  .image-container-item {
    flex-shrink: 0;
    width: 115px;
    height: 90px;
    margin-right: 20px;
    margin-top: 25px;
    padding: 0px;
    box-sizing: border-box;
    position: relative;
    border-radius: 20px;
    /* overflow: hidden; */
    cursor: pointer;
    transition: transform 0.5s ease;
  }

  .image-container-item img {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 20px;
  }

  .image-container-item-detail {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 32px;
  }

  .image-container-item-detail-top {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border-radius: 20px;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .image-container-item-detail-container {
    display: flex;
    flex-direction: column;
    padding: 10px 15px 10px 15px;
  }

  .image-container-item-detail-container-top {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -5px;
  }

  .image-container-item-select {
    width: 170px;
    height: 100px;
    position: absolute;
    left: -20px;
    top: -10px;
    z-index: 100;
    border-radius: 20px;
    border: 1px solid #fff;
    background-color: rgba(42, 45, 49);
  }

  .image-container-item-select img {
    width: 100%;
    height: 100%;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 20px;
  }

  .image-container-item-select-detail {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 32px;
  }

  .image-container-item-select-detail-container {
    display: flex;
    flex-direction: column;
    padding: 10px 15px 10px 15px;
  }

  .image-container-item-select-detail-container-top {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: -5px;
  }
  /* image---end */
  ::-webkit-scrollbar {
    background-color: transparent;
    height: 0px;
  }

  ::v-deep .el-carousel__arrow {
    font-size: 18px !important;
    font-weight: 800 !important;
  }
}
</style>