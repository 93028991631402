// store/auth.js

import Cookies from 'js-cookie';

const state = {
    isLoggedIn: false, // 登录状态
    userInfo: {},
    showPersonPreferencePop: false, // 显示个人偏好弹窗
};

const mutations = {
    SET_LOGGED_IN(state, isLoggedIn) {
        state.isLoggedIn = isLoggedIn;
    },
    saveUserInfo(state, userInfo) {
        state.userInfo = userInfo;
    },
    clearUserInfo(state) {
     state.userInfo = {};
  },
     SET_SHOW_PERSON_PREFERENCE_POP(state, showPersonPreference) {
        state.showPersonPreferencePop = showPersonPreference;
    },
};

const actions = {
    login({ commit }) {
        return new Promise((resolve, reject) => {
            commit('SET_LOGGED_IN', true);
            resolve();
        });
    },

    saveUserInfo({ commit, getters }, res) {
        return new Promise(resolve => {
            localStorage.setItem('userInfo', JSON.stringify(res));
            commit('saveUserInfo', res.data.data);
            resolve();
        });
    },

    saveToken({ commit, getters }, res) {
        return new Promise(resolve => {
            Cookies.set('token', res.data.data.token, { expires: 5 });
            // Cookies.set('token', res.data.data.token); 
            resolve();
        });
    },

    logout ({ commit }) {
        console.log('开始执行 logout action');
        return new Promise((resolve, reject) => {
            commit('SET_LOGGED_IN', false);
            commit('clearUserInfo');
            Cookies.remove('token');
            localStorage.removeItem('userInfo');
            console.log('完成执行 logout action');
            resolve();
        });
    },

    getUserInfo({ commit }) {
        const userInfoString = localStorage.getItem('userInfo');
        if (userInfoString) {
            const userInfo = JSON.parse(userInfoString);
            commit('saveUserInfo', userInfo.data.data);
            commit('SET_LOGGED_IN', true); // 更新登录状态为已登录
        } else {
            commit('saveUserInfo', {});
            commit('SET_LOGGED_IN', false); // 更新登录状态为未登录
        }
    },
    setShowPersonPreference({ commit }, showPersonPreference) {
    commit('SET_SHOW_PERSON_PREFERENCE_POP', showPersonPreference);
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
};
