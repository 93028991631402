<!-- 店铺详情页 -->
<template>
  <div class="detail-container">
    <h1 style="text-indent: -9000px;position: absolute;">{{ detailData.projectNameEn }}</h1>
    <!-- <div class="nav">
      <div class="nav-left">
      </div>
      <div class="nav-right">
        <div class="nav-search">
          <div class="nav-search-box"></div>
          <img :src="languageType == 'en' ? require('@/assets/common/chiness.jpg') : require('@/assets/common/english.jpg')"
               @click="navTranslate"
               alt="">
        </div>
      </div>
    </div> -->
    <div :class="{ 'main-container': showType === 0, 'main-container-big': showType === 1 }">
      <div class="main-top-container">
        <div class="main-info-left-container"
             v-show="showType === 0">
          <div class="main-info-left-pic-container">
            <div v-show="detailPosterUrl"
                 class="main-info-left-pic-left-container">
              <a :href="'https://www.interlib.asia/' + (languageType == 'en' ? 'en/' : 'cn/') + detailData.id + '-' + (detailData.projectNameEn ? detailData.projectNameEn.replace(/\s+/g, '-') : '')"></a>
              <img :src="detailPosterUrl"
                   alt="" />

              <div class="top-collect">
                <!-- <img :src="detailCollectionFlag == 1 ? require('@/assets/home/collect-select.jpg') : require('@/assets/home/collect-normal.jpg') "
                     alt=""
                     @click="topCollectClick(detailData, $event)"> -->
              </div>
            </div>

            <div class="main-info-left-pic-right-container">
              <div class="main-info-left-pic-right-top-container">
                <div class="main-info-left-pic-right-container-title"
                     slot="reference">
                  <el-popover placement="top-start"
                              width="400"
                              trigger="hover"
                              :visible-arrow="true"
                              :content="languageType == 'en' ? detailData.projectNameEn : detailData.projectName">
                    <div class="main-info-left-pic-right-container-title"
                         slot="reference">
                      {{languageType == 'en' ? detailData.projectNameEn : detailData.projectName}}
                    </div>
                  </el-popover>
                </div>
                <div class="main-info-left-pic-right-container-type">
                  <div class="main-info-left-pic-right-container-type-text"
                       v-show="languageType == 'en' ? categoryTypeData.nameEn : categoryTypeData.name">
                    {{languageType == 'en' ? categoryTypeData.nameEn : categoryTypeData.name}}
                  </div>
                  <div class="main-info-left-pic-right-container-mid-line"
                       v-show="detailData.episode || detailData.length">
                    |
                  </div>
                  <div class="main-info-left-pic-right-container-type-time"
                       v-show="detailData.episode || detailData.length">
                    {{detailData.episode + 'eps' + ' x ' + detailData.length + 'mins' }}
                  </div>
                </div>
                <div class="main-info-left-pic-right-container-tabs">
                  <div v-for="(category, index) in categories"
                       :key="index"
                       :class="{ 'main-info-left-pic-right-container-tabs-item-selected': categorySelected === category, 'main-info-left-pic-right-container-tabs-item-unselected': categorySelected !== category }"
                       @click="handleCategoryClick(category, index)">
                    <div class="main-info-left-pic-right-container-tabs-item-text">
                      <div v-if="category === $t('detail_trailers') || category === $t('detail_screeners')">
                        <img src="@/assets/detail/detail_btn_tips.png"
                             alt="">
                      </div>
                      {{category }}
                    </div>
                  </div>
                </div>
              </div>
              <div class="main-info-left-pic-right-bottom-container">
                <div class="main-info-left-pic-right-bottom-container-formats"
                     v-show="detailData.formats && detailData.formats.length > 0">
                  {{ $t('detail_formats') }}
                  {{ detailData.formats ? detailData.formats.join(',')  : '' }}
                </div>

                <div v-show="tipsList && tipsList.length > 0"
                     class="main-info-left-pic-right-bottom-container-tips">
                  {{ tipsList.join(' • ') }}
                </div>

                <!-- <div v-show="tipsList && tipsList.length > 0"
                     class="main-info-left-pic-right-bottom-container-tips">
                  <div v-for="(tips, index) in tipsList"
                       :key="index"
                       class="main-info-left-pic-right-bottom-container-tips-span">
                    <span> {{ tips }}

                    </span>
                    <span v-show="index < tipsList.length - 1"
                          class="main-info-left-pic-right-bottom-container-tips-dian"> •
                    </span>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
          <div class="main-info-left-detail-container">
            {{ languageType == 'en' ? detailData.synopsisEn : detailData.synopsis}}
          </div>
          <div class="main-info-left-deta-score-container">
            <div class="main-info-left-deta-score-left-container"
                 v-show="detailData.score && detailData.score.length > 0 || detailData.premiereDate">
              <div v-show="detailData.premiereDate"
                   class="main-info-left-deta-score-left-date-container">
                <div class="main-info-left-deta-score-left-container-tips">
                </div>
                <span class="main-info-container-item-date-title">{{ $t('detail_premiere_date') }}</span>
                <span class="main-info-container-item-date-detail">{{ detailData.premiereDate }}</span>
              </div>
              <div v-show="detailData.score && detailData.score.length > 0"
                   class="main-info-left-deta-score-left-score-container">
                <div class="main-info-left-deta-score-left-container-tips">
                </div>
                <span class="main-info-container-item-score-title">{{ $t('detail_score') }}</span>
                <div class="main-info-container-item-detail-source">
                  <div class="main-info-container-item-detail-source-dou"
                       v-if="this.doubanScoreData">
                    <img src="@/assets/detail/douban.png"
                         alt=""
                         @click="doubanClick">
                  </div>
                  <div class="main-info-container-item-detail-source-dou-data"
                       v-if="this.doubanScoreData">
                    {{ this.doubanScoreData.score }}
                  </div>
                  <div class="main-info-container-item-detail-source-dou-mdl-mid"
                       v-if="this.myDramaListScoreData && this.doubanScoreData">
                  </div>
                  <div class="main-info-container-item-detail-source-mdl"
                       v-if="this.myDramaListScoreData">
                    <img src="@/assets/detail/mdl.png"
                         alt=""
                         @click="mdlClick">
                  </div>
                  <div class="main-info-container-item-detail-source-mdl-data"
                       v-if="this.myDramaListScoreData">
                    {{ this.myDramaListScoreData.score }}
                  </div>
                  <div class="main-info-container-item-detail-source-dou-mdl-mid"
                       v-if="this.myDramaListScoreData || this.doubanScoreData">
                  </div>
                  <div class="main-info-container-item-detail-source-imdb"
                       v-if="this.imdbScoreData">
                    <img src="@/assets/detail/imdb.png"
                         alt=""
                         @click="imdbClick">
                  </div>
                  <div class="main-info-container-item-detail-source-mdl-data"
                       v-if="this.imdbScoreData">
                    {{ this.imdbScoreData.score }}
                  </div>
                </div>
              </div>
            </div>
            <div class="main-info-left-deta-score-right-container">
              <div v-show="languageType == 'en' ? detailData.broadcastingEn : detailData.broadcasting"
                   class="main-info-left-deta-score-right-platform-container">
                <div class="main-info-left-deta-score-left-platform-container-tips">
                </div>
                <span class="main-info-container-item-platform-title">{{ $t('detail_premiere_platform') }}</span>
                <span class="main-info-container-item-platform-detail">{{ languageType == 'en' ? detailData.broadcastingEn : detailData.broadcasting}}</span>
              </div>
              <!-- <div class="main-info-left-deta-score-right-tk-container">
                <div class="main-info-left-deta-score-left-container-tips">
                </div>
                <span class="main-info-container-item-title">TikTok:</span>
                <span class="main-info-container-item-detail-tk">#themagicalwomen 1M
                  #แม่เลี้ยงเดี่ยวเฟี้ยวได้ใจ 1M</span>
              </div> -->
            </div>
          </div>
        </div>
        <div :class="containerClass"
             v-show="showType === 1">
          <div class="mine-container"
               :style=" menuContainerStyle ">
            <div class="info-container">
              <a :href="'https://www.interlib.asia/' + (languageType == 'en' ? 'en/' : 'cn/') + detailData.id + '-' + (detailData.projectNameEn ? detailData.projectNameEn.replace(/\s+/g, '-') : '')"></a>
              <div class="info-content-top"
                   :style="{ backgroundImage: 'url(' + detailPosterUrl + ')' }">
              </div>
              <div class="top-collect-type-1">
                <!-- <img :src="detailCollectionFlag == 1 ? require('@/assets/home/collect-select.jpg') : require('@/assets/home/collect-normal.jpg') "
                     alt=""
                     @click="topCollectClick(detailData, $event)"> -->
              </div>
              <div class="top-type-back">
                <img src="@/assets/detail/detail_type_back.png"
                     alt=""
                     @click="topTypeBackClick()">
              </div>
              <div class="top-type-contact">
                <img src="@/assets/detail/detail_type1_contect.png"
                     alt=""
                     @click="topTypeContectClick()">
              </div>
              <div class="info-content-bottom">
                <div class="info-content-bottom-title"
                     v-show="deviceType !== 2">
                  {{languageType == 'en' ? detailData.projectNameEn : detailData.projectName}}
                </div>
                <div class="info-content-bottom-type"
                     v-show="shouldShowCategory">
                  {{languageType == 'en' ? categoryTypeData.nameEn : categoryTypeData.name}}
                </div>
                <div class="info-content-bottom-time"
                     v-show="deviceType !== 2 && detailData.episode || deviceType !== 2 &&detailData.length">
                  {{detailData.episode + 'eps' + ' x ' + detailData.length + 'mins' }}
                </div>
                <div class="info-content-bottom-score"
                     v-show="deviceType !== 2">
                  <div class="info-content-bottom-score-dou"
                       v-if="this.doubanScoreData">
                    <img src="@/assets/detail/douban.png"
                         alt=""
                         @click="doubanClick">
                  </div>
                  <div class="info-content-bottom-score-dou-data"
                       v-if="this.doubanScoreData">
                    {{ this.doubanScoreData.score }}
                  </div>
                  <div class="info-content-bottom-score-mdl"
                       v-if="this.myDramaListScoreData">
                    <img src="@/assets/detail/mdl.png"
                         alt=""
                         @click="mdlClick">
                  </div>
                  <div class="info-content-bottom-score-mdl-data"
                       v-if="this.myDramaListScoreData">
                    {{ this.myDramaListScoreData.score }}
                  </div>
                </div>
                <div class="info-content-bottom-score-imdb-container"
                     v-show="deviceType !== 2">
                  <div v-if="this.imdbScoreData">
                    <img class="info-content-bottom-score-imdb-container-img"
                         src="@/assets/detail/imdb.png"
                         alt=""
                         @click="imdbClick">
                  </div>
                  <div class="info-content-bottom-score-imdb-container-data"
                       v-if="this.imdbScoreData">
                    {{ this.imdbScoreData.score }}
                  </div>
                </div>
                <div class="top-main-mid-line"
                     v-show="deviceType !== 2">
                </div>
              </div>
            </div>
            <div class="menu-container">
              <div v-for="(item, index) in items"
                   :key="index"
                   @click="selectItem(item, index)"
                   :class="{ 'selected': selectedItemIndex === index, 'unselected': selectedItemIndex !== index }">
                {{ item }}
                <img v-show="item !== $t('detail_stills')"
                     src="@/assets/detail/menu_arrow.png"
                     alt="">
              </div>
            </div>

          </div>
          <div class="slider-menu-container"
               v-show="categorySelected !== $t('detail_stills')">
          </div>
          <div @click="sliderClick"
               class="slider-container"
               :style=" deviceType === 2? mobileSliderContainerStyle : sliderContainerStyle "
               v-show="categorySelected !== $t('detail_stills')">
            <img v-show="sliderIsOpen"
                 src="@/assets/detail/slider_arrow_close.png"
                 alt="">
            <img v-show="!sliderIsOpen"
                 src="@/assets/detail/slider_arrow_open.png"
                 alt="">
          </div>
          <div v-show="sliderIsOpen"
               class="bottom-container-right">
            <div class="discover">
              <div class="trailers"
                   v-show="trailersListData && trailersListData.length > 0">
                <div class="trailers-up-arrow"
                     v-show="showUpScroll">
                  <img class="trailers-up-scroll"
                       src="@/assets/detail/menu_arrow_up.png"
                       @click="trailersSlideUp"
                       alt="" />
                </div>
                <div class="trailers-container"
                     v-show="trailersListData && trailersListData.length > 0">
                  <div class="trailers-container-item"
                       v-for="(item, index) in trailersListData"
                       :key="index"
                       @click="trailerClick(item)">
                    <img v-if="item.mode === 1"
                         class="trailers-container-item-img"
                         :src="item.imgUrl"
                         alt="" />

                    <div v-else-if="item.mode === 2"
                         class="trailers-container-item-img">
                      <video :src="item.link"
                             style="width: 100%; height: 100%; object-fit: cover; border-radius: 5px;"
                             alt=""
                             preload="metadata"
                             @loadedmetadata="setVideoCurrentTime($event)" />
                    </div>

                    <div class="history-container-item-img-overlay"
                         v-show="!item.isSelect">
                    </div>

                    <div class="trailers-container-item-detail">
                      <div class="trailers-container-item-detail-container">
                        <div v-show="categorySelected === $t('detail_trailers')"
                             class="trailers-container-item-detail-container-top">
                          {{ $t('detail_trailers') + (index + 1) }}
                        </div>
                        <div v-show="categorySelected === $t('detail_screeners')"
                             class="trailers-container-item-detail-container-top">
                          {{ $t('detail_screeners') + (index + 1) }}
                        </div>
                      </div>
                    </div>

                    <div v-if="item.isSelect && trailersMenuIsClick=== false"
                         class="trailers-container-item-img-top">
                    </div>

                    <img v-else-if="item.isSelect && trailersMenuIsClick=== true"
                         class="trailers-container-item-img-top-gif"
                         src="@/assets/detail/top_menu_bg.gif"
                         alt="">
                    <!-- <div class="history-container-item-img-top-dot"
                         v-show="item.isSelect">
                      <div class="history-container-item-img-top-dot-item"></div>
                    </div> -->
                    <div class="item-pause">
                      <img src="@/assets/detail/pause.png"
                           alt="">
                    </div>
                  </div>
                </div>
                <div class="trailers-down-arrow"
                     v-show="showDownScroll">
                  <img class="trailers-down-scroll"
                       src="@/assets/detail/menu_arrow_down.png"
                       @click="trailersSlideDown"
                       alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div :class="{ 'main-info-right-container-type-0': showType === 0, 'main-info-right-container-type-1': showType === 1 }"
             v-show="categorySelected === $t('detail_stills')">
          <div class="main-info-right-container-grid-item"
               v-for="(image, index) in stillsShowImages"
               :key="index"
               :style="{ backgroundImage: 'url(' + image + ')' }"
               @click="stillsShowImagesClick(image)">
            <div v-if="index === (showType === 0 ? stillsShowImagesCount : stillsShowImagesBigCount) - 1 && (showType === 0 ? stillsImages.length > 9 : stillsImages.length > 12)"
                 class="main-info-right-container-grid-item-top-special-div">
              <span>{{ $t('home_more')}}</span>
            </div>
          </div>
        </div>
        <div :class="{ 'main-info-right-container-trailers-container-type-0': showType === 0, 'main-info-right-container-trailers-container-type-1': showType === 1 && sliderIsOpen === true,'main-info-right-container-trailers-container-type-1-close': showType === 1 && sliderIsOpen === false }"
             v-show="categorySelected === $t('detail_trailers') && detailData.trailers && detailData.trailers.length > 0">
          <trailers :activeElement="activeElement"
                    :trailers="detailData.trailers"></trailers>
        </div>
        <div :class="{ 'main-info-right-container-trailers-container-type-0': showType === 0, 'main-info-right-container-trailers-container-type-1': showType === 1 && sliderIsOpen === true,'main-info-right-container-trailers-container-type-1-close': showType === 1 && sliderIsOpen === false }"
             v-show="categorySelected === $t('detail_screeners') && detailData.screeners && detailData.screeners.length > 0">
          <trailers :activeElement="activeElement"
                    :trailers="detailData.screeners"></trailers>
        </div>
      </div>
      <div class="submitted-tips"
           v-if="showSubmittedTips"
           :class="{ 'slide-down-enter-active': showSubmittedTips, 'slide-up-leave-active': !showSubmittedTips }"
           :key="showSubmittedTips">
        {{ $t('detail_contact_us_success') }}
      </div>
      <div v-show="detailData.id"
           class="main-mid-line">
      </div>
    </div>
    <div class="main-mid-container"
         v-if="menuList.length > 0">
      <!-- 中间页 -->
      <midPage :detailData="detailData"
               :menuListData="menuList"
               @clickAndScrollToTop="handleScrollToTop"></midPage>
    </div>
    <div class="main-bottom-container">
      <!-- 页脚 -->
      <bottom @clickAndScrollToTop="handleScrollToTop"></bottom>
    </div>
    <!-- Detail-Contact Seller弹窗 -->
    <contactSellerPop ref="contactSellerPop"
                      :issuerInfo="issuerInfo"></contactSellerPop>

    <!-- 查看大图弹窗 -->
    <bigImagePop ref="bigImagePop"
                 :imgListData="newStillsImages"></bigImagePop>
  </div>
</template>

<script>
import StoreDetailCastPage from '@/views/store/StoreDetailCastPage.vue'//演员页面
import StoreDetailBottomPage from '@/views/store/StoreDetailBottomPage.vue'//详情底部页面
import StoreDetailTrailersPage from '@/views/store/StoreDetailTrailersPage.vue'
import DetailGenresPage from '@/views/Detail/New/DetailGenresPage.vue'//详情题材页面
import StoreDetailProductionCompanyPage from '@/views/store/StoreDetailProductionCompanyPage.vue'// 制片公司页面
import StoreContactSellerPop from '@/views/store/StoreContactSellerPop.vue' // 联系卖家弹窗页面
import DetaiImagePop from '@/views/Detail/New/DetaiImagePop.vue'// 查看大图弹窗页面
import StoreDetailMidPage from '@/views/store/StoreDetailMidPage.vue'//详情中间页面
import { detailInfoApi, categoryApi, detailRelateApi } from '@/api/detailApi.js'
import { getPresignedUrlApi } from '@/api/homeApi.js'

import { mapState, mapActions } from 'vuex';

import { recordUserActionApi } from '@/api/userLogApi.js'

export default {
  metaInfo () {
    return {
      title: this.pageTitleName,
      meta: [
        {
          name: 'description', content: this.pageDescription
        },
        { name: 'keywords', content: this.pageKeywords },
        { property: 'og:title', content: this.pageTitleName },
        {
          property: 'og:description', content: this.pageDescription
        },
      ]
    }
  },
  props: {
    issuerInfo: {},
  },
  components: {
    castPage: StoreDetailCastPage,
    bottom: StoreDetailBottomPage,
    trailers: StoreDetailTrailersPage,
    genres: DetailGenresPage,
    productioncompany: StoreDetailProductionCompanyPage,
    contactSellerPop: StoreContactSellerPop,
    bigImagePop: DetaiImagePop,
    midPage: StoreDetailMidPage,
  },
  data () {
    return {
      detailPosterUrl: '',//海报图
      categories: [],
      categorySelected: '',
      detailData: {},
      categoryTypeData: {},
      doubanScoreData: {},
      myDramaListScoreData: {},
      imdbScoreData: {},
      stillsImages: [],
      stillsShowImages: [],
      stillsShowImagesCount: 9,
      stillsShowImagesBigCount: 12,
      newStillsImages: [],
      trailersImages: [],
      trailersVideos: [],
      trailersMode: 1,

      screenersImages: [],
      screenersVideos: [],
      screenersMode: 1,

      activeIndex: 1,
      tipsList: [],
      currentItem: '', //菜单栏
      menuList: [],
      genresData: [],
      genresList: [],

      isCollected: false,
      languageType: "",
      pageTitleName: 'Interlib',
      pageDescription: '',
      pageKeywords: '',
      backgroundImageUrl: require('@/assets/store/store_top_menu_3_1.png'),
      selectedItemIndex: 0,
      showSubmittedTips: false,
      detailCollectionFlag: undefined,
      showType: 0,
      items: [],
      sliderIsOpen: false,
      sliderBackgroundImageUrl: require('@/assets/detail/slider_click.png'),
      mobileSliderBackgroundImageUrl: require('@/assets/detail/mobile_slider_click.png'),
      trailersListData: [],
      showUpScroll: false,
      showDownScroll: true,
      imageUrl:
        'https://ixmediahz-qa.s3.ap-southeast-1.amazonaws.com/data/1582306006861033477/2023-09-14/58ebff40-d465-4dc2-8275-64a8edba7555.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231222T032024Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=AKIA4MPA5ZJ5BRQ7WL7N%2F20231222%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=48d8162eb401caec657a427e4af82070ffbd3712111dcb3652d59166c8fd55b0',
      activeElement: undefined,

      showBigImagePop: false,
      trailersMenuBackgroundImageUrl: require('@/assets/detail/top_menu_bg.gif'),
      trailersMenuIsClick: false,
      deviceType: '0',//0 :笔记本 1：PC 2：手机
      loadTime: 0,
      startTiming: undefined,
      currentIssuerInfo: {}
    }
  },

  computed: {
    ...mapState('collectDialog', ['projectId']),
    ...mapState('auth', ['isLoggedIn']),

    shouldShowCategory () {
      return this.deviceType !== 2 && this.languageType === 'en';
    },
    categoryName () {
      return this.languageType === 'en' ? this.categoryTypeData.nameEn : this.categoryTypeData.name;
    },

    containerClass () {
      return {
        'bottom-container-open': this.sliderIsOpen,
        'bottom-container': !this.sliderIsOpen
      }
    },
    menuContainerStyle () {
      return {
        backgroundImage: `url("${this.backgroundImageUrl}")`
      };
    },
    trailersMenuContainerStyle () {
      return {
        backgroundImage: `url("${this.trailersMenuBackgroundImageUrl}")`
      };
    },
    sliderContainerStyle () {
      return {
        backgroundImage: `url("${this.sliderBackgroundImageUrl}")`
      };
    },
    mobileSliderContainerStyle () {
      return {
        backgroundImage: `url("${this.mobileSliderBackgroundImageUrl}")`
      };
    },
    borderStyle () {
      if (this.selectedItem !== null) {
        const itemHeight = 30; // 菜单项的高度
        const topOffset = this.selectedItem * itemHeight; // 计算距离顶部的偏移量

        return {
          top: `${topOffset}px`
        };
      }
      return {};
    }
  },

  created () {

    this.startTiming = performance.now();

    this.resetData()

    var mediaQueryPC = window.matchMedia('(min-width: 1920px)');
    if (mediaQueryPC.matches) {
      this.deviceType = 1
      this.stillsShowImagesCount = 9;
      this.stillsShowImagesBigCount = 15;
    }

    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    if (mediaQuery.matches) {
      this.stillsShowImagesCount = 6;
      this.stillsShowImagesBigCount = 9;
      this.deviceType = 2
    }

    this.reloadLocationLanguageData()
    const locationStoreClickInterlibContentId = localStorage.getItem('locationStoreClickInterlibContentId')

    console.log("StoreContainerPage created:", locationStoreClickInterlibContentId);
    if (!locationStoreClickInterlibContentId) return;
    this.fetchDetailData(locationStoreClickInterlibContentId);
    this.$store.watch(
      (state) => state.collectDialog.hasCollectSuccess,
      (newVal) => {
        if (newVal) {
          console.log('DetailPage-hasCollectSuccess:', newVal);
          console.log("详情页-收藏成功");
          this.$nextTick(() => {
            this.detailCollectionFlag = 1
          });
          // this.setCollectNormalDialog()
        }
      }
    );
  },
  mounted () {

    this.currentIssuerInfo = this.issuerInfo;
    console.log('this.currentIssuerInfo:', this.currentIssuerInfo);

    // 在子组件中监听父组件数据的变化
    this.$watch(() => this.$parent.selectedItemInterlibContentId, (newValue, oldValue) => {
      console.log('selectedItemInterlibContentId 发生变化：', newValue);
      this.$nextTick(() => {
        this.fetchDetailData(newValue);
      })
    });

    const container = document.querySelector('.trailers-container');
    container?.addEventListener('scroll', this.updateScrollButtonVisibility);
    setTimeout(() => {
      this.updateScrollButtonVisibility();
    }, 2000);

    setTimeout(() => {
      const yearString = new Date(this.detailData.premiereDate).getFullYear().toString();
      this.pageTitleName = this.detailData.projectNameEn ? (this.detailData.projectNameEn + (this.detailData.premiereDate ? ("(" + yearString + ")") : '')) : 'Interlib'
      console.log("详情页SEO-Title:", this.pageTitleName);

      this.pageDescription = this.detailData.projectNameEn + ';' + this.detailData.projectName + ';' + 'Plot Summary,stills,reels,trailers,Chinese '
        + this.categoryTypeData.nameEn;
      console.log("详情页SEO-Description:", this.pageDescription);

      this.pageKeywords = 'Chinese ' + this.categoryTypeData.nameEn + ',Synopsis and introduction,stills,reels,Trailers,casts,directors';
      console.log("详情页SEO-Keywords:", this.pageKeywords);
    }, 1000)

  },
  beforeDestroy () {
    const container = document.querySelector('.trailers-container');
    container?.removeEventListener('scroll', this.updateScrollButtonVisibility);
  },
  watch: {
    '$route.params': {
      handler (newParams, oldParams) {
        console.log('路由参数发生变化:', newParams.id);
        this.resetData()
        this.fetchDetailData(newParams.id);

        this.startTiming = performance.now();

      },
      deep: true
    }
  },
  methods: {
    ...mapActions('collectDialog', ['showCollectOfWorksDialog', 'setProjectIdDialog', 'hasCollectSuccess', 'setCollectNormalDialog']),
    ...mapActions('dialog', ['showLoginDialog']),

    //中英文切换
    navTranslate () {
      console.log("中英文切换:", this.languageType);
      if (this.languageType == 'en') {
        localStorage.setItem('locationLanguageType', 'zh')
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
      const location_href = window.location.href;
      if (location_href.includes('/cn')) {
        let modifiedHref = location_href.replace('/cn', '/' + 'en');
        window.location.href = modifiedHref;
      } else {
        let modifiedHref = location_href.replace('/en', '/' + 'cn');
        window.location.href = modifiedHref;
      }
    },

    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },

    resetData () {
      this.categories = []
      this.categorySelected = ''
      this.detailDat = {}
      this.categoryTypeData = {}
      this.doubanScoreData = {}
      this.myDramaListScoreData = {}
      this.imdbScoreData = {}
      this.stillsImages = []
      this.stillsShowImages = []
      this.stillsShowImagesCount = 9
      this.stillsShowImagesBigCount = 12
      this.newStillsImages = []
      this.trailersImages = []
      this.trailersVideos = []
      this.trailersMode = 1

      this.screenersImages = []
      this.screenersVideos = []
      this.screenersMode = 1

      this.activeIndex = 1
      this.tipsList = []
      this.currentItem = ''
      this.menuList = []
      this.genresData = []
      this.genresList = []
      this.productionCompanyData = []
      this.isCollected = false

      this.detailPosterUrl = ''
      this.detailData = {}
      this.pageTitleName = 'Interlib'

      this.pageDescription = ''
      this.pageKeywords = ''
      this.pageTitleName = 'Interlib'
      this.backgroundImageUrl = require('@/assets/store/store_top_menu_3_1.png'),
        this.selectedItemIndex = 0
      this.showSubmittedTips = false

      this.detailCollectionFlag = undefined
      this.showType = 0
      this.items = []
      this.sliderIsOpen = false

      this.sliderBackgroundImageUrl = require('@/assets/detail/slider_click.png')
      this.trailersListData = []
      this.showUpScroll = false
      this.showDownScroll = true
      this.imageUrl =
        'https://ixmediahz-qa.s3.ap-southeast-1.amazonaws.com/data/1582306006861033477/2023-09-14/58ebff40-d465-4dc2-8275-64a8edba7555.jpg?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Date=20231222T032024Z&X-Amz-SignedHeaders=host&X-Amz-Expires=43200&X-Amz-Credential=AKIA4MPA5ZJ5BRQ7WL7N%2F20231222%2Fap-southeast-1%2Fs3%2Faws4_request&X-Amz-Signature=48d8162eb401caec657a427e4af82070ffbd3712111dcb3652d59166c8fd55b0'
      this.activeElement = undefined
      this.showBigImagePop = false
      this.trailersMenuBackgroundImageUrl = require('@/assets/detail/top_menu_bg.gif')
      this.trailersMenuIsClick = false
    },
    goBack () {
      this.$router.go(-1);
    },
    handleScrollToTop () {
      this.scrollToTop()
    },
    scrollToTop () {
      const container = document.querySelector(".store-container");
      console.log("container:", container);

      if (container) {
        container.scrollTo({
          top: 0,
          behavior: "smooth"
        });
      }
    },

    //获取详情数据
    fetchDetailData (id) {
      this.scrollToTop();

      this.showType = 0;
      this.sliderIsOpen = false;

      detailInfoApi(id, this.languageType)
        .then(async (res) => {
          if (res.data.code === 200) {
            this.detailData = res.data.data

            this.detailCollectionFlag = this.detailData.collectionFlag;

            const yearString = new Date(this.detailData.premiereDate).getFullYear().toString();
            this.pageTitleName = this.detailData.projectNameEn ? (this.detailData.projectNameEn + (this.detailData.premiereDate ? ("(" + yearString + ")") : '')) : 'Interlib'
            console.log("详情页SEO-Title:", this.pageTitleName);

            this.pageDescription = this.detailData.projectNameEn + ';' + this.detailData.projectName + ';' + 'Plot Summary,stills,reels,trailers,Chinese '
              + this.categoryTypeData.nameEn;
            console.log("详情页SEO-Description:", this.pageDescription);

            this.pageKeywords = 'Chinese ' + this.categoryTypeData.nameEn + ',Synopsis and introduction,stills,reels,Trailers,casts,directors';
            console.log("详情页SEO-Keywords:", this.pageKeywords);

            const categoriesName = [];
            categoriesName.push(this.$t('detail_contact'))

            if (this.detailData.stills && this.detailData.stills.length > 0) {
              categoriesName.push(this.$t('detail_stills'))
            }

            if (this.detailData.trailers && this.detailData.trailers.length > 0) {
              categoriesName.push(this.$t('detail_trailers'))
            }

            if (this.detailData.screeners && this.detailData.screeners.length > 0) {
              categoriesName.push(this.$t('detail_screeners'))
            }

            this.categories = categoriesName
            this.items = categoriesName.slice(1);
            if (categoriesName.length > 1) {
              this.categorySelected = categoriesName[1];
            }

            if (this.categorySelected === this.$t('detail_trailers')) {
              this.handleDetailTrailersData(this.detailData.trailers)
            } else if (this.categorySelected === this.$t('detail_screeners')) {
              this.handleDetailTrailersData(this.detailData.screeners)
            }

            this.fetchCategoryTypeData(this.detailData.type)
            const genresWithEnName = [];
            for (const genre of this.detailData.genres) {
              if (genre.showFlag === 1) {
                genresWithEnName.push(this.languageType == 'en' ? genre.nameEn : genre.name);
              }
            }
            this.tipsList = genresWithEnName

            const platform = this.languageType === 'en' ? 'douban' : '豆瓣';
            let doubanScore = this.detailData.score.find(item => item.platform === platform);

            let myDramaListScore = this.detailData.score.find(item => item.platform === "MyDramaList");

            this.doubanScoreData = doubanScore
            console.log(" 豆瓣：", doubanScore);
            this.myDramaListScoreData = myDramaListScore

            let imdbScore = this.detailData.score.find(item => item.platform === "IMDB");
            this.imdbScoreData = imdbScore

            // 获取海报图url
            if (this.detailData.poster && this.detailData.poster.length > 0) {
              const firstPoster = this.detailData.poster[0];
              try {
                const imageRes = await getPresignedUrlApi(firstPoster);
                if (imageRes.data.code === 200) {
                  this.detailPosterUrl = imageRes.data.data.url;
                }
              } catch (error) {
                // 错误处理
              }
            }

            if (this.detailData.stills) {
              //获取stills图片URL
              const stillsResultData = await Promise.all(
                this.detailData.stills.map(async (element) => {
                  if (element) {
                    try {
                      const imageRes = await getPresignedUrlApi(element);
                      if (imageRes.data.code === 200) {
                        const replacedUrl = imageRes.data.data.url.replace(/ /g, '%20');
                        return replacedUrl;
                      }
                    } catch (error) {
                      // 错误处理
                    }
                  }

                })
              );

              if (stillsResultData.filter(Boolean).length > 0) {
                this.stillsImages = stillsResultData.filter(Boolean);
                if (stillsResultData.filter(Boolean).length > this.stillsShowImagesCount) {
                  this.stillsShowImages = this.stillsImages.slice(0, this.stillsShowImagesCount);
                } else {
                  this.stillsShowImages = this.stillsImages;
                }
                console.log("stillsShowImagesCount:", this.stillsShowImagesCount);
                console.log("获取stills图片URL:", this.stillsImages);
                console.log("获取stillsShowImages图片URL:", this.stillsShowImages);
              }
            }

            //获取菜单类目
            const menuListNames = [];
            // if (this.detailData.genres && this.detailData.genres.length > 0) {
            //   menuListNames.push(this.$t('detail_genres'))
            // }
            if (this.detailData.directors && this.detailData.directors.length > 0) {
              menuListNames.push(this.$t('detail_director'))
            }
            if (this.detailData.screenwriters && this.detailData.screenwriters.length > 0) {
              menuListNames.push(this.$t("detail_script_writer"))
            }
            if (this.detailData.casts && this.detailData.casts.length > 0) {
              menuListNames.push(this.$t('detail_cast'))
            }
            if (this.detailData.productionCompanies && this.detailData.productionCompanies.length > 0) {
              menuListNames.push(this.$t('detail_production_ompany'))
            }
            this.menuList = menuListNames;

            console.log("this.menuList:", this.menuList);

            // 题材：
            this.genresData = this.detailData.genres;
            console.log("题材:", this.genresData);

            setTimeout(() => {
              const endTiming = performance.now();
              const loadTime = endTiming - this.startTiming;
              console.log('Detail页面加载时长：', loadTime.toFixed(2) * 1.3);
              this.loadTime = parseInt(loadTime) * 1.3;
              // this.recordUserActionApiHomePageData()
            }, 0);
          }
        })
        .catch((error) => {
        })
    },

    //获取类型数据
    fetchCategoryTypeData (id) {
      categoryApi(id)
        .then((res) => {
          if (res.data.code === 200) {
            this.categoryTypeData = res.data.data
          }
        })
        .catch((error) => {
        })
    },

    doubanClick () {
      if (this.doubanScoreData.link) {
        window.open(this.doubanScoreData.link, "_blank");
      }
    },

    mdlClick () {
      if (this.myDramaListScoreData.link) {
        window.open(this.myDramaListScoreData.link, "_blank");
      }
    },

    imdbClick () {
      if (this.imdbScoreData.link) {
        window.open(this.imdbScoreData.link, "_blank");
      }
    },

    handleCategoryClick (category, index) {
      //处理联系卖家点击事件
      if (category == this.$t('detail_contact')) {
        this.topTypeContectClick()
      } else { //处理 Trailers、Stills 点击事件
        this.categorySelected = category
        this.selectedItemIndex = index - 1
        console.log("this.categorySelected:", this.categorySelected);
        this.showType = 1
        if (this.stillsImages.length > this.stillsShowImagesBigCount) {
          this.stillsShowImages = this.stillsImages.slice(0, this.stillsShowImagesBigCount);
        } else {
          this.stillsShowImages = this.stillsImages;
        }

        console.log("handleCategoryClick-this.stillsShowImagesBigCount:", this.stillsShowImagesBigCount);

        console.log("handleCategoryClick:", this.stillsShowImages);

        this.backgroundImageUrl = require(`@/assets/store/store_top_menu_${this.items.length}_${index}.png`)

        if (this.deviceType === 2) {
          this.backgroundImageUrl = require(`@/assets/store/store_mobile_top_menu_${this.items.length}_${index}.png`)
        }

        this.sliderIsOpen = false

        if (this.categorySelected === this.$t('detail_trailers')) {
          this.handleDetailTrailersData(this.detailData.trailers)
          this.sliderIsOpen = true

        } else if (this.categorySelected === this.$t('detail_screeners')) {
          this.handleDetailTrailersData(this.detailData.screeners)
          this.sliderIsOpen = true

        }
      }
    },
    //联系卖家确定
    makeSureClick () {
      this.$refs.contactSellerPop.close()
      this.showSubmittedTips = true;
      setTimeout(() => {
        this.showSubmittedTips = false;
      }, 2000);
    },
    //查看大图点击
    stillsShowImagesClick (image) {
      let newImages = this.stillsImages.map(item => {
        if (item === image) {
          return { image: item, isSelect: true };
        } else {
          return { image: item, isSelect: false };
        }
      });
      // 更新数据
      this.newStillsImages = newImages;
      this.$refs.bigImagePop.open()
    },
    topCollectClick (item, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      if (this.isLoggedIn) {
        this.showCollectOfWorksDialog()
        this.setProjectIdDialog(item.id)
        console.log("projectId:", this.projectId);
      } else {
        this.showLoginDialog()
      }
    },
    topTypeBackClick () {
      this.showType = 0;
      this.sliderIsOpen = false;
      if (this.stillsImages.length > this.stillsShowImagesCount) {
        this.stillsShowImages = this.stillsImages.slice(0, this.stillsShowImagesCount);
      } else {
        this.stillsShowImages = this.stillsImages;
      }
    },
    topTypeContectClick () {
      console.log('联系我们');
      this.$refs.contactSellerPop.open()
    },
    selectItem (item, index) {
      this.$nextTick(() => {
        this.selectedItemIndex = index;
        this.categorySelected = item;
        this.backgroundImageUrl = require(`@/assets/store/store_top_menu_${this.items.length}_${index + 1}.png`)
        if (this.deviceType === 2) {
          this.backgroundImageUrl = require(`@/assets/store/store_mobile_top_menu_${this.items.length}_${index + 1}.png`)
        }
        this.sliderIsOpen = false

        if (this.categorySelected === this.$t('detail_trailers')) {
          this.handleDetailTrailersData(this.detailData.trailers)
          this.sliderIsOpen = true

        } else if (this.categorySelected === this.$t('detail_screeners')) {
          this.handleDetailTrailersData(this.detailData.screeners)
          this.sliderIsOpen = true

        }
      });
    },
    sliderClick () {
      console.log("抽屉点击");
      this.sliderIsOpen = !this.sliderIsOpen;
    },
    trailersSlideUp () {
      const container = document.querySelector('.trailers-container')
      const itemHeight = container.offsetHeight
      const scrollAmount = itemHeight

      container.scrollBy({
        top: -scrollAmount,
        behavior: 'smooth',
      })
      setTimeout(this.updateScrollButtonVisibility, 500)
    },

    trailersSlideDown () {
      const container = document.querySelector('.trailers-container')
      const itemHeight = container.offsetHeight
      const scrollAmount = itemHeight

      container.scrollBy({
        top: scrollAmount,
        behavior: 'smooth',
      })
      setTimeout(this.updateScrollButtonVisibility, 500)
    },
    trailerClick (item) {
      this.trailersMenuIsClick = true;
      this.activeElement = item;
      this.trailersListData.forEach((element) => {
        element.isSelect = element === item;
      });
      item.rotateDotActive = true;
      // 更新数据模型
      setTimeout(() => {
        item.rotateDotActive = false;
      }, 1500); // 延迟1.5秒后关闭动画
      this.$forceUpdate();

      setTimeout(() => {
        this.trailersMenuIsClick = false;
      }, 1800);
    },

    updateScrollButtonVisibility () {
      const container = document.querySelector('.trailers-container')
      const maxScrollTop = container?.scrollHeight - container?.clientHeight
      this.showUpScroll = container?.scrollTop > 0
      this.showDownScroll = container?.scrollTop + 30 < maxScrollTop
    },

    async handleDetailTrailersData (trailers) {
      if (trailers) {
        const newTrailers = await Promise.all(
          trailers.map(async (element) => {
            if (element.mode == 1) {
              if (element.coverId) {
                try {
                  const imageRes = await getPresignedUrlApi(element.coverId);
                  if (imageRes.data.code === 200) {
                    element.imgUrl = imageRes.data.data.url || "";
                    console.log("图片URL赋值成功：", imageRes.data.data.url);
                  }
                } catch (error) {
                  console.error("获取图片URL出错：", error);
                }
              }
              if (element.videoId) {
                try {
                  const videoRes = await getPresignedUrlApi(element.videoId);
                  if (videoRes.data.code === 200) {
                    element.videoUrl = videoRes.data.data.url || "";
                    console.log("获取视频URL赋值成功：", videoRes.data.data.url);

                  }
                } catch (error) {
                  console.error("获取视频URL出错：", error);
                }
              }
              return element; // 将处理后的元素存入新数组
            } else if (element.mode == 2) {
              return element;
            }
          })
        );
        console.log("数据处理后trailers：", newTrailers);
        newTrailers.forEach((element, index) => {
          element.isSelect = index === 0;
        });
        const selectedTrailer = newTrailers.find(item => item.isSelect === true);
        this.activeElement = selectedTrailer
        this.trailersListData = newTrailers;

        this.trailersSlideUp()
      }
    },
    setVideoCurrentTime (event) {
      const videoElement = event.target;
      videoElement.currentTime = 5;
    },
    //记录数据
    async recordUserActionApiHomePageData () {
      try {
        const res = recordUserActionApi("Detail", this.loadTime);
        this.loadTime = 0
      } catch (error) {
        // 错误处理
      }
    }
  }
}
</script>


<style scoped>
/* 笔记本 */
body {
  margin: 0;
}

.detail-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: scroll;
}

/* 导航栏---start */

.nav {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  overflow: hidden;
}

.nav-left {
  margin-top: 20px;
  margin-left: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  justify-content: left;
}

.back-icon img {
  width: 10px;
  height: auto;
  margin-right: 60px;
  cursor: pointer;
}

.nav-right {
  margin-top: 20px;
  margin-right: 35px;
  color: #fff;
  height: 100%;
}
.nav-search {
  width: 90px;
  height: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.nav-search img {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  cursor: pointer;
}
/* 导航栏---end */

/* main---start */

.main-container {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 35px;
  height: 420px;
}
.main-container-big {
  display: flex;
  flex-direction: column;
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 35px;
  height: 550px;
}

.main-top-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.bottom-container {
  width: 194px;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  transition: width 0.3s ease;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.bottom-container-open {
  width: 290px;
  height: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  position: relative;
  animation: slideIn 0.3s ease;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.mine-container {
  left: 0;
  top: 0;
  position: absolute;
  width: 160px;
  height: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 100;
}

.info-container {
  width: 100%;
  height: 70%;
  display: flex;
  flex-direction: column;
}

.info-content-top {
  width: 100%;
  height: 213px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 8px;
  position: relative;
}

.top-collect-type-1 {
  right: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  border-radius: 8px;
  position: absolute;
  overflow: hidden;
  cursor: pointer;
}

.top-collect-type-1 img {
  width: 100%;
  height: 100%;
}

.top-type-back {
  left: 15px;
  top: 15px;
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
}

.top-type-back img {
  width: 100%;
  height: 100%;
}

.top-type-contact {
  right: 15px;
  top: 140px;
  width: 30px;
  height: 30px;
  position: absolute;
  cursor: pointer;
}

.top-type-contact img {
  width: 100%;
  height: 100%;
}

.info-content-bottom {
  width: 100%;
  height: 55%;
  display: flex;
  flex-direction: column;
}

.info-content-bottom-title {
  margin-top: 20px;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.info-content-bottom-type {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
}
.info-content-bottom-time {
  margin-top: 3px;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
}

.info-content-bottom-score {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.info-content-bottom-score-dou img {
  width: 15px;
}

.info-content-bottom-score-dou-data {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Medium;
  margin-bottom: 5px;
  margin-left: 5px;
  margin-right: 20px;
}

.info-content-bottom-score-mdl img {
  height: 15px;
  margin-left: 0px;
}

.info-content-bottom-score-mdl-data {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Medium;
  margin-bottom: 5px;
  margin-left: 5px;
}

.info-content-bottom-score-imdb-container {
  /* height: 40px; */
  display: flex;
  flex-direction: row;
  margin-top: 10px;
}

.info-content-bottom-score-imdb-container-img {
  width: 25px;
  margin-left: 10px;
}

.info-content-bottom-score-imdb-container-data {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Medium;
  margin-bottom: 7px;
  margin-left: 5px;
}

.top-main-mid-line {
  width: 100%;
  height: 0;
  border-top: 1px dashed rgba(255, 255, 255, 0.4); /* 点状线 */
  margin-top: 20px;
}

.menu-container {
  width: 100%;
  height: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.selected {
  font-weight: bold;
  color: #fff;
  text-align: left;
  cursor: pointer;
  margin: 0px 15px 0px 20px;
  height: 41px;
  white-space: normal;
  overflow-wrap: break-word;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unselected {
  font-weight: normal;
  color: #fff;
  text-align: left;
  cursor: pointer;
  margin: 0px 15px 0px 20px;
  height: 41px;
  white-space: normal;
  overflow-wrap: break-word;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.unselected img {
  width: 8px;
  display: block;
}

.selected img {
  width: 8px;
  display: none;
}

.slider-menu-container {
  display: flex;
  flex-flow: 1;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.15);
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

.bottom-container-right {
  right: 0;
  top: 0;
  position: absolute;
  width: 80px;
  height: 100%;
  margin-right: 30px;
}

.slider-container {
  width: 26px;
  height: 100%;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.slider-container img {
  width: 8px;
  cursor: pointer;
}

@keyframes slideIn {
  from {
    width: 194px;
  }

  to {
    width: 290px;
  }
}

@keyframes slideOut {
  from {
    width: 290px;
  }

  to {
    width: 194px;
  }
}

.discover {
  width: 100%;
  height: 100%;
}

.trailers {
  width: 90px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: -10px;
}

.trailers-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow-x: hidden;
  overflow-y: scroll;
}

.trailers-container-item {
  flex-shrink: 0;
  width: 100%;
  margin-bottom: 5px;
  margin-top: 5px;
  /* padding: 0px; */
  margin-left: -5px;
  margin-right: -5px;
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.5s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 90px;
}

.trailers-container-item-img {
  width: 65px;
  height: 45px;
  position: absolute;
  display: block;
  border: none;
  object-fit: cover;
  border-radius: 8px;
  margin-top: 5px;
}

.history-container-item-img-overlay {
  content: "";
  position: absolute;
  width: 60px;
  height: 40px;
  border-radius: 10px;
  margin-top: 6px;
  margin-left: 14px;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.trailers-container-item:hover .history-container-item-img-overlay {
  opacity: 0;
}

.trailers-container-item-img-top {
  position: absolute;
  left: 6.5px;
  top: 0.5px;
  height: 51px;
  width: 72px;
  border: 1.5px solid #fff;
  border-radius: 9px;
}

.trailers-container-item-img-top-gif {
  position: absolute;
  left: -24.5px;
  top: -33.5px;
  height: 150px;
  width: 137px;
}

.history-container-item-img-top-dot {
  position: absolute;
  width: 20px;
  height: 20px;
  top: -3px;
  right: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.history-container-item-img-top-dot-item {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  animation: scale 1s linear 2;
  box-shadow: 0 0 6px #fff;
  background-color: #fff;
}

@keyframes scale {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
.item-pause {
  position: absolute;
  top: 15px;
  left: 34px;
  width: 20px;
  height: 20px;
}
.item-pause img {
  width: 100%;
  height: 100%;
}

@keyframes rotateDot {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.trailers-container-item-detail {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 30px;
}

.trailers-container-item-detail-container {
  display: flex;
  flex-direction: column;
  padding: 0px 0px 0px 0px;
}

.trailers-container-item-detail-container-top {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.trailers-up-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 5px;
  cursor: pointer;
}

.trailers-down-arrow {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 5px;
  cursor: pointer;
}

.trailers-up-scroll {
  cursor: pointer;
  height: 10px;
  margin: 5px;
  margin-left: -3px;
}

.trailers-down-scroll {
  cursor: pointer;
  height: 10px;
  margin: 10px;
  margin-left: -3px;
}

.main-info-left-container {
  width: 46%;
  display: flex;
  flex-direction: column;
  background-color: rgba(255, 255, 255, 0.15);
  border-radius: 8px;
  padding: 35px 0px 0px 35px;
  margin-right: 35px;
}

.main-info-left-pic-container {
  height: 50%;
  width: 100%;
  display: flex;
  flex-direction: row;
}

.main-info-left-pic-left-container {
  width: 140px;
  height: 96%;
  position: relative;
}

.main-info-left-pic-left-container img {
  width: 100%;
  height: 187px;
  object-fit: cover;
  border-radius: 8px;
  box-shadow: 10px 13px 18px rgba(0, 0, 0, 0.3);
}

.top-collect {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 10px;
  z-index: 100;
}

.top-collect img {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

.main-info-left-pic-right-container {
  width: 65%;
  height: 96%;
  display: flex;
  flex-direction: column;
  margin-left: 3%;
  justify-content: space-between;
}

.main-info-left-pic-right-top-container {
  display: flex;
  flex-direction: column;
}

.main-info-left-pic-right-container-title {
  font-size: 24px;
  font-family: Source Han Sans SC-Medium;
  color: #fff;
  text-overflow: -o-ellipsis-lastline;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.main-info-left-pic-right-container-type {
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  color: #ff036a;
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.main-info-left-pic-right-container-mid-line {
  margin: 0 1%;
}

.main-info-left-pic-right-container-tabs {
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.main-info-left-pic-right-container-tabs-item-selected {
  border-radius: 20px;
  padding: 1% 4%;
  margin-right: 3%;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  cursor: pointer;
}

.main-info-left-pic-right-container-tabs-item-text {
  display: flex;
  flex-direction: row;
}

.main-info-left-pic-right-container-tabs-item-text img {
  width: 8px;
  margin-right: 6px;
}

.main-info-left-pic-right-container-tabs-item-unselected {
  border-radius: 20px;
  padding: 1% 4%;
  margin-right: 3%;
  border: 1px #fff solid;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  background-image: none;
  cursor: pointer;
}

.main-info-left-pic-right-bottom-container {
  display: flex;
  flex-direction: column;
}

.main-info-left-pic-right-bottom-container-formats {
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
  color: #fff;
  margin-top: 5px;
}

.main-info-left-pic-right-bottom-container-tips {
  display: flex;
  flex-direction: row;
  /* margin-top: 5px; */
  color: #a8a8a8;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
}

.main-info-left-pic-right-bottom-container-tips-span {
  color: #a8a8a8;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
}
.main-info-left-pic-right-bottom-container-tips-dian {
  margin-left: 5px;
  margin-right: 5px;
}

.main-info-left-detail-container {
  margin-top: 10px;
  height: 110px;
  width: 92%;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  line-height: 1.8;
  overflow: auto;
  padding-right: 15px;
}

.main-info-left-deta-score-container {
  height: 20%;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 15px;
}

.main-info-left-deta-score-left-container {
  display: flex;
  flex-direction: column;
  width: 45%;
  height: 100%;
}

.main-info-left-deta-score-left-container-tips {
  width: 3px;
  height: 10px;
  border-radius: 3px;
  background-color: #ff036a;
  margin-right: 3%;
  flex-shrink: 0;
}

.main-info-left-deta-score-left-date-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-info-container-item-date-title {
  color: #a8a8a8;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  margin-right: 5%;
}

.main-info-container-item-date-detail {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
}

.main-info-left-deta-score-left-score-container {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.main-info-container-item-score-title {
  color: #a8a8a8;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  margin-right: 5%;
}

.main-info-container-item-date-detail {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
}

.main-info-container-item-detail-source {
  display: flex;
  flex-direction: row;
  margin-top: 4%;
}

.main-info-container-item-detail-source-dou img {
  width: 17px;
  margin-right: 10px;
  cursor: pointer;
}

.main-info-container-item-detail-source-dou-data {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  margin-top: 0px;
}

.main-info-container-item-detail-source-dou-mdl-mid {
  width: 20px;
}

.main-info-container-item-detail-source-mdl img {
  width: 28px;
  margin-right: 10px;
  cursor: pointer;
}

.main-info-container-item-detail-source-mdl-data {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
}

.main-info-container-item-detail-source-imdb img {
  width: 34px;
  margin-right: 10px;
  cursor: pointer;
  margin-top: -1.5px;
}

.main-info-left-deta-score-right-container {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
}

.main-info-left-deta-score-right-platform-container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.main-info-left-deta-score-left-platform-container-tips {
  width: 3px;
  height: 10px;
  border-radius: 3px;
  background-color: #ff036a;
  margin-right: 3%;
  flex-shrink: 0; /* 防止被压缩 */
  margin-top: 4px;
}

.main-info-container-item-platform-title {
  color: #a8a8a8;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  margin-right: 5%;
  white-space: nowrap;
  margin-top: 0px;
}

.main-info-container-item-platform-detail {
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  margin-top: 0px;
}

.main-info-left-deta-score-right-tk-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 2.5%;
}

.main-info-container-item-detail-tk {
  width: 50%;
  color: #fff;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
}

.main-info-right-container-type-0 {
  width: 54%;
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* 创建4列 */
  grid-template-rows: repeat(3, 1fr); /* 创建3行 */
  grid-gap: 10px; /* 格子之间的间隔 */
  grid-gap: 15px;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
}

.main-info-right-container-type-1 {
  width: calc(100% - 204px);
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 创建4列 */
  grid-template-rows: repeat(3, 1fr); /* 创建3行 */
  grid-gap: 10px; /* 格子之间的间隔 */
  grid-gap: 15px;
  height: auto;
  border-radius: 8px;
  overflow: hidden;
  margin-left: 10px;
  height: 550px;
}

.main-info-right-container-trailers-container-type-0 {
  width: 54%;
  height: 100%;
}

.main-info-right-container-trailers-container-type-1 {
  width: calc(100% - 300px);
  height: 550px;
  margin-left: 10px;
}

.main-info-right-container-trailers-container-type-1-close {
  width: calc(100% - 204px);
  height: 550px;
  margin-left: 10px;
}
.main-info-right-container-grid-item {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
}

.main-info-right-container-grid-item-top-special-div {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.main-info-right-container-grid-item-top-special-div span {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  padding: 3% 15%;
  border: 1px solid #fff;
  border-radius: 20px;
}

.main-mid-line {
  width: 100%;
  height: 0;
  /* border-top: 1px dashed rgba(255, 255, 255, 0.4);  */
  margin-top: 0px;
}

.main-mid-container {
  margin-top: 35px;
  margin-left: 20px;
  margin-right: 35px;
  height: 500px;
  display: flex;
  flex-direction: row;
}

.main-bottom-container {
  margin-top: 3%;
  margin-left: 20px;
  margin-right: 35px;
}

.submitted-tips {
  position: absolute;
  top: 50px;
  left: 45%;
  background-color: #fff;
  color: #2b2d31;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  padding: 5px 20px;
  border-radius: 15px;
  z-index: 100;
}

@keyframes slideDown {
  from {
    transform: translateY(-150%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-150%);
  }
}

.slide-down-enter-active,
.slide-up-leave-active {
  animation-duration: 0.5s;
  animation-fill-mode: both;
}

.submitted-tips,
.slide-up-leave-to {
  animation-name: slideUp;
}

.submitted-tips,
.slide-down-leave-to {
  animation-name: slideDown;
}

/* main---end */
::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 1.5px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.15) !important;
  border-radius: 1.5px;
}
</style>

<style>
.el-popover {
  background: rgba(255, 255, 255, 0.35) !important;
  border: 1px solid transparent !important;
  border-radius: 16px !important;
  color: #fff;
  padding: 10px !important;
}
</style>

<style scoped>
/* PC设备 */
@media only screen and (min-width: 1920px) {
  body {
    margin: 0;
  }

  .detail-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
  }

  /* 导航栏---start */

  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    overflow: hidden;
  }

  .nav-left {
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: left;
  }

  .back-icon img {
    width: 10px;
    height: auto;
    margin-right: 60px;
    cursor: pointer;
  }

  .nav-right {
    margin-top: 20px;
    margin-right: 30px;
    color: #fff;
    height: 100%;
  }
  .nav-search {
    width: 90px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .nav-search img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
  }
  /* 导航栏---end */

  /* main---start */

  .main-container {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 35px;
    height: 600px;
  }
  .main-container-big {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-left: 20px;
    margin-right: 35px;
    height: 650px;
  }

  .main-top-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .bottom-container {
    width: 194px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bottom-container-open {
    width: 290px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    animation: slideIn 0.3s ease;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mine-container {
    left: 0;
    top: 0;
    position: absolute;
    width: 160px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 100;
  }

  .info-container {
    width: 100%;
    height: 70%;
    display: flex;
    flex-direction: column;
  }

  .info-content-top {
    width: 100%;
    height: 213px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    position: relative;
  }

  .top-collect-type-1 {
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
  }

  .top-collect-type-1 img {
    width: 100%;
    height: 100%;
  }

  .top-type-back {
    left: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
  }

  .top-type-back img {
    width: 100%;
    height: 100%;
  }

  .top-type-contact {
    right: 15px;
    top: 145px;
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
  }

  .top-type-contact img {
    width: 100%;
    height: 100%;
  }

  .info-content-bottom {
    width: 100%;
    height: 64%;
    display: flex;
    flex-direction: column;
  }

  .info-content-bottom-title {
    margin-top: 20px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }

  .info-content-bottom-type {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }
  .info-content-bottom-time {
    margin-top: 3px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .info-content-bottom-score {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .info-content-bottom-score-dou img {
    width: 20px;
  }

  .info-content-bottom-score-dou-data {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-bottom: 5px;
    margin-right: 20px;
  }

  .info-content-bottom-score-mdl img {
    height: 20px;
    margin-left: 0px;
  }

  .info-content-bottom-score-mdl-data {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .info-content-bottom-score-imdb-container {
    /* height: 40px; */
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .info-content-bottom-score-imdb-container-img {
    width: 35px;
    margin-left: 10px;
  }

  .info-content-bottom-score-imdb-container-data {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .top-main-mid-line {
    width: 100%;
    height: 0;
    border-top: 1px dashed rgba(255, 255, 255, 0.4); /* 点状线 */
    margin-top: 60px;
  }

  .menu-container {
    width: 100%;
    height: 30%;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .selected {
    font-weight: bold;
    color: #fff;
    text-align: left;
    cursor: pointer;
    margin: 0px 15px 0px 20px;
    height: 48px;
    white-space: normal;
    overflow-wrap: break-word;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .unselected {
    font-weight: normal;
    color: #fff;
    text-align: left;
    cursor: pointer;
    margin: 0px 15px 0px 20px;
    height: 48px;
    white-space: normal;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .unselected img {
    width: 8px;
    display: block;
  }

  .selected img {
    width: 8px;
    display: none;
  }

  .slider-menu-container {
    display: flex;
    flex-flow: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .bottom-container-right {
    right: 0;
    top: 0;
    position: absolute;
    width: 80px;
    height: 100%;
    margin-right: 30px;
  }

  .slider-container {
    width: 26px;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .slider-container img {
    width: 8px;
    cursor: pointer;
  }

  @keyframes slideIn {
    from {
      width: 194px;
    }

    to {
      width: 290px;
    }
  }

  @keyframes slideOut {
    from {
      width: 290px;
    }

    to {
      width: 194px;
    }
  }

  .discover {
    width: 100%;
    height: 100%;
  }

  .trailers {
    width: 90px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -10px;
  }

  .trailers-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .trailers-container-item {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 0px;
    /* padding: 0px; */
    margin-left: -5px;
    margin-right: -5px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
  }

  .trailers-container-item-img {
    width: 65px;
    height: 45px;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 5px;
  }

  .history-container-item-img-overlay {
    content: "";
    position: absolute;
    width: 60px;
    height: 40px;
    border-radius: 10px;
    margin-top: 6px;
    margin-left: 14px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .trailers-container-item:hover .history-container-item-img-overlay {
    opacity: 0;
  }

  .trailers-container-item-img-top {
    position: absolute;
    left: 6.5px;
    top: 0.5px;
    height: 51px;
    width: 72px;
    border: 1.5px solid #fff;
    border-radius: 9px;
  }

  .trailers-container-item-img-top-gif {
    position: absolute;
    left: -24.5px;
    top: -33.5px;
    height: 150px;
    width: 137px;
  }

  .history-container-item-img-top-dot {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -3px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .history-container-item-img-top-dot-item {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: scale 1s linear 2;
    box-shadow: 0 0 6px #fff;
    background-color: #fff;
  }

  @keyframes scale {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  .item-pause {
    position: absolute;
    top: 15px;
    left: 34px;
    width: 20px;
    height: 20px;
  }
  .item-pause img {
    width: 100%;
    height: 100%;
  }

  @keyframes rotateDot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .trailers-container-item-detail {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30px;
  }

  .trailers-container-item-detail-container {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
  }

  .trailers-container-item-detail-container-top {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .trailers-up-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .trailers-down-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    cursor: pointer;
  }

  .trailers-up-scroll {
    cursor: pointer;
    height: 10px;
    margin: 5px;
    margin-left: -5px;
  }

  .trailers-down-scroll {
    cursor: pointer;
    height: 10px;
    margin: 10px;
    margin-left: -3px;
  }

  .main-info-left-container {
    width: 46%;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    padding: 35px 0px 0px 35px;
    margin-right: 35px;
  }

  .main-info-left-pic-container {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .main-info-left-pic-left-container {
    width: 180px;
    height: 96%;
    position: relative;
  }

  .main-info-left-pic-left-container img {
    width: 100%;
    height: 240px;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 10px 13px 28px rgba(0, 0, 0, 0.2);
  }

  .top-collect {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  .top-collect img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  .main-info-left-pic-right-container {
    width: 65%;
    height: 96%;
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    justify-content: space-between;
  }

  .main-info-left-pic-right-top-container {
    display: flex;
    flex-direction: column;
  }

  .main-info-left-pic-right-container-title {
    font-size: 24px;
    font-family: Source Han Sans SC-Medium;
    color: #fff;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .main-info-left-pic-right-container-type {
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    color: #ff036a;
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }

  .main-info-left-pic-right-container-mid-line {
    margin: 0 1%;
  }

  .main-info-left-pic-right-container-tabs {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }

  .main-info-left-pic-right-container-tabs-item-selected {
    border-radius: 20px;
    padding: 1% 4%;
    margin-right: 3%;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    cursor: pointer;
  }

  .main-info-left-pic-right-container-tabs-item-text {
    display: flex;
    flex-direction: row;
  }

  .main-info-left-pic-right-container-tabs-item-text img {
    width: 8px;
    margin-right: 6px;
  }

  .main-info-left-pic-right-container-tabs-item-unselected {
    border-radius: 20px;
    padding: 1% 4%;
    margin-right: 3%;
    border: 1px #fff solid;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    background-image: none;
    cursor: pointer;
  }

  .main-info-left-pic-right-bottom-container {
    display: flex;
    flex-direction: column;
  }

  .main-info-left-pic-right-bottom-container-formats {
    font-size: 16px;
    font-family: Source Han Sans SC-Normal;
    color: #fff;
    margin-top: 5px;
  }

  .main-info-left-pic-right-bottom-container-tips {
    display: flex;
    flex-direction: row;
    /* margin-top: 5px; */
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
  }

  .main-info-left-pic-right-bottom-container-tips-span {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
  }
  .main-info-left-pic-right-bottom-container-tips-dian {
    margin-left: 5px;
    margin-right: 5px;
  }

  .main-info-left-detail-container {
    margin-top: 10px;
    height: 250px;
    width: 92%;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.8;
    overflow: auto;
    padding-right: 15px;
  }

  .main-info-left-deta-score-container {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }

  .main-info-left-deta-score-left-container {
    display: flex;
    flex-direction: column;
    width: 45%;
    height: 100%;
  }

  .main-info-left-deta-score-left-container-tips {
    width: 3px;
    height: 10px;
    border-radius: 3px;
    background-color: #ff036a;
    margin-right: 3%;
  }

  .main-info-left-deta-score-left-date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .main-info-container-item-date-title {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5%;
  }

  .main-info-container-item-date-detail {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .main-info-left-deta-score-left-score-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .main-info-container-item-score-title {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5%;
  }

  .main-info-container-item-date-detail {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .main-info-container-item-detail-source {
    display: flex;
    flex-direction: row;
    margin-top: 11px;
  }

  .main-info-container-item-detail-source-dou img {
    width: 17px;
    margin-right: 10px;
    cursor: pointer;
  }

  .main-info-container-item-detail-source-dou-data {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .main-info-container-item-detail-source-dou-mdl-mid {
    width: 20px;
  }

  .main-info-container-item-detail-source-mdl img {
    width: 28px;
    margin-right: 10px;
    cursor: pointer;
  }

  .main-info-container-item-detail-source-mdl-data {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .main-info-container-item-detail-source-imdb img {
    width: 34px;
    margin-right: 10px;
    cursor: pointer;
    margin-top: -1.5px;
  }

  .main-info-left-deta-score-right-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
  }

  .main-info-left-deta-score-right-platform-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .main-info-left-deta-score-left-platform-container-tips {
    width: 3px;
    height: 10px;
    border-radius: 3px;
    background-color: #ff036a;
    margin-right: 3%;
    flex-shrink: 0; /* 防止被压缩 */
    margin-top: 4px;
  }

  .main-info-container-item-platform-title {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5%;
    white-space: nowrap;
    margin-top: 0px;
  }

  .main-info-container-item-platform-detail {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    margin-top: 0px;
  }

  .main-info-left-deta-score-right-tk-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2.5%;
  }

  .main-info-container-item-detail-tk {
    width: 50%;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .main-info-right-container-type-0 {
    width: 54%;
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 创建4列 */
    grid-template-rows: repeat(3, 1fr); /* 创建3行 */
    grid-gap: 10px; /* 格子之间的间隔 */
    grid-gap: 15px;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
  }

  .main-info-right-container-type-1 {
    width: calc(100% - 204px);
    display: grid;
    grid-template-columns: repeat(5, 1fr); /* 创建4列 */
    grid-template-rows: repeat(3, 1fr); /* 创建3行 */
    grid-gap: 10px; /* 格子之间的间隔 */
    grid-gap: 15px;
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    margin-left: 10px;
    height: 650px;
  }

  .main-info-right-container-trailers-container-type-0 {
    width: 54%;
    height: 100%;
  }

  .main-info-right-container-trailers-container-type-1 {
    width: calc(100% - 300px);
    height: 650px;
    margin-left: 10px;
  }

  .main-info-right-container-trailers-container-type-1-close {
    width: calc(100% - 204px);
    height: 650px;
    margin-left: 10px;
  }
  .main-info-right-container-grid-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .main-info-right-container-grid-item-top-special-div {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-info-right-container-grid-item-top-special-div span {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    padding: 3% 15%;
    border: 1px solid #fff;
    border-radius: 20px;
  }

  .main-mid-line {
    width: 100%;
    height: 0;
    /* border-top: 1px dashed rgba(255, 255, 255, 0.4);  */
    margin-top: 0px;
  }

  .main-mid-container {
    margin-top: 35px;
    margin-left: 20px;
    margin-right: 35px;
    height: 600px;
    display: flex;
    flex-direction: row;
  }

  .main-bottom-container {
    margin-top: 3%;
    margin-left: 20px;
    margin-right: 35px;
  }

  .submitted-tips {
    position: absolute;
    top: 50px;
    left: 45%;
    background-color: #fff;
    color: #2b2d31;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    padding: 5px 20px;
    border-radius: 15px;
    z-index: 100;
  }

  @keyframes slideDown {
    from {
      transform: translateY(-150%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-150%);
    }
  }

  .slide-down-enter-active,
  .slide-up-leave-active {
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  .submitted-tips,
  .slide-up-leave-to {
    animation-name: slideUp;
  }

  .submitted-tips,
  .slide-down-leave-to {
    animation-name: slideDown;
  }

  /* main---end */
  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 1.5px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.15) !important;
    border-radius: 1.5px;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  body {
    margin: 0;
  }

  .detail-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    overflow: scroll;
  }

  /* 导航栏---start */

  .nav {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    align-content: center;
    overflow: hidden;
  }

  .nav-left {
    margin-top: 20px;
    margin-left: 20px;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
    justify-content: left;
  }

  .back-icon img {
    width: 10px;
    height: auto;
    margin-right: 60px;
    cursor: pointer;
  }

  .nav-right {
    margin-top: 20px;
    margin-right: 20px;
    color: #fff;
    height: 100%;
  }
  .nav-search {
    width: 90px;
    height: 30px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .nav-search img {
    width: 25px;
    height: 25px;
    margin-right: 5px;
    cursor: pointer;
  }
  /* 导航栏---end */

  /* main---start */

  .main-container {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 10px;
    height: 330px;
  }
  .main-container-big {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    margin-left: 20px;
    margin-right: 20px;
    height: 300px;
  }

  .main-top-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .bottom-container {
    width: 160px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    transition: width 0.3s ease;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .bottom-container-open {
    width: 230px;
    height: 100%;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    position: relative;
    animation: slideIn 0.3s ease;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .mine-container {
    left: 0;
    top: 0;
    position: absolute;
    width: 124px;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 100;
  }

  .info-container {
    width: 100%;
    /* height: 70%; */
    display: flex;
    flex-direction: column;
  }

  .info-content-top {
    width: 100%;
    height: 165px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 8px;
    position: relative;
  }

  .top-collect-type-1 {
    right: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    border-radius: 8px;
    position: absolute;
    overflow: hidden;
    cursor: pointer;
  }

  .top-collect-type-1 img {
    width: 100%;
    height: 100%;
  }

  .top-type-back {
    left: 15px;
    top: 15px;
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
  }

  .top-type-back img {
    width: 100%;
    height: 100%;
  }

  .top-type-contact {
    right: 15px;
    top: 125px;
    width: 30px;
    height: 30px;
    position: absolute;
    cursor: pointer;
  }

  .top-type-contact img {
    width: 100%;
    height: 100%;
  }

  .info-content-bottom {
    width: 100%;
    height: 0%;
    display: flex;
    flex-direction: column;
  }

  .info-content-bottom-title {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .info-content-bottom-type {
    margin-top: 15px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }
  .info-content-bottom-time {
    margin-top: 3px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .info-content-bottom-score {
    margin-top: 10px;
    margin-left: 10px;
    margin-right: 10px;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .info-content-bottom-score-dou img {
    width: 15px;
  }

  .info-content-bottom-score-dou-data {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    margin-bottom: 5px;
    margin-left: 5px;
    margin-right: 20px;
  }

  .info-content-bottom-score-mdl img {
    height: 15px;
    margin-left: 0px;
  }

  .info-content-bottom-score-mdl-data {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Medium;
    margin-bottom: 5px;
    margin-left: 5px;
  }

  .top-main-mid-line {
    width: 100%;
    height: 0;
    border-top: 1px dashed rgba(255, 255, 255, 0.4); /* 点状线 */
    margin-top: 5px;
  }

  .menu-container {
    width: 100%;
    height: 50%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 6px;
  }

  .selected {
    color: #fff;
    text-align: left;
    cursor: pointer;
    margin: 0px 15px 0px 20px;
    height: 36px;
    white-space: normal;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
  }

  .unselected {
    color: #fff;
    text-align: left;
    cursor: pointer;
    margin: 0px 15px 0px 20px;
    height: 36px;
    white-space: normal;
    overflow-wrap: break-word;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
  }

  .unselected img {
    width: 8px;
    display: block;
  }

  .selected img {
    width: 8px;
    display: none;
  }

  .slider-menu-container {
    display: flex;
    flex-flow: 1;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.15);
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  .bottom-container-right {
    right: 0;
    top: 0;
    position: absolute;
    width: 80px;
    height: 100%;
    margin-right: 30px;
  }

  .slider-container {
    width: 26px;
    height: 100%;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  .slider-container img {
    width: 8px;
    cursor: pointer;
  }

  @keyframes slideIn {
    from {
      width: 124px;
    }

    to {
      width: 230px;
    }
  }

  @keyframes slideOut {
    from {
      width: 230px;
    }

    to {
      width: 124px;
    }
  }

  .discover {
    width: 100%;
    height: 100%;
  }

  .trailers {
    width: 70px;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: 10px;
  }

  .trailers-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  .trailers-container-item {
    flex-shrink: 0;
    width: 100%;
    margin-bottom: 5px;
    margin-top: 5px;
    /* padding: 0px; */
    margin-left: 1px;
    box-sizing: border-box;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.5s ease;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 90px;
  }

  .trailers-container-item-img {
    width: 55px;
    height: 35px;
    position: absolute;
    display: block;
    border: none;
    object-fit: cover;
    border-radius: 8px;
    margin-top: 5px;
  }

  .history-container-item-img-overlay {
    content: "";
    position: absolute;
    width: 50px;
    height: 30px;
    border-radius: 10px;
    margin-top: 6px;
    margin-left: 14px;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.25);
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
  }

  .trailers-container-item:hover .history-container-item-img-overlay {
    opacity: 0;
  }

  .trailers-container-item-img-top {
    position: absolute;
    left: 2px;
    top: 0.5px;
    height: 41px;
    width: 62px;
    border: 1.5px solid #fff;
    border-radius: 9px;
  }

  .trailers-container-item-img-top-gif {
    position: absolute;
    left: -25.5px;
    top: -27.5px;
    height: 124px;
    width: 120px;
  }

  .history-container-item-img-top-dot {
    position: absolute;
    width: 20px;
    height: 20px;
    top: -3px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .history-container-item-img-top-dot-item {
    width: 6px;
    height: 6px;
    border-radius: 50%;
    animation: scale 1s linear 2;
    box-shadow: 0 0 6px #fff;
    background-color: #fff;
  }

  @keyframes scale {
    0%,
    100% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.5);
    }
  }
  .item-pause {
    position: absolute;
    top: 12px;
    left: 24px;
    width: 20px;
    height: 20px;
  }
  .item-pause img {
    width: 100%;
    height: 100%;
  }

  @keyframes rotateDot {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  .trailers-container-item-detail {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 30px;
  }

  .trailers-container-item-detail-container {
    display: flex;
    flex-direction: column;
    padding: 0px 0px 0px 0px;
  }

  .trailers-container-item-detail-container-top {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .trailers-up-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5px;
    cursor: pointer;
  }

  .trailers-down-arrow {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    cursor: pointer;
  }

  .trailers-up-scroll {
    cursor: pointer;
    height: 10px;
    margin: 5px;
  }

  .trailers-down-scroll {
    cursor: pointer;
    height: 10px;
    margin: 10px;
  }

  .main-info-left-container {
    width: 50%;
    display: flex;
    flex-direction: column;
    background-color: rgba(255, 255, 255, 0.15);
    border-radius: 8px;
    padding: 12px 0px 0px 12px;
    margin-right: 10px;
  }

  .main-info-left-pic-container {
    height: 50%;
    width: 100%;
    display: flex;
    flex-direction: row;
  }

  .main-info-left-pic-left-container {
    width: 90px;
    height: 120px;
    position: relative;
  }

  .main-info-left-pic-left-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
    box-shadow: 10px 13px 18px rgba(0, 0, 0, 0.3);
  }

  .top-collect {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  .top-collect img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  .main-info-left-pic-right-container {
    width: 65%;
    height: 80%;
    display: flex;
    flex-direction: column;
    margin-left: 3%;
    justify-content: space-between;
  }

  .main-info-left-pic-right-top-container {
    display: flex;
    flex-direction: column;
  }

  .main-info-left-pic-right-container-title {
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    color: #fff;
    text-overflow: -o-ellipsis-lastline;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: -1px;
    line-height: 1.2;
  }

  .main-info-left-pic-right-container-type {
    font-size: 10px;
    font-family: Source Han Sans SC-Normal;
    color: #ff036a;
    display: flex;
    flex-direction: row;
    margin-top: 0px;
  }

  .main-info-left-pic-right-container-mid-line {
    margin: 0 1%;
  }

  .main-info-left-pic-right-container-tabs {
    display: flex;
    flex-direction: row;
    margin-top: 10px;
  }

  .main-info-left-pic-right-container-tabs-item-selected {
    border-radius: 8px;
    padding: 0.2% 3%;
    margin-right: 3%;
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Normal;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    cursor: pointer;
  }

  .main-info-left-pic-right-container-tabs-item-text {
    display: flex;
    flex-direction: row;
  }

  .main-info-left-pic-right-container-tabs-item-text img {
    width: 5px;
    margin-right: 3px;
    margin-bottom: 1px;
  }

  .main-info-left-pic-right-container-tabs-item-unselected {
    border-radius: 8px;
    padding: 0.2% 3%;
    margin-right: 3%;
    border: 1px #fff solid;
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Normal;
    background-image: none;
    cursor: pointer;
  }

  .main-info-left-pic-right-bottom-container {
    display: flex;
    flex-direction: column;
  }

  .main-info-left-pic-right-bottom-container-formats {
    font-size: 10px;
    font-family: Source Han Sans SC-Normal;
    color: #fff;
    margin-top: 10px;
  }

  .main-info-left-pic-right-bottom-container-tips {
    display: flex;
    flex-direction: row;
    /* margin-top: 5px; */
    color: #a8a8a8;
    font-size: 10px;
    font-family: Source Han Sans SC-Normal;
  }

  .main-info-left-pic-right-bottom-container-tips-span {
    color: #a8a8a8;
    font-size: 10px;
    font-family: Source Han Sans SC-Normal;
  }
  .main-info-left-pic-right-bottom-container-tips-dian {
    margin-left: 5px;
    margin-right: 5px;
  }

  .main-info-left-detail-container {
    margin-top: 10px;
    height: 140px;
    width: 94%;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.4;
    overflow: auto;
    padding-right: 5px;
  }

  .main-info-left-deta-score-container {
    height: 20%;
    width: 100%;
    display: flex;
    flex-direction: row;
    margin-top: 15px;
  }

  .main-info-left-deta-score-left-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
  }

  .main-info-left-deta-score-left-container-tips {
    width: 3px;
    height: 10px;
    border-radius: 3px;
    background-color: #ff036a;
    margin-right: 3%;
  }

  .main-info-left-deta-score-left-date-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .main-info-container-item-date-title {
    color: #a8a8a8;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5px;
  }

  .main-info-container-item-date-detail {
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
  }

  .main-info-left-deta-score-left-score-container {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .main-info-container-item-score-title {
    color: #a8a8a8;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5px;
  }

  .main-info-container-item-date-detail {
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
  }

  .main-info-container-item-detail-source {
    display: flex;
    flex-direction: row;
    margin-top: 8px;
  }

  .main-info-container-item-detail-source-dou img {
    width: 12px;
    margin-right: 5px;
    cursor: pointer;
  }

  .main-info-container-item-detail-source-dou-data {
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
    margin-top: 1px;
  }

  .main-info-container-item-detail-source-dou-mdl-mid {
    width: 10px;
  }

  .main-info-container-item-detail-source-mdl img {
    width: 18px;
    margin-right: 10px;
    cursor: pointer;
  }

  .main-info-container-item-detail-source-mdl-data {
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
    margin-top: 1px;
  }

  .main-info-container-item-detail-source-imdb img {
    width: 22px;
    margin-right: 10px;
    cursor: pointer;
    margin-top: 2px;
  }

  .main-info-left-deta-score-right-container {
    display: flex;
    flex-direction: column;
    width: 50%;
    height: 100%;
  }

  .main-info-left-deta-score-right-platform-container {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    margin-left: 5px;
  }
  .main-info-left-deta-score-left-platform-container-tips {
    width: 3px;
    height: 10px;
    border-radius: 3px;
    background-color: #ff036a;
    margin-right: 3%;
    flex-shrink: 0; /* 防止被压缩 */
    margin-top: 4px;
  }

  .main-info-container-item-platform-title {
    color: #a8a8a8;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
    margin-right: 5px;
    white-space: nowrap;
    margin-top: 0px;
  }

  .main-info-container-item-platform-detail {
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
    margin-top: 0px;
  }

  .main-info-left-deta-score-right-tk-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2.5%;
  }

  .main-info-container-item-detail-tk {
    width: 50%;
    color: #fff;
    font-size: 10px;
    font-family: Source Han Sans SC-Regular;
  }

  .main-info-right-container-type-0 {
    width: 50%;
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* 创建4列 */
    grid-template-rows: repeat(3, 1fr); /* 创建3行 */
    grid-gap: 10px; /* 格子之间的间隔 */
    height: auto;
    border-radius: 8px;
    overflow: hidden;
  }

  .main-info-right-container-type-1 {
    width: calc(100% - 134px);
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* 创建4列 */
    grid-template-rows: repeat(3, 1fr); /* 创建3行 */
    grid-gap: 10px; /* 格子之间的间隔 */
    height: auto;
    border-radius: 8px;
    overflow: hidden;
    margin-left: 10px;
    height: 300px;
  }

  .main-info-right-container-trailers-container-type-0 {
    width: 54%;
    height: 100%;
  }

  .main-info-right-container-trailers-container-type-1 {
    width: calc(100% - 244px);
    height: 300px;
    margin-left: 10px;
  }

  .main-info-right-container-trailers-container-type-1-close {
    width: calc(100% - 134px);
    height: 300px;
    margin-left: 10px;
  }
  .main-info-right-container-grid-item {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
  }

  .main-info-right-container-grid-item-top-special-div {
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .main-info-right-container-grid-item-top-special-div span {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    padding: 3% 15%;
    border: 1px solid #fff;
    border-radius: 20px;
  }

  .main-mid-line {
    width: 100%;
    height: 0;
    /* border-top: 1px dashed rgba(255, 255, 255, 0.4);  */
    margin-top: 0px;
  }

  .main-mid-container {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 20px;
    height: 400px;
    display: flex;
    flex-direction: row;
  }

  .main-bottom-container {
    margin-top: 3%;
    margin-left: 20px;
    margin-right: 20px;
  }

  .submitted-tips {
    position: absolute;
    top: 50px;
    left: 45%;
    background-color: #fff;
    color: #2b2d31;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    padding: 5px 20px;
    border-radius: 8px;
    z-index: 100;
  }

  @keyframes slideDown {
    from {
      transform: translateY(-150%);
    }
    to {
      transform: translateY(0);
    }
  }

  @keyframes slideUp {
    from {
      transform: translateY(0);
    }
    to {
      transform: translateY(-150%);
    }
  }

  .slide-down-enter-active,
  .slide-up-leave-active {
    animation-duration: 0.5s;
    animation-fill-mode: both;
  }

  .submitted-tips,
  .slide-up-leave-to {
    animation-name: slideUp;
  }

  .submitted-tips,
  .slide-down-leave-to {
    animation-name: slideDown;
  }

  /* main---end */
  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 1.5px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.15) !important;
    border-radius: 1.5px;
  }
}
</style>

