import { render, staticRenderFns } from "./CollectOfWorksPop.vue?vue&type=template&id=2a42b23f&scoped=true&"
import script from "./CollectOfWorksPop.vue?vue&type=script&lang=js&"
export * from "./CollectOfWorksPop.vue?vue&type=script&lang=js&"
import style0 from "./CollectOfWorksPop.vue?vue&type=style&index=0&id=2a42b23f&prod&scoped=true&lang=css&"
import style1 from "./CollectOfWorksPop.vue?vue&type=style&index=1&id=2a42b23f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2a42b23f",
  null
  
)

export default component.exports