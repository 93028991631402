<!-- 注册弹窗 -->
<template>
  <div class="pop-wrapper"
       v-if="isSignUpDialogVisible">
    <div class="pop-mask"
         @click="close"></div>
    <div class="pop-dialog-bg">
      <div class="pop-dialog">
        <div class="pop-dialog-container">
          <div class="pop-close">
            <span> </span>
            <img src="@/assets/login/close-no-cicle.png"
                 @click="close"
                 alt="" />
          </div>

          <img class="pop-title-img"
               src="@/assets/home/interlib-logo.png"
               alt="" />

          <span class="pop-tips">{{$t('sign_up')}}</span>
          <LoginDropdownMenu class="item-border"
                             v-show="!isMobile"
                             :placeholder="$t('sign_up_country_placeholder')"
                             :type="'user_country'"
                             :items="user_countrys"
                             @selected="handleSelected" />
          <CustomLoginInput class="item-border"
                            v-show="!isMobile"
                            v-model="inputCompanyNameValue"
                            value=""
                            :placeholder="$t('sign_up_company_name_placeholder')"
                            @input="handleInput" />
          <LoginDropdownMenu class="item-border"
                             v-show="!isMobile"
                             :placeholder="$t('sign_up_company_type_placeholder')"
                             :type="'company_nature'"
                             :items="company_natures"
                             @selected="handleSelected" />
          <CustomLoginInput class="item-border"
                            v-show="!isMobile"
                            v-model="inputNicknameValue"
                            value=""
                            :placeholder="$t('sign_up_nickname_placeholder')"
                            @input="handleInput" />
          <CustomLoginInput class="item-border"
                            v-model="inputEmailValue"
                            value=""
                            :type="'email'"
                            :placeholder="$t('sign_up_email_placeholder')"
                            @input="handleInput" />
          <CustomLoginInputHasCountdown class="item-border"
                                        v-model="inputverficationCodeValue"
                                        value=""
                                        :placeholder="$t('sign_up_verfication_code_placeholder')"
                                        @input="handleInput"
                                        @get-code="handleGetCodeFromChild"
                                        @noEmail="handleGetCodeNoEmail" />
          <CustomLoginInputPassword class="item-border"
                                    v-model="inputPasswordValue"
                                    value=""
                                    :placeholder="$t('sign_up_password_placeholder')"
                                    @input="handleInput" />

          <span class="show-tips"> {{  showTips }}</span>

          <div class="makesure"
               @click="handleMakeSureClick">
            {{$t('sign_up')}}
          </div>
          <div class="goto-login-container">
            <span class="no-account">{{$t('sign_up_have_account')}}</span>
            <span class="goto-login"
                  @click="gotoLogin">{{$t('sign_up_Goto_login')}}</span>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import CustomLoginInput from '@/components/CustomLoginInput.vue';
import LoginDropdownMenu from '@/components/LoginDropdownMenu.vue';
import DropdownMenu from '@/components/DropdownMenu.vue'
import CustomLoginInputHasCountdown from '@/components/CustomLoginInputHasCountdown.vue';
import CustomLoginInputPassword from '@/components/CustomLoginInputPassword.vue';
import { mapState, mapActions } from 'vuex';
import { sysDictInfoApi, sendCodeApi, registerApi } from '@/api/userApi.js'
import { recordUserRegisterActionApi } from '@/api/userLogApi.js'

export default {
  components: {
    CustomLoginInput,
    DropdownMenu,
    LoginDropdownMenu,
    CustomLoginInputHasCountdown,
    CustomLoginInputPassword
  },
  created () {
    // 创建一个 MediaQueryList 对象，传入媒体查询条件
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    // 检查是否匹配指定的媒体查询条件
    if (mediaQuery.matches) {
      // 在此处执行适应小屏幕的代码
      console.log('屏幕宽度小于等于 1024px');
      this.isMobile = true;
    } else {
      // 在此处执行适应大屏幕的代码
      console.log('屏幕宽度大于 1024px');
      this.isMobile = false;
    }
  },
  computed: {
    ...mapState('dialog', ['isSignUpDialogVisible']),
  },
  watch: {
    // 监听 isLoginDialogVisible 变化，在变化时执行相应的逻辑
    isSignUpDialogVisible (newValue) {
      if (newValue) {
        // 弹窗显示的逻辑
        this.reloadSelectData()
        localStorage.setItem('signUpInputEmail', '')
        this.getRecordUserRegisterActionData('3')
      }
    },
  },
  data () {
    return {
      company_natures: [],
      user_countrys: [],
      selectedUserCountrysValue: '',
      inputCompanyNameValue: '',
      selectedCompanyNaturesValue: '',
      inputNicknameValue: '',
      inputEmailValue: '',
      inputverficationCodeValue: '',
      inputPasswordValue: '',
      showTips: '',
      isMobile: false,
      hasCheckedSignUpButton: false,
    }
  },
  methods: {
    ...mapActions('dialog', ['showLoginDialog', 'hideLoginDialog', 'showForgetPasswordDialog', 'hideForgetPasswordDialog', 'showSignupDialog', 'hideSignupDialog']),

    close () {
      this.hideSignupDialog()
      this.handleRemove()
    },
    reloadSelectData () {
      //获取国家列表
      this.handleGetUserCountry()
      //获取公司类型列表
      this.handleGetCompanyNature()
    },
    //获取国家列表
    handleGetUserCountry () {
      const resultData = []
      const params = {
        groupCode: 'user_country'
      }
      console.log(params)
      sysDictInfoApi(params)
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            res.data.data.forEach((item) => {
              resultData.push(item)
            })
            this.user_countrys = resultData
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    //获取公司类型列表
    handleGetCompanyNature () {
      const resultData = []
      const params = {
        groupCode: 'company_nature'
      }
      console.log(params)
      sysDictInfoApi(params)
        .then((res) => {
          console.log(res)
          if (res.data.code === 200) {
            res.data.data.forEach((item) => {
              resultData.push(item)
            })
            this.company_natures = resultData
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },
    gotoLogin () {
      this.hideSignupDialog()
      this.showLoginDialog()
    },
    handleMakeSureClick () {
      this.submitData()
    },
    handleRemove () {
      this.company_natures = []
      this.user_countrys = []
      this.selectedCompanyNaturesValue = ''
      this.inputCompanyNameValue = ''
      this.selectedUserCountrysValue = ''
      this.inputNicknameValue = ''
      this.inputEmailValue = ''
      this.inputverficationCodeValue = ''
      this.inputPasswordValue = ''
      localStorage.setItem('signUpInputEmail', '')
      this.hasCheckedSignUpButton = false
    },
    //提交数据
    submitData () {
      if (this.hasCheckedSignUpButton === false) {
        this.getRecordUserRegisterActionData('5')
        this.hasCheckedSignUpButton = true
      }

      if (!this.isMobile) {
        if (!this.selectedUserCountrysValue) {
          this.showTips = this.$t('tips_select_country');

          setTimeout(() => {
            this.showTips = '';
          }, 4000);
          return
        }

        if (!this.inputCompanyNameValue) {
          this.showTips = this.$t('tips_input_company_name');

          setTimeout(() => {
            this.showTips = '';
          }, 4000);
          return
        }

        if (!this.selectedCompanyNaturesValue) {
          this.showTips = this.$t('tips_select_company_type');

          setTimeout(() => {
            this.showTips = '';
          }, 4000);
          return
        }

        if (!this.inputNicknameValue) {
          this.showTips = this.$t('tips_input_nickname');

          setTimeout(() => {
            this.showTips = '';
          }, 4000);
          return
        }
      }

      if (!this.inputEmailValue) {
        this.showTips = this.$t('tips_input_email');

        setTimeout(() => {
          this.showTips = '';
        }, 4000);
        return
      }
      if (!this.inputverficationCodeValue) {
        this.showTips = this.$t('tips_input_email_code');

        setTimeout(() => {
          this.showTips = '';
        }, 4000);
        return
      }
      if (!this.inputPasswordValue) {
        this.showTips = this.$t('tips_input_password');

        setTimeout(() => {
          this.showTips = '';
        }, 4000);
        return
      }

      const data = {
        country: this.selectedUserCountrysValue,
        company: this.inputCompanyNameValue,
        companyNature:
          this.selectedCompanyNaturesValue,
        username:
          this.inputNicknameValue,
        email: this.inputEmailValue,
        code: this.inputverficationCodeValue,
        password: this.inputPasswordValue
      }

      console.log(data)
      this.getRecordUserRegisterActionData('6')
      // 发送注册请求
      registerApi(data)
        .then((res) => {
          console.log(res)
          console.log(`code + ${res.data.code}`)
          if (res.data.code === 200) {
            console.log("es.data.code === 200");
            this.getRecordUserRegisterActionData('7')
            this.close()
            this.showLoginDialog()
          }
        })
        .catch((error) => {
          console.error(error)
          console.log("error.data.message", error.data.message);
          this.showTips = error.data.message;
          setTimeout(() => {
            this.showTips = '';
          }, 4000);
        })

    },
    handleGetCodeFromChild (countdown) {
      this.getRecordUserRegisterActionData('4')
      // 在这里可以获取到倒计时时间
      console.log("获取到倒计时时间：" + countdown + "s");
      const params = {
        email: this.inputEmailValue,
      }
      console.log(params)
      // 发送获取验证吗请求
      sendCodeApi(params)
        .then((res) => {
          console.log(res)
          console.log(`code + ${res.data.code}`)
          if (res.data.code === 200) {
            console.log("res.data.code === 200");
            this.showTips = res.data.message
          }
        })
        .catch((error) => {
          console.error(error)
          this.showTips = error.data.message;
        })
      setTimeout(() => {
        this.showTips = '';
      }, 4000);
    },
    handleGetCodeNoEmail () {
      this.showTips = this.$t('tips_input_email');

      setTimeout(() => {
        this.showTips = '';
      }, 4000);
    },
    handleSelected (value, type) {
      if (type == 'user_country') {
        this.selectedUserCountrysValue = value
      } else if (type == 'company_nature') {
        this.selectedCompanyNaturesValue = value
      }
    },
    handleInput (value, type) {
      if (type == 'email') {
        this.inputEmailValue = value
        if (!value) {
          localStorage.setItem('signUpInputEmail', '')
        } else {
          localStorage.setItem('signUpInputEmail', value)
        }
      }
    },
    //记录用户注册行为日志
    getRecordUserRegisterActionData (actionType) {
      recordUserRegisterActionApi(actionType)
        .then(async (res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },
  }
}
</script>


<style scoped>
.pop-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  animation: fadeIn 0.5s ease-out;
}

.pop-mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: -1;
}

.pop-dialog-bg {
  width: 622px;
  height: 714px;
  /* background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe); */
  background-color: #fff;
  border-radius: 30px;
  box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
}

.pop-dialog {
  width: 620px;
  height: 712px;
}
.pop-dialog-container {
  width: 620px;
  height: 712px;
  border-radius: 29px;
  margin: 1px 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-image: url("@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.pop-close {
  display: flex;
  flex-direction: row;
  align-items: end;
  justify-content: space-between;
  margin-top: 20px;
  width: 100%;
}

.pop-close img {
  width: 15px;
  height: auto;
  margin-right: 25px;
  cursor: pointer;
}

.pop-title-img {
  margin-top: 10px;
  width: 190px;
  height: auto;
}

.pop-tips {
  margin-top: 25px;
  color: #fff;
  font-size: 22px;
  font-family: Source Han Sans SC-Medium;
  height: 12px;
  width: 70%;
  margin-bottom: 25px;
}

.item-border {
  margin-top: 15px;
  width: 70%;
}

.password-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  margin-top: 20px;
}

.remember-password {
  display: flex;
  flex-direction: row;
  justify-content: left;
  cursor: pointer;
}

.remember-password img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.remember-password span {
  color: #a8a8a8;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
}

.forget-password {
  display: flex;
  flex-direction: row;
  justify-content: right;
  align-items: center;
  cursor: pointer;
}

.forget-password img {
  width: 7px;
  height: 10px;
  margin-left: 10px;
  margin-top: 3px;
}

.forget-password span {
  color: #fff;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
}

.show-tips {
  margin-top: 15px;
  color: #ff036a;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  height: 12px;
}

.makesure {
  margin-top: 20px;
  color: #fff;
  font-size: 18px;
  font-family: Source Han Sans SC-Medium;
  padding: 8px 80px;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  border-radius: 18px;
  cursor: pointer;
}

.goto-login-container {
  margin-top: 25px;
}

.no-account {
  color: #a8a8a8;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
  margin-right: 19px;
}

.goto-login {
  color: #1cc405;
  font-size: 16px;
  font-family: Source Han Sans SC-Normal;
  cursor: pointer;
}

.pop-wrapper.fade-out {
  animation: fadeOut 0.3s ease-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
</style>


<style scoped>
@media only screen and (max-width: 1024px) {
  .pop-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
    animation: fadeIn 0.5s ease-out;
  }

  .pop-mask {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    z-index: -1;
  }

  .pop-dialog-bg {
    width: 482px;
    height: 284px;
    /* background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe); */
    background-color: #fff;
    border-radius: 30px;
    box-shadow: 2px 2px 15px rgba(0, 0, 0, 0.3);
    margin-bottom: 80px;
  }

  .pop-dialog {
    width: 480px;
    height: 282px;
    border-radius: 29px;
    background-image: url("@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: auto;
    margin: 1px 1px;
  }

  .pop-dialog-container {
    width: 479px;
    height: 412px;
    border-radius: 29px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-image: none;
    overflow: auto;
  }

  .pop-close {
    display: flex;
    flex-direction: row;
    align-items: end;
    justify-content: space-between;
    margin-top: 20px;
    width: 100%;
  }

  .pop-close img {
    width: 15px;
    height: auto;
    margin-right: 25px;
    cursor: pointer;
  }

  .pop-title-img {
    margin-top: 0px;
    width: 120px;
    height: auto;
  }

  .pop-tips {
    margin-top: 10px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    height: 12px;
    width: 70%;
    margin-bottom: 25px;
  }

  .item-border {
    margin-top: 10px;
    width: 70%;
  }

  .password-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 70%;
    margin-top: 20px;
  }

  .remember-password {
    display: flex;
    flex-direction: row;
    justify-content: left;
    cursor: pointer;
  }

  .remember-password img {
    width: 24px;
    height: 24px;
    margin-right: 10px;
  }

  .remember-password span {
    color: #a8a8a8;
    font-size: 16px;
    font-family: Source Han Sans SC-Normal;
  }

  .forget-password {
    display: flex;
    flex-direction: row;
    justify-content: right;
    align-items: center;
    cursor: pointer;
  }

  .forget-password img {
    width: 7px;
    height: 10px;
    margin-left: 10px;
    margin-top: 3px;
  }

  .forget-password span {
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Normal;
  }

  .show-tips {
    margin-top: 10px;
    color: #ff036a;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    height: 12px;
  }

  .makesure {
    margin-top: 10px;
    color: #fff;
    font-size: 16px;
    font-family: Source Han Sans SC-Medium;
    padding: 6px 60px;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    border-radius: 18px;
    cursor: pointer;
  }

  .goto-login-container {
    margin-top: 10px;
  }

  .no-account {
    color: #a8a8a8;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    margin-right: 19px;
  }

  .goto-login {
    color: #1cc405;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    cursor: pointer;
  }

  .pop-wrapper.fade-out {
    animation: fadeOut 0.3s ease-out;
  }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeOut {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
}
</style>