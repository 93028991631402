// store/collectDialog.js

const state = {
  isCreatCollectDialogVisible: false, // 创建收藏夹弹窗的显示状态
  isCollectOfWorksDialogVisible: false, // 收藏到收藏夹弹窗的显示状态
  isCollectOfWorksSearchDialogVisible: false, // 搜索收藏夹弹窗的显示状态
  projectId: '', //收藏的节目ID
  creatCollectType: 0, // 0:收藏页-添加； 1:收藏弹窗-添加； 2:搜索收藏弹窗-添加； 
  hasCollectSuccess: false // 是否收藏成功
};

const mutations = {
  SET_CREATE_COLLECT_VISIBLE(state, isVisible) {
    state.isCreatCollectDialogVisible = isVisible;
  },
  SET_COLLECT_OF_WORKS_DIALOG_VISIBLE(state, isVisible) {
    state.isCollectOfWorksDialogVisible = isVisible;
  },
  SET_COLLECT_OF_WORKS_SEARCH_DIALOG_VISIBLE(state, isVisible) {
    state.isCollectOfWorksSearchDialogVisible = isVisible;
  },
  SET_PROJECTID_DIALOG_VISIBLE(state, projectId) {
    state.projectId = projectId;
  },
  SET_CREATE_COLLECT_TYPE_DIALOG_VISIBLE(state, type) {
    state.creatCollectType = type;
  },
  SET_HAS_COLLECT_SUCCESS_DIALOG_VISIBLE(state, collectSuccess) {
    state.hasCollectSuccess = collectSuccess;
  },
};

const actions = {
  showCreatCollectDialog ({ commit }) {
    commit('SET_CREATE_COLLECT_VISIBLE', true);
  },
  hideCreatCollectDialog({ commit }) {
    commit('SET_CREATE_COLLECT_VISIBLE', false);
  },
  showCollectOfWorksDialog({ commit }) {
    commit('SET_COLLECT_OF_WORKS_DIALOG_VISIBLE', true);
  },
  hideCollectOfWorksDialog({ commit }) {
    commit('SET_COLLECT_OF_WORKS_DIALOG_VISIBLE', false);
  },
  showCollectOfWorksSearchDialog({ commit }) {
    commit('SET_COLLECT_OF_WORKS_SEARCH_DIALOG_VISIBLE', true);
  },
  hideCollectOfWorksSearchDialog({ commit }) {
    commit('SET_COLLECT_OF_WORKS_SEARCH_DIALOG_VISIBLE', false);
  },
  setProjectIdDialog({ commit }, itemId) {
    commit('SET_PROJECTID_DIALOG_VISIBLE', itemId);
  },
  removeProjectIdDialog({ commit }) {
    commit('SET_PROJECTID_DIALOG_VISIBLE', '');
  },
   setCreatCollectTypeDialog({ commit }, type) {
    commit('SET_CREATE_COLLECT_TYPE_DIALOG_VISIBLE', type);
  },
   setCollectSuccessDialog({ commit }) {
    commit('SET_HAS_COLLECT_SUCCESS_DIALOG_VISIBLE', true);
  },
   setCollectNormalDialog({ commit }) {
    commit('SET_HAS_COLLECT_SUCCESS_DIALOG_VISIBLE', false);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
