<!-- DetailGenresPage -->
<template>
  <div class="genres-main-container">
    <div class="genres-left-container">
      <div class="genres-left-container-top">
        <div class="genres-left-container-top-title-container">
          <img src="@/assets/detail/genres_theme.jpg"
               alt="" />
          <span>{{ $t('detail_theme_analysis') }}</span>
        </div>
        <div class="genres-left-container-top-type-container">
          <ul style="display: flex;  flex-wrap: wrap;">
            <li v-for="(item, index) in menuItems"
                :key="index"
                :class="{ selected: selectedMenuItem === item }"
                @click="selectMenuItem(item, index)"
                style="margin-right: 10px;">
              {{ item }}
            </li>
          </ul>
        </div>
      </div>
      <div class="genres-left-container-bottom">
        <div class="genres-left-container-bottom-item"
             v-if="genreName1">
          <div class="genres-left-container-bottom-item-progress">
            <el-progress type="circle"
                         :stroke-width=elProgressStrokeWidth
                         color="#1cc405"
                         :percentage="genreNumber1"
                         text-color="#fff"
                         define-back-color="rgba(247, 247, 247, 0.04)"
                         :width=elProgressWidth>
            </el-progress>
          </div>
          <span>
            {{genreName1}}
          </span>
        </div>
        <div class="genres-left-container-bottom-item"
             v-if="genreName2">
          <div class="genres-left-container-bottom-item-progress">
            <el-progress type="circle"
                         :stroke-width=elProgressStrokeWidth
                         color="#ffcb01"
                         :percentage="genreNumber2"
                         text-color="#fff"
                         define-back-color="rgba(247, 247, 247, 0.04)"
                         :width=elProgressWidth>
            </el-progress>
          </div>
          <span>
            {{genreName2}}
          </span>
        </div>
        <div class="genres-left-container-bottom-item"
             v-if="genreName3">
          <div class="genres-left-container-bottom-item-progress">
            <el-progress type="circle"
                         :stroke-width=elProgressStrokeWidth
                         color="#ff036a"
                         :percentage="genreNumber3"
                         text-color="#fff"
                         define-back-color="rgba(247, 247, 247, 0.04)"
                         :width=elProgressWidth>
            </el-progress>
          </div>
          <span>
            {{genreName3}}
          </span>
        </div>
      </div>
      <div class="genres-left-container-mid">
        <!-- <span>{{ isLoggedIn ?  genreInfo : $t('detail_genres_need_login_tips') }}</span> -->
        <span>{{ isLoggedIn ?  genreInfoDetail : $t('detail_genres_need_login_tips') }}</span>
      </div>
    </div>
    <div class="genres-right-container">
      <div class="genres-right-container-top">
        <div class="genres-right-container-top-type-list">
          <span v-for="(item, index) in typeList"
                :key="index"
                :class="{ 'genres-right-container-top-type-selected': selectedTypeId === item.id }"
                @click="typeSelect(item.id)">{{ languageType == 'en' ? item.nameEn :item.name  }}</span>
        </div>
      </div>
      <div class="genres-right-container-bottom">
        <div class="genres-right-container-bottom-grid-container">
          <div class="genres-right-container-bottom-grid-item"
               v-for="item in hotListData"
               :key="item.id"
               @click="gotoDetail(item)"
               :style="{ backgroundImage: 'url(' + item.imageUrl + ')' }">
            <div class="recommend-container-top-detail">
              <span class="recommend-container-top-detail-movies-title">{{  languageType == 'en' ? item.projectNameEn : item.projectName }}</span>
              <span class="recommend-container-top-detail-movies-detail">{{ item.genres.map(genre => genre.name).join(',') }}</span>
            </div>
            <div class="top-collect">
              <img :src="item.collectionFlag == 1 ? require('@/assets/home/collect-select.jpg') : require('@/assets/home/collect-normal.jpg') "
                   alt=""
                   @click="topCollectClick(item, $event)">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DetailWorkStylePage from '@/views/Detail/New/DetailWorkStylePage.vue'
import { genresDetailApi } from '@/api/detailApi.js'
import { getPresignedUrlApi } from '@/api/homeApi.js'
import { hotApi } from '@/api/discoverApi.js'

import { mapState, mapActions } from 'vuex';

export default {
  components: {
    workStyle: DetailWorkStylePage,
  },
  computed: {
    ...mapState('collectDialog', ['projectId']),
    ...mapState('deatilEra', ['eraId']),
    ...mapState('auth', ['isLoggedIn']),
    genreInfo () {
      const texts = []
      texts.push(
        `${this.$t('detail_theme_analysis_tips1')} ${this.selectedMenuItem
        }${this.selectedMenuIndex == 5 || this.selectedMenuIndex == 4
          ? this.$t('detail_theme_analysis_tips2')
          : ''
        }, ${this.$t('detail_theme_analysis_tips3')} ${this.allCount
        } ${this.$t('detail_theme_analysis_tips4')}`
      )
      if (this.genreName1 || this.genreName2 || this.genreName3) {
        texts.push(',')
      }

      return texts.join('')
    },
    genreInfoDetail () {
      const texts = []

      texts.push(
        `${this.$t('detail_theme_analysis_tips1')} ${this.selectedMenuItem
        }`
      )


      if (this.genreName1) {
        if (this.genreName2) {
          texts.push(
            `${this.genreNumber1}% ${this.$t(
              'detail_theme_analysis_tips6'
            )} ${this.genreName1}`
          )
          // texts.push(',')
        } else {
          texts.push(
            `${this.genreNumber1}% ${this.$t(
              'detail_theme_analysis_tips6'
            )} ${this.genreName1}.`
          )
        }
      }

      if (this.genreName2) {
        if (this.genreName3) {
          texts.push(
            `${this.genreNumber2}% ${this.$t(
              'detail_theme_analysis_tips6'
            )} ${this.genreName2}`
          )
          // texts.push(',')
        } else {
          texts.push(
            `${this.genreNumber2}% ${this.$t(
              'detail_theme_analysis_tips6'
            )} ${this.genreName2}.`
          )
        }
      }

      if (this.genreName3) {
        texts.push(
          `${this.$t('detail_theme_analysis_tips7')} ${this.genreNumber3
          }% ${this.$t('detail_theme_analysis_tips6')} ${this.genreName3
          }.`
        )
      }

      if (texts.length === 0) {
        texts.push('.')
      }

      return texts.join(',')
    },
  },
  props: {
    detailPageData: {
      type: Object,
      required: true
    },
  },
  watch: {
    detailPageData (newValue) {
      if (newValue) {
        console.log("DetailCastPage-detailPageData:", newValue);
        this.detailPageData = newValue;
        this.genresIdList = this.detailPageData.genres.map((genre) => genre.id)
        if (this.genresIdList && this.genresIdList.length > 0) {
          this.fetchGenresData()
        }

        this.typeList = this.detailPageData.genres;
        if (this.typeList && this.typeList.length > 0) {
          this.selectedTypeId = this.typeList[0].id
          this.fetchHotListData()
        }
      }
    }
  },
  created () {

    var mediaQueryPC = window.matchMedia('(min-width: 1920px)');
    if (mediaQueryPC.matches) {
      this.elProgressWidth = 180
      this.elProgressStrokeWidth = 20
      this.pageSize = 8
    }

    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    if (mediaQuery.matches) {
      this.isMobile = true;
      this.pageSize = 4
      this.elProgressWidth = 80
      this.elProgressStrokeWidth = 12
    } else {
      this.isMobile = false;
    }
    this.reloadLocationLanguageData()

    this.$store.watch(
      (state) => state.collectDialog.hasCollectSuccess,
      (newVal) => {
        if (newVal) {
          if (this.collectType == 1 && this.selectItem.id == this.projectId) {
            console.log('DetailPageTrailersCast-Recommend-hasCollectSuccess:', newVal);

            this.hotListData = this.hotListData.map(item => {
              if (item.id === this.selectItem.id) {
                console.log('Recommend-匹配数据:', item.id === this.selectItem.id);
                item.collectionFlag = 1;
              }
              return item;
            });
            this.setCollectNormalDialog()
          }
        }
      }
    );

    console.log("DetailGenresPage-detailPageData:", this.detailPageData.genres);

    this.genresIdList = this.detailPageData.genres.map((genre) => genre.id)
    if (this.genresIdList && this.genresIdList.length > 0) {
      this.fetchGenresData()
    }

    this.typeList = this.detailPageData.genres;
    if (this.typeList && this.typeList.length > 0) {
      this.selectedTypeId = this.typeList[0].id
      this.fetchHotListData()
    }
  },

  data () {
    return {
      menuItems: [],
      selectedMenuItem: this.$t('detail_last_1_year'),
      selectedMenuIndex: 3,
      languageType: "",
      genresIdList: [],
      genresData: {},
      allCount: 0,
      tempCount: '',

      genreName1: undefined,
      genreNumber1: 0,
      genreName2: undefined,
      genreNumber2: 0,
      genreName3: undefined,
      genreNumber3: 0,
      isMobile: false,
      typeList: [],
      selectedTypeId: undefined,
      hotListData: [],
      currentPage: 1,
      pageSize: 6,
      elProgressWidth: 160,
      elProgressStrokeWidth: 20
    }
  },
  methods: {
    ...mapActions('collectDialog', ['showCollectOfWorksDialog', 'setProjectIdDialog', 'hasCollectSuccess', 'setCollectNormalDialog']),
    ...mapActions('dialog', ['showLoginDialog']),

    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }

      this.menuItems = [
        this.$t('detail_last_month'),
        this.$t('detail_last_3_month'),
        this.$t('detail_last_6_month'),
        this.$t('detail_last_1_year'),
        '2023',
        '2024'
      ]
    },

    async handleDetailData (casts) {
      if (casts && casts.length > 0) {
        //获取头像图片URL
        const resultData = await Promise.all(
          casts.map(async (element) => {
            if (element) {
              if (element.mode == 1) {//mode 文件访问模式 1:fileId  2:imgUrl链接 null:没有图片
                try {
                  const imageRes = await getPresignedUrlApi(element.fileId);
                  if (imageRes.data.code === 200) {
                    element.imgUrl = imageRes.data.data.url;
                  }
                } catch (error) {
                  // 错误处理
                }
              }
            }
            return element; // 返回更新后的对象
          })
        );
        if (resultData.filter(Boolean).length > 0) {
          this.newCastsList = resultData.filter(Boolean);
        }
        console.log("处理后的演员头像数据：", this.newCastsList);
      }
    },

    slideLeft () {
      console.log('slideLeft');
      const container = document.querySelector('.popular-container');
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth;

      container.scrollBy({
        left: -scrollAmount,
        behavior: 'smooth'
      });
    },

    slideRight () {
      console.log('slideRight');
      const container = document.querySelector('.popular-container');
      const itemWidth = container.offsetWidth;
      const scrollAmount = itemWidth;

      container.scrollBy({
        left: scrollAmount,
        behavior: 'smooth'
      });
    },

    resetData () {
      this.genresIdList = []
      this.selectedMenuItem = this.$t('detail_last_1_year')
      this.selectedMenuIndex = 3
      this.genresData = {}
      this.allCount = 0


      this.selectedDirectorIndex = 0
      this.newMenuList = []
      this.directorInfo = {}
      this.influenceList = []
      this.scrollPosition = 0
      this.maxScroll = 0
      this.showMasterpiece = false
      this.showUpcoming = false
      this.bannerCurrentIndex = 0
      this.dataList = []
      this.masterpieceBannerList = []

      this.upcomingBannerList = []

      this.selectItem = {}
      this.collectType = 0
      this.pageSize = 6
    },
    selectMenuItem (item, index) {
      this.selectedMenuItem = item
      this.selectedMenuIndex = index
      this.fetchGenresData()
    },
    //获取Genres数据
    fetchGenresData () {
      const data = {
        language: this.languageType,
        genres: this.genresIdList,
        dateFilter: this.selectedMenuIndex + 1
      }
      genresDetailApi(data)
        .then((res) => {
          if (res.data.code === 200) {
            const resultData = res.data
            this.genresData = resultData.data

            this.allCount = this.genresData.count
            this.tempCount = ' '
            if (this.genresData.genresCount && this.genresData.genresCount.length > 0) {
              this.genreName1 =
                this.genresData.genresCount[0].genreName
              this.genreNumber1 = parseInt(
                this.genresData.genresCount[0].number
              )
              if (this.genreNumber1 !== 0) {
                this.genreNumber1 = Math.round(this.genreNumber1 / this.allCount * 100);
              }
            }

            if (this.genresData.genresCount && this.genresData.genresCount.length > 1) {
              this.genreName2 =
                this.genresData.genresCount[1].genreName
              this.genreNumber2 = parseInt(
                this.genresData.genresCount[1].number
              )
              if (this.genreNumber2 !== 0) {
                this.genreNumber2 = Math.round(this.genreNumber2 / this.allCount * 100);
              }
            }

            if (this.genresData.genresCount && this.genresData.genresCount.length > 2) {
              this.genreName3 =
                this.genresData.genresCount[2].genreName
              this.genreNumber3 = parseInt(
                this.genresData.genresCount[2].number
              )
              if (this.genreNumber3 !== 0) {
                this.genreNumber3 = Math.round(this.genreNumber3 / this.allCount * 100);
              }
            }
          }
        })
        .catch((error) => { })
    },
    //获取列表数据
    async fetchHotListData () {
      this.hotListData = [];
      const data = {
        pageNum: this.currentPage,
        pageSize: this.pageSize,
        type: this.detailPageData.type,
        isSearch: 0,
        genres: [],
      };
      if (this.selectedTypeId) {
        data.genres = [this.selectedTypeId]
      };
      try {
        const res = await hotApi(data);
        if (res.data.code === 200) {
          const resultData = res.data.data;

          const childrenResultData = await Promise.all(
            resultData.list.map(async (element) => {
              try {
                const imageRes = await getPresignedUrlApi(element.imageId[0]);
                if (imageRes.data.code === 200) {
                  element.imageUrl = imageRes.data.data.url;
                  return element;
                }
              } catch (error) {
                // 错误处理
              }
            })
          );
          this.hotListData = childrenResultData.filter(Boolean); // 过滤掉可能为 undefined 的元素
        }
      } catch (error) {
        // 错误处理
      }
    },
    gotoDetail (item) {
      if (this.detailPageData.id === item.id) {
        this.$emit('clickAndScrollToTop');
        return;
      }
      this.resetData()
      // 在详情页中再次导航到另一个详情页
      const targetPath = item.id + '-' + item.projectNameEn.trim().replace(/\s+/g, "-");
      if (this.$route.path !== targetPath) {
        console.log("eraId:", this.eraId);
        this.$router.push(targetPath);
      }
    },
    topCollectClick (item, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      if (this.isLoggedIn) {
        this.selectItem = item
        this.collectType = 1

        console.log("topCollectClick:", item.id);
        this.showCollectOfWorksDialog()
        this.setProjectIdDialog(item.id)
        console.log("projectId:", this.projectId);
      } else {
        this.showLoginDialog()
      }

    },
    bannerClick (id, event) {
      // 阻止事件冒泡到父元素的点击事件
      event.stopPropagation();
      console.log("projectId:", id);

      // 在详情页中再次导航到另一个详情页
      const targetPath = '/detail/' + id;
      console.log("targetPath:", targetPath);
      console.log("this.$route.path:", this.$route.path);
      if (this.$route.path !== targetPath) {
        this.$router.push(targetPath);
      }
    },
    bannerHandleClick (index) {
      this.bannerCurrentIndex = index
      console.log("bannerHandleClick:", index);
      if (index == 0) {
        this.dataList = this.masterpieceBannerList
      } else {
        this.dataList = this.upcomingBannerList
      }
    },

    handleScrollToTop () {
      this.$emit('clickAndScrollToTop');
    },
    typeSelect (id) {
      this.selectedTypeId = id
      this.fetchHotListData()
    },
  }
}
</script>

<style scoped>
.genres-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
}

.genres-left-container {
  width: 55%;
  height: 100%;
  margin-top: 30px;
  margin-left: 30px;
}

.genres-left-container-top {
  width: 100%;
  height: 20%;
  display: flex;
  flex-direction: column;
}

.genres-left-container-top-title-container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.genres-left-container-top-title-container img {
  width: 18px;
  height: 18px;
  margin-right: 15px;
}

.genres-left-container-top-title-container span {
  color: #f5f5f5;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
}

.genres-left-container-top-type-container {
  width: calc(100% -35px);
  margin: 0 0 0 0px;
  display: flex;
  flex-direction: row;
  overflow: auto;
}

.genres-left-container-top-type-container ul {
  list-style-type: none;
  padding: 0;
}

.genres-left-container-top-type-container li {
  padding: 0 8px 2px 8px;
  /* width: 80px;
  height: 20px; */
  border: 1px solid #e5e5e5;
  margin-top: 10px;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  white-space: nowrap;
}

.genres-left-container-top-type-container li.selected {
  background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
  color: white;
  font-family: Source Han Sans SC-Medium;
  border: none;
}

.genres-left-container-mid {
  width: calc(100% -35px);
  margin: 35px 0 0 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.genres-left-container-mid span {
  color: #a8a8a8;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
}

.genres-left-container-bottom {
  width: calc(100% -35px);
  margin: 70px 0 0 0px;
  display: flex;
  flex-direction: row;
}

.genres-left-container-bottom-item {
  width: 35%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.genres-left-container-bottom-item-progress {
  width: 100%;
}

.genres-left-container-bottom-item span {
  margin-top: 20px;
  color: #f5f5f5;
  font-size: 16px;
  width: 160px;
  text-align: center;
  font-family: Source Han Sans SC-Regular;
}

.genres-right-container {
  width: 48%;
  height: 100%;
  margin-top: 35px;
  margin-right: 30px;
}

.genres-right-container-top {
  width: 100%;
  height: 10%;
}

.genres-right-container-top-type-list {
  display: flex;
  flex-wrap: wrap;
}

.genres-right-container-top-type-list span {
  color: white;
  font-size: 12px;
  font-family: Source Han Sans SC-Regular;
  white-space: nowrap;
  padding: 3px 15px;
  border: 1px solid #e5e5e5;
  margin-right: 3%;
  border-radius: 28px;
  cursor: pointer;
}

.genres-right-container-top .genres-right-container-top-type-selected,
.genres-right-container-top-type-list
  .genres-right-container-top-type-selected {
  background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
  color: white;
  font-family: Source Han Sans SC-Medium;
  border: none;
}

.genres-right-container-bottom {
  width: 100%;
  height: 100%;
}

.genres-right-container-bottom-grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 5%;
  width: 100%;
  height: 390px;
  padding: 0px;
  margin-top: 20px;
}

.genres-right-container-bottom-grid-item {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: transform 0.5s ease;
}

.genres-right-container-bottom-grid-item:hover {
  transform: translateY(-5px);
}

.genres-right-container-bottom-grid-item::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 1;
  opacity: 0;
  transition: opacity 0.3s ease;
  border-radius: 20px;
}

.genres-right-container-bottom-grid-item:hover::before {
  opacity: 1;
}

.genres-right-container-bottom-grid-item:hover
  .recommend-container-top-detail
  span {
  animation: moveUp 0.5s ease forwards;
}

@keyframes moveUp {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-5px);
  }
}

.recommend-container-top-detail {
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.9),
    rgba(0, 0, 0, 0.8),
    rgba(0, 0, 0, 0.6),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.3),
    rgba(0, 0, 0, 0.2),
    rgba(0, 0, 0, 0.1),
    rgba(0, 0, 0, 0)
  ); /* 渐变背景 */
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.recommend-container-top-detail-movies-title {
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Medium;
  line-height: 1.1;
  margin-left: 10px;
  margin-right: 10px;
  white-space: pre-wrap;
}

.recommend-container-top-detail-movies-detail {
  margin-top: 4px;
  color: #9b9b9b;
  font-size: 12px;
  font-family: Source Han Sans SC-Normal;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 130px;
  margin-bottom: 15%;
}

.top-collect {
  position: absolute;
  top: 0;
  right: 0;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin: 10px 10px;
  z-index: 100;
}

.top-collect img {
  border-radius: 0;
  width: 100%;
  height: 100%;
}

::-webkit-scrollbar {
  background-color: transparent;
}

/* 整个滚动条 */
::-webkit-scrollbar {
  width: 2px;
  border-radius: 3px;
  background-color: transparent;
  /* 隐藏水平滚动条 */
  height: 0;
}

/* 滚动条轨道 */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* 滚动条滑块 */
::-webkit-scrollbar-thumb {
  background-color: #f1f1f1;
  border-radius: 6px;
}
</style>

<style scoped>
/* PC设备 */
@media only screen and (min-width: 1920px) {
  .genres-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .genres-left-container {
    width: calc(100% - 700px);
    height: 100%;
    margin-top: 30px;
    margin-left: 30px;
  }

  .genres-left-container-top {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
  }

  .genres-left-container-top-title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .genres-left-container-top-title-container img {
    width: 18px;
    height: 18px;
    margin-right: 15px;
  }

  .genres-left-container-top-title-container span {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
  }

  .genres-left-container-top-type-container {
    width: calc(100% -35px);
    margin: 20px 0 0 0px;
    display: flex;
    flex-direction: row;
    overflow: auto;
  }

  .genres-left-container-top-type-container ul {
    list-style-type: none;
    padding: 0;
  }

  .genres-left-container-top-type-container li {
    /* padding: 2px 10px 4px 10px; */
    width: 80px;
    height: 20px;
    border: 1px solid #e5e5e5;
    margin-top: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
  }

  .genres-left-container-top-type-container li.selected {
    background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
    color: white;
    font-family: Source Han Sans SC-Medium;
    border: none;
  }

  .genres-left-container-mid {
    width: calc(100% -35px);
    margin: 95px 0 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .genres-left-container-mid span {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .genres-left-container-bottom {
    width: 100%;
    margin: 70px 0 0 0px;
    display: flex;
    flex-direction: row;
  }

  .genres-left-container-bottom-item {
    width: 35%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .genres-left-container-bottom-item-progress {
    width: 100%;
  }

  .genres-left-container-bottom-item span {
    margin-top: 0px;
    color: #f5f5f5;
    font-size: 16px;
    width: 170px;
    margin-right: 20px;
    text-align: center;
    font-family: Source Han Sans SC-Regular;
  }

  .genres-right-container {
    width: 940px;
    height: 100%;
    margin-top: 35px;
    margin-right: 30px;
  }

  .genres-right-container-top {
    width: 100%;
    height: 10%;
  }

  .genres-right-container-top-type-list {
    display: flex;
    flex-wrap: wrap;
  }

  .genres-right-container-top-type-list span {
    color: white;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
    padding: 3px 15px;
    border: 1px solid #e5e5e5;
    margin-right: 3%;
    border-radius: 28px;
    cursor: pointer;
  }

  .genres-right-container-top .genres-right-container-top-type-selected,
  .genres-right-container-top-type-list
    .genres-right-container-top-type-selected {
    background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
    color: white;
    font-family: Source Han Sans SC-Medium;
    border: none;
  }

  .genres-right-container-bottom {
    width: 100%;
    height: 100%;
  }

  .genres-right-container-bottom-grid-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 20px;
    width: 100%;
    height: 490px;
    padding: 0px;
    margin-top: 20px;
  }

  .genres-right-container-bottom-grid-item {
    height: 100%;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: transform 0.5s ease;
  }

  .genres-right-container-bottom-grid-item:hover {
    transform: translateY(-5px);
  }

  .genres-right-container-bottom-grid-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 20px;
  }

  .genres-right-container-bottom-grid-item:hover::before {
    opacity: 1;
  }

  .genres-right-container-bottom-grid-item:hover
    .recommend-container-top-detail
    span {
    animation: moveUp 0.5s ease forwards;
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  .recommend-container-top-detail {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ); /* 渐变背景 */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .recommend-container-top-detail-movies-title {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    line-height: 1.1;
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-wrap;
  }

  .recommend-container-top-detail-movies-detail {
    margin-top: 4px;
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 130px;
    margin-bottom: 15%;
  }

  .top-collect {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  .top-collect img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>

<style scoped>
/* 移动设备 */
@media only screen and (max-width: 1024px) {
  .genres-main-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
  }

  .genres-left-container {
    width: 52%;
    height: 100%;
    margin-top: 15px;
    margin-left: 15px;
  }

  .genres-left-container-top {
    width: 100%;
    height: 20%;
    display: flex;
    flex-direction: column;
  }

  .genres-left-container-top-title-container {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .genres-left-container-top-title-container img {
    width: 18px;
    height: 18px;
    margin-right: 15px;
  }

  .genres-left-container-top-title-container span {
    color: #f5f5f5;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
  }

  .genres-left-container-top-type-container {
    width: calc(100% -35px);
    margin: 0 0 0 0px;
    display: flex;
    flex-direction: row;
    overflow: auto;
  }

  .genres-left-container-top-type-container ul {
    list-style-type: none;
    padding: 0;
  }

  .genres-left-container-top-type-container li {
    padding: 0 8px 2px 8px;
    /* width: 80px;
  height: 20px; */
    border: 1px solid #e5e5e5;
    margin-top: 10px;
    color: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
  }

  .genres-left-container-top-type-container li.selected {
    background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
    color: white;
    font-family: Source Han Sans SC-Medium;
    font-size: 12px;
    border: none;
  }

  .genres-left-container-mid {
    width: 100%;
    margin: 10px 0 0 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .genres-left-container-mid span {
    color: #a8a8a8;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .genres-left-container-bottom {
    width: calc(100% -35px);
    margin: 20px 0 0 0px;
    display: flex;
    flex-direction: row;
  }

  .genres-left-container-bottom-item {
    width: 37%;
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  .genres-left-container-bottom-item-progress {
    width: 100%;
  }

  .genres-left-container-bottom-item span {
    margin-top: 10px;
    color: #f5f5f5;
    font-size: 12px;
    width: 80px;
    text-align: center;
    white-space: pre-wrap;
    font-family: Source Han Sans SC-Regular;
  }

  .genres-right-container {
    width: 43%;
    height: 100%;
    margin-top: 15px;
    margin-right: 0px;
  }

  .genres-right-container-top {
    width: 100%;
    height: 10%;
  }

  .genres-right-container-top-type-list {
    display: flex;
    flex-wrap: wrap;
  }

  .genres-right-container-top-type-list span {
    color: white;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    white-space: nowrap;
    padding: 0px 10px 1px 10px;
    border: 1px solid #e5e5e5;
    margin-right: 3%;
    border-radius: 28px;
    cursor: pointer;
    margin-bottom: 5px;
  }

  .genres-right-container-top .genres-right-container-top-type-selected,
  .genres-right-container-top-type-list
    .genres-right-container-top-type-selected {
    background: linear-gradient(to right, #ff036a, #d764e0, #6e2ff4, #1a56fe);
    color: white;
    font-family: Source Han Sans SC-Medium;
    border: none;
  }

  .genres-right-container-bottom {
    width: 100%;
    height: 100%;
  }

  .genres-right-container-bottom-grid-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    height: 300px;
    padding: 0px;
    margin-top: 1px;
  }

  .genres-right-container-bottom-grid-item {
    height: 100%;
    width: 110px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    transition: transform 0.5s ease;
  }

  .genres-right-container-bottom-grid-item:hover {
    transform: translateY(-5px);
  }

  .genres-right-container-bottom-grid-item::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 1;
    opacity: 0;
    transition: opacity 0.3s ease;
    border-radius: 20px;
  }

  .genres-right-container-bottom-grid-item:hover::before {
    opacity: 1;
  }

  .genres-right-container-bottom-grid-item:hover
    .recommend-container-top-detail
    span {
    animation: moveUp 0.5s ease forwards;
  }

  @keyframes moveUp {
    0% {
      transform: translateY(0);
    }
    100% {
      transform: translateY(-5px);
    }
  }

  .recommend-container-top-detail {
    width: 100%;
    height: 100%;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    background: linear-gradient(
      to top,
      rgba(0, 0, 0, 1),
      rgba(0, 0, 0, 0.9),
      rgba(0, 0, 0, 0.8),
      rgba(0, 0, 0, 0.6),
      rgba(0, 0, 0, 0.4),
      rgba(0, 0, 0, 0.3),
      rgba(0, 0, 0, 0.2),
      rgba(0, 0, 0, 0.1),
      rgba(0, 0, 0, 0)
    ); /* 渐变背景 */
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
  }

  .recommend-container-top-detail-movies-title {
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Medium;
    line-height: 1.1;
    margin-left: 10px;
    margin-right: 10px;
    white-space: pre-wrap;
  }

  .recommend-container-top-detail-movies-detail {
    margin-top: 4px;
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1.3;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 95px;
    margin-bottom: 15%;
  }

  .top-collect {
    position: absolute;
    top: 0;
    right: 0;
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin: 10px 10px;
    z-index: 100;
  }

  .top-collect img {
    border-radius: 0;
    width: 100%;
    height: 100%;
  }

  ::-webkit-scrollbar {
    background-color: transparent;
  }

  /* 整个滚动条 */
  ::-webkit-scrollbar {
    width: 2px;
    border-radius: 3px;
    background-color: transparent;
    /* 隐藏水平滚动条 */
    height: 0;
  }

  /* 滚动条轨道 */
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }

  /* 滚动条滑块 */
  ::-webkit-scrollbar-thumb {
    background-color: #f1f1f1;
    border-radius: 6px;
  }
}
</style>