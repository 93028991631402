<template>
  <div class="main-container">
    <div class="nav-container">
      <div class="nav-container-top">
        <div class="nav-item-mine"
             @click="goToMinePage">
          <div class="avatar-container-all">

            <div class="avatar-container">
              <img :class="{'avatar-img': isLoggedIn, 'avatar-img-notlogin': !isLoggedIn,}"
                   :src="avatar"
                   alt="" />
            </div>
            <img v-if="isLoggedIn"
                 src="~@/assets/nav/mine-edit.png"
                 class="edit"
                 alt="" />
          </div>
          <div :class="{'nickname-container': isLoggedIn, 'nickname-container-notlogin': !isLoggedIn,}">
            <span :class="{'nickname': isLoggedIn, 'nickname-notlogin': !isLoggedIn,}">
              {{
						isLoggedIn ? username : $t('login')
					  }}
            </span>
            <div v-show="isLoggedIn && userInfoIncomplete"
                 class="nickname-star">
              *
            </div>
          </div>

        </div>
      </div>
      <div class="nav-container-middle">
        <nav>
          <router-link v-for="(item, index) in navItemList"
                       :to="item.path"
                       :class="{ active: index === activeIndex }"
                       @click.native="setActiveIndex(index)"
                       :data-image="item.image"
                       :data-active-image="item.activeImage"
                       :key="`nav-item-${index}`">
            <div class="nav-item">
              <img :src="
									index === activeIndex
										? item.activeImage
										: item.image
								"
                   :style="{
                    width: index === activeIndex ?  (isMobile ? '30px' : '40px') : (isMobile ? '30px' : '40px'),
                    height: index === activeIndex ? isMobile ? '30px' : '40px' : (isMobile ? '30px' : '40px')
                    }" />
              <span>{{ item.text }}</span>
            </div>
          </router-link>
        </nav>
      </div>
      <div class="nav-container-bottom">
        <div class="nav-item-logout"
             @click="goToLoginPage">
          <img src="@/assets/nav/logout.png"
               alt=""
               v-show="!isMobile" />
          <span>{{ isLoggedIn ?  $t('logout') :  $t('login') }} </span>
        </div>
      </div>
      <div class="slider-line"></div>
    </div>
    <div class="child-container">
      <!-- <keep-alive> -->
      <!-- <router-view /> -->
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <!-- </keep-alive> -->
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { getPresignedUrlApi } from '@/api/homeApi.js'
import { userInfoApi } from '@/api/userApi.js'
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import { recordUserRegisterActionApi, recordUserActionApi } from '@/api/userLogApi.js'

export default {
  name: 'VerticalNavigation',
  data () {
    return {
      activeIndex: -1,
      top: 0,
      // 导航栏列表
      navItemList: [
      ],
      avatar: require('@/assets/nav/mine-not-login-default.jpg'),
      username: '',
      isMobile: false,
      languageType: "",
      isMobile: false,
      userInfoIncomplete: false,
      country: null,
      timer: null,
      homePageHasLoad: false,
      discoverPageHasLoad: false
    }
  },

  beforeRouteUpdate (to, from, next) {
    next();
  },

  created () {

    // 获取网页来源
    const referer = document.referrer;
    console.log("输出网页来源 - referer :", referer);
    if (referer) {
      const interlibUserReferer = localStorage.getItem('interlibUserReferer')
      if (!interlibUserReferer) {
        localStorage.setItem('interlibUserReferer', referer)
      }
    } else {
      const location_href = window.location.href;
      console.log("输出网页来源 - location_href:", location_href);
      localStorage.setItem('interlibUserReferer', location_href)
    }

    this.getVisitorId()
    this.getAllInfo()
    // 创建一个 MediaQueryList 对象，传入媒体查询条件
    var mediaQuery = window.matchMedia('(max-width: 1024px)');
    // 检查是否匹配指定的媒体查询条件
    if (mediaQuery.matches) {
      // 在此处执行适应小屏幕的代码
      console.log('屏幕宽度小于等于 1024px');
      this.isMobile = true;
    } else {
      // 在此处执行适应大屏幕的代码
      console.log('屏幕宽度大于 1024px');
      this.isMobile = false;
    }

    const location_href = window.location.href;

    const regex = /projectId=(\d+)/;
    const match = location_href.match(regex);
    const projectId = match ? match[1] : null;

    // 获取projectId参数
    console.log("projectId:", projectId);

    console.log('location_href:', location_href);

    // 解析 URL 参数
    const urlReferer = new URL(location_href);
    const params = {};
    for (const [key, value] of urlReferer.searchParams.entries()) {
      params[key] = value;
    }

    console.log("提取到的参数:", params);

    // 存储参数
    if (Object.keys(params).length > 0) {
      localStorage.setItem('interlibUserRefererParams', JSON.stringify(params));
    }

    if (location_href.includes('/zh') || location_href.includes('/cn')) {
      // URL中包含zh或cn
      console.log("URL中包含'zh'或'cn'");
      localStorage.setItem('locationLanguageType', 'zh')
      this.$i18n.locale = 'zh';
    } else if (location_href.includes('/en')) {
      // URL中不包含zh或cn
      console.log("URL中包含'en'");
      localStorage.setItem('locationLanguageType', 'en')
      this.$i18n.locale = 'en';
    } else {
      if (!projectId) {
        const newUrl = window.location.origin + '/en' + window.location.pathname;
        localStorage.setItem('locationLanguageType', 'en')
        this.$i18n.locale = 'en';
        window.location.href = newUrl;
      }
    }

    this.reloadLocationLanguageData()

    // 获取域名
    const url = new URL(window.location.href);
    const domain = url.hostname;
    console.log("url:", url);

    console.log("domain:", domain);
    if (projectId) {
      // 判断域名是否符合预期
      if (domain === 'www.interlib.asia' && projectId) {
        // 线上地址
        // 构建新的路由
        const newUrl = `https://www.interlib.asia/${this.languageType === 'zh' ? 'cn' : 'en'}/${projectId}-InterLib`;
        // 访问新的路由
        window.location.href = newUrl;
      } else if (domain === 'qa.interlib.asia' && projectId) {
        // 测试地址
        // 构建新的路由
        const newUrl = `http://qa.interlib.asia/${this.languageType === 'zh' ? 'cn' : 'en'}/${projectId}-InterLib`;
        // 访问新的路由
        window.location.href = newUrl;
      } else if (domain === 'localhost' && projectId) {
        // 本地地址
        // 构建新的路由
        const newUrl = `http://localhost:8085/${this.languageType === 'zh' ? 'cn' : 'en'}/${projectId}-InterLib`;
        // 访问新的路由
        window.location.href = newUrl;
      }
      localStorage.setItem('currentRoute', `${projectId}`);
    } else {
      const currentRoute = this.$route.path;
      console.log("currentRoute:", currentRoute);
      const storedRoute = localStorage.getItem('currentRoute');
      console.log("storedRoute:", storedRoute);

      if (storedRoute && storedRoute !== currentRoute) {
        // this.$router.push(storedRoute);
      }
      console.log("storedRoute:", storedRoute);

      if (storedRoute && storedRoute.includes("collect")) {
        this.activeIndex = 3;
      } else if (storedRoute && storedRoute.includes("-")) {
        this.activeIndex = 0;
      }
    }

    // 监听路由变化
    this.$router.afterEach((to, from) => {
      localStorage.setItem('currentRoute', to.path);
      localStorage.setItem('fromRoute', from.path);
      const index = this.navItemList.findIndex(item => item.path === to.path);
      if (index !== -1) {
        this.setActiveIndex(index);
      }
      console.log("切换到index:", index);
      if (index === 0 && this.homePageHasLoad === true) {
        this.recordUserActionApiHomePageData('Home')
      }
      if (index === 1 && this.discoverPageHasLoad === true) {
        this.recordUserActionApiHomePageData('Discover')
      }
      if (index === 0 && this.homePageHasLoad === false) {
        this.homePageHasLoad = true
      }
      if (index === 1 && this.discoverPageHasLoad === false) {
        this.discoverPageHasLoad = true
      }
    });

    this.loadAvatar()

    if (!this.isLoggedIn) {
      const joinStorePage = localStorage.getItem('joinStorePage')
      if (!joinStorePage) {
        this.getRecordUserRegisterActionData()
      }
    }
  },
  mounted () {
    window.addEventListener("popstate", this.handlePopstate);
    // 判断是否需要跳转到首页
    const lastRoute = sessionStorage.getItem('lastRoute')
    if (lastRoute && lastRoute !== '/') {
      this.$router.push('/')
    }
    window.onresize = function () { }
    if (this.isNumberBrowser()) { // 判断是否是360浏览器
      console.log('浏览器：360浏览器');
      localStorage.setItem('interlibUserBrowserInfo', '360浏览器')

    } else {
      console.log('浏览器：其他浏览器');
      localStorage.setItem('interlibUserBrowserInfo', '')
    }
  },

  beforeDestroy () {
    window.removeEventListener("popstate", this.handlePopstate);
  },

  computed: {
    ...mapState('auth', ['isLoggedIn', 'userInfo'])
  },

  methods: {
    ...mapActions('dialog', ['showLoginDialog']),
    ...mapActions('auth', ['logout', 'setShowPersonPreference']),

    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
        localStorage.setItem('locationLanguageType', 'zh')
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }

      this.navItemList = [
        {
          text: this.$t('nav_home'),
          path: language == 'zh' ? '/cn/' : '/en/',
          image: require('@/assets/nav/home-normal.png'),
          activeImage: require('@/assets/nav/home-active.png')
        },
        {
          text: this.$t('nav_discover'),
          path: language == 'zh' ? '/cn/discover' : '/en/discover',
          image: require('@/assets/nav/discover-normal.png'),
          activeImage: require('@/assets/nav/discover-active.png')
        },
        // {
        //   text: 'Message',
        //   path: '/message',
        //   image: require('@/assets/nav/message-normal.png'),
        //   activeImage: require('@/assets/nav/message-active.png')
        // },
        {
          text: this.$t('nav_collect'),
          path: language == 'zh' ? '/cn/collect' : '/en/collect',
          image: require('@/assets/nav/collect-normal.png'),
          activeImage: require('@/assets/nav/collect-active.png')
        },
        {
          text: this.$t('tools'),
          path: language == 'zh' ? '/cn/tools' : '/en/tools',
          image: require('@/assets/nav/tools-normal.png'),
          activeImage: require('@/assets/nav/tools-active.png')
        },

        // {
        //   text: 'DEV',
        //   path: language == 'zh' ? '/cn/DEV' : '/en/DEV',
        //   image: require('@/assets/nav/home-normal.png'),
        //   activeImage: require('@/assets/nav/home-active.png')
        // }
      ]
    },

    //获取用户信息
    getUserInfoData () {
      userInfoApi()
        .then(async (res) => {
          if (res.data.code === 200) {
            this.username = res.data.data.username

            localStorage.setItem('interlibId', res.data.data.id)

            //国家
            const country = res.data.data.country ? res.data.data.country : '';
            //公司名
            const company = res.data.data.company ? res.data.data.company : '';
            //公司性质
            const companyNature = res.data.data.companyNature ? res.data.data.companyNature : '';

            if (country.length == 0 || company.length == 0 || companyNature.length == 0) {
              this.userInfoIncomplete = true;
            } else {
              this.userInfoIncomplete = false;
            }

            if (res.data.data.avatar) {
              try {
                const imageRes = await getPresignedUrlApi(res.data.data.avatar);
                if (imageRes.data.code === 200) {
                  this.avatar = imageRes.data.data.url || require('@/assets/nav/mine-login-default.jpg')
                }
              } catch (error) {
              }
            } else {
              this.avatar = require('@/assets/nav/mine-login-default.jpg')
            }
            if (res.data.data.hasPreference === 0 && !this.isMobile) {
              this.setShowPersonPreference(true)
            }
          }
        })
        .catch((error) => {
          console.error(error)
        })
    },

    //记录用户注册行为日志
    getRecordUserRegisterActionData () {
      recordUserRegisterActionApi('1')
        .then(async (res) => {
          if (res.data.code === 200) {
          }
        })
        .catch((error) => {
        })
    },

    async loadAvatar () {
      if (this.isLoggedIn) {
        this.getUserInfoData()
      } else {
        this.avatar = require('@/assets/nav/mine-not-login-default.jpg')
        localStorage.setItem('interlibId', "0")
      }
    },

    handlePopstate () {
      const fromRoute = localStorage.getItem('fromRoute');
      console.log("handlePopstate检查条件并更新:", fromRoute);

      if (fromRoute.includes("collect")) {
        this.activeIndex = 3;
      } else if (fromRoute.includes("detail")) {
        this.activeIndex = 0;
      } else if (fromRoute.includes("mine")) {
        this.activeIndex = -2;
      }
    },
    goToMinePage () {
      if (this.isLoggedIn) {
        this.activeIndex = -2;
        const location_href = window.location.href;
        if (!location_href.includes('mine')) {
          this.$router.push({ name: 'language-mine', params: { language: this.languageType === 'zh' ? 'cn' : 'en' } })
        }
      } else {
        this.showLoginDialog()
      }
    },
    goToLoginPage () {
      if (this.isLoggedIn) {
        this.logout()
        this.loadAvatar()
        window.location.reload()
      }
      this.showLoginDialog()
    },

    // 设置选中的导航按钮
    setActiveIndex (index) {
      if (index == 2 && this.isLoggedIn == false) {
        this.showLoginDialog()
      }
      if (index !== this.activeIndex) {
        this.activeIndex = index

        // 切换高亮图片
        const navItems = document.querySelectorAll('nav a')
        const activeImage =
          navItems[index].getAttribute('data-active-image')
        navItems.forEach((item, i) => {
          const image = item.querySelector('img')
          if (i === index) {
            // 选中状态使用高亮图片
            image.src = activeImage
          } else {
            // 非选中状态使用普通图片
            image.src = item.getAttribute('data-image')
          }
        })

        // 添加抖动和放大效果
        const images = document.querySelectorAll('.nav-container-middle img');
        images.forEach((img, idx) => {
          if (idx === index) {
            img.classList.add('shake');
            setTimeout(() => {
              img.classList.remove('shake');
            }, 1000); // 抖动动画持续时间为0.5s * 2 = 1s
          }
        });
      }
    },
    showBackground () {

    },
    hideBackground () {

    },
    getReferrer () {
      // 获取网页来源
      const referer = document.referrer;
      console.log("输出网页来源:", referer);
      if (referer) {
        // 解析 URL 参数
        const url = new URL(referer);
        const params = {};
        for (const [key, value] of url.searchParams.entries()) {
          params[key] = value;
        }

        console.log("提取到的参数:", params);
        // 存储参数
        if (Object.keys(params).length > 0) {
          localStorage.setItem('interlibUserRefererParams', JSON.stringify(params));
        } else {
          localStorage.setItem('interlibUserRefererParams', '');
        }
      }
    },
    getDeviceInfo () {
      // 获取设备信息
      const deviceInfo = navigator.userAgent;
      console.log("输出设备信息:", deviceInfo);
      localStorage.setItem('interlibUserDeviceInfo', deviceInfo)
    },
    getBrowserInfo () {
      // 获取浏览器信息
      const browserInfo = navigator.userAgent;
      console.log("输出浏览器信息:", browserInfo);

    },
    getScreenInfo () {
      // 获取屏幕信息
      const screenInfo = window.screen;
      console.log("输出屏幕信息:", screenInfo.width, screenInfo.height);
      localStorage.setItem('interlibUserScreenInfo', `${screenInfo.width}x${screenInfo.height}`)
    },
    // 获取设备或浏览器唯一标识
    async getVisitorId () {
      const fp = await FingerprintJS.load()
      const result = await fp.get()
      const visitorId = result.visitorId
      console.log('唯一标识', visitorId)
      if (visitorId.length > 16) {
        const substrResult = visitorId.substr(0, 16);
        console.log(substrResult);
        localStorage.setItem('interlibUserVisitorId', substrResult)
      } else {
        localStorage.setItem('interlibUserVisitorId', visitorId)
      }
    },
    getCity () {
      axios
        .get('http://ip-api.com/json', { withCredentials: false })
        .then(response => {
          this.country = response.data.country;
          console.log("输出国家信息:", this.country);
          localStorage.setItem('interlibUserCountry', this.country)
        })
        .catch(error => {
          this.error = error.message;
        });
    },
    getSessionToken () {
      // 定义字符集
      const charSet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
      // 生成随机字符串
      let randomStr = '';
      for (let i = 0; i < 28; i++) {
        if (i % 4 === 0 && i !== 0) {
          // 每隔4个字符加一个横线
          randomStr += '-';
        } else {
          // 否则从字符集中随机选择一个字符
          randomStr += charSet.charAt(Math.floor(Math.random() * charSet.length));
        }
      }
      console.log(randomStr); // 输出类似的字符串：IU8Y-IN79-INSE-Pwke-JE0B-SSTU-IPwd
      localStorage.setItem('interlibUserSessionToken', randomStr)
      const timestamp = new Date().getTime();
      console.log(timestamp);
      localStorage.setItem('interlibUserSessionTokenTime', timestamp)
      // 45分钟后再次执行
      setTimeout(() => {
        this.getSessionToken();
      }, 2700000);
    },
    handleGetSessionToken () {
      const sessionToken = localStorage.getItem('interlibUserSessionToken')
      if (!sessionToken) {
        this.getSessionToken()
      }

      const sessionTokenTime = localStorage.getItem('interlibUserSessionTokenTime')
      const currentTime = new Date().getTime();
      console.log(currentTime);

      const timeDifference = currentTime - sessionTokenTime;
      const minutesDifference = Math.floor(timeDifference / (1000 * 60));
      console.log(minutesDifference);
      if (minutesDifference > 4) {
        console.log("时间戳相差大于45分钟");
        this.getSessionToken()
      } else {
        console.log("时间戳相差不大于45分钟");
      }
    },
    getAllInfo () {
      this.getReferrer()
      this.getDeviceInfo()
      this.getBrowserInfo()
      this.getScreenInfo()
      this.getCity()
      this.handleGetSessionToken()
    },
    // 判断是否是360浏览器
    isNumberBrowser () {
      if (navigator.userAgent.indexOf('Safari') != -1) {
        return this.checkChromeForNum();
      } else {
        return this.checkIeForNum();
      }
    },
    checkIeForNum () {
      return ((navigator.msPointerEnabled == undefined ? true : navigator.msPointerEnabled) &&
        (navigator.msDoNotTrack == 1 || window.doNotTrack == 1) &&
        ((Number(window.screenX) ? (window.screenLeft - window.screenX != 8) : false) ||
          ((navigator.userAgent.indexOf('MSIE 7.0') != -1 || navigator.userAgent.indexOf('MSIE 8.0') != -1) && console.count == undefined)));
    },
    checkChromeForNum () {
      const uas = navigator.userAgent.split(' ');
      const result = false;
      if (uas[uas.length - 1].indexOf('Safari') == -1) {
        return result;
      }
      for (var key in navigator.plugins) {
        if (navigator.plugins[key].filename == 'np-mswmp.dll') {
          return !result;
        }
      }
      return result;
    },

    //记录数据
    async recordUserActionApiHomePageData (page) {
      try {
        const res = recordUserActionApi(page, '0');
      } catch (error) {
        // 错误处理
      }
    },
  }
}
</script>

<style scoped>
.main-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: row;
  background-color: black;
  background-image: url("~@/assets/bg/interlib-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
}

.nav-container {
  display: flex;
  width: 120px;
  height: 100%;
  position: relative;
  flex-direction: column;
  justify-content: center;
  z-index: 9999;
}

.nav-container-top {
  flex-grow: 2;
  display: flex;
  justify-content: end;
  padding-left: 13px;
}

.nav-container-middle {
  flex-grow: 3;
  display: flex;
  padding-left: 8px;
}

.nav-container-bottom {
  flex-grow: 2;
  display: flex;
  justify-content: start;
}

.child-container {
  flex-grow: 1;
  height: 100vh;
  margin-top: 0px;
  overflow-y: scroll;
  overflow-x: hidden;
}

nav {
  display: flex;
  width: 150px;
  height: 100%;
  flex-direction: column;
  justify-content: center;
}

.nav-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px;
  width: 100%;
  margin-bottom: calc(10px + 3vh);
  cursor: pointer;
}

.nav-item:hover {
  color: #fff;
}

.nav-item img {
  margin-right: 10px;
  transition: transform 0.2s ease-in-out;
  margin-bottom: -5px;
}

.nav-item img.shake {
  animation: shake 0.5s ease-in-out;
}

@keyframes shake {
  0% {
    transform: scale(1) rotate(0deg);
  }
  50% {
    transform: scale(1.2) rotate(7deg);
  }
  100% {
    transform: scale(1) rotate(0deg);
  }
}

.nav-item span {
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
}

nav a {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #999;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

nav a span {
  width: 100%;
  text-align: center;
  margin-top: 5px;
  font-size: 14px;
  margin-right: 10px;
  font-family: Source Han Sans SC-Regular;
}

nav a.active {
  color: #fff;
}

.slider-line {
  position: absolute;
  width: 0.5px;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.2);

  right: 0;
  margin-right: 2.5px;
}

.nav-item-mine {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #999;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.avatar-img {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
}

.avatar-img-notlogin {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-bottom: 5px;
}
.nav-item-mine span {
  font-size: 20px;
  color: #fff;
  font-family: Source Han Sans SC-Regular;
}

.nav-item-mine .edit {
  width: 15px;
  height: 15px;
  border-radius: 0;
}

.nickname-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-right: 15px;
}

.nickname-container-notlogin {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  align-content: center;
  /* margin-right: 15px; */
}

.nickname-star {
  margin-top: 7px;
  margin-left: 3px;
  color: red;
  font-size: 15px;
  font-family: Source Han Sans SC-Regular;
}

.nickname {
  max-width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.nickname-notlogin {
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;
}

.nav-item-mine img {
  width: 40px;
  height: 40px;
  align-items: center;
}

.avatar-container-all {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: center;
  align-content: center;
}

.avatar-container {
  position: relative;
  width: 40px;
  height: 40px;
}

.avatar-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.3s ease;
  border-radius: 20px;
  z-index: 1;
}

.avatar-container:hover::after {
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 20px;
}

.nav-item-mine span {
  margin-top: 5px;
  color: #fff;
  font-size: 15px;
  font-family: Source Han Sans SC-Regular;
}

.nav-item-logout {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: #999;
  text-decoration: none;
  transition: color 0.2s ease-in-out;
}

.nav-item-logout img {
  width: 20px;
  height: 20px;
  align-items: center;
  margin-right: 10px;
}

.nav-item-logout span {
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
}
</style>

<style scoped>
@media only screen and (max-width: 1024px) {
  .main-container {
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: row;
    background-color: black;
    background-image: url("~@/assets/bg/interlib-bg.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
  }

  .nav-container {
    display: flex;
    width: 90px;
    height: 100%;
    position: relative;
    flex-direction: column;
    justify-content: center;
    z-index: 9999;
  }

  .nav-container-top {
    flex-grow: 2;
    display: flex;
    justify-content: end;
  }

  .nav-container-middle {
    flex-grow: 3;
    display: flex;
  }

  .nav-container-bottom {
    flex-grow: 2;
    display: flex;
    justify-content: start;
  }

  .child-container {
    flex-grow: 1;
    height: 100vh;
    margin-top: 0px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  nav {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
  }

  .nav-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0px;
    width: 100%;
    margin-bottom: calc(10px + 1vh);
  }

  .nav-item img {
    margin-right: 10px;
    transition: transform 0.2s ease-in-out;
    margin-bottom: 0px;
  }

  .nav-item img.shake {
    animation: shake 0.5s ease-in-out;
  }

  @keyframes shake {
    0% {
      transform: scale(1) rotate(0deg);
    }
    50% {
      transform: scale(1.2) rotate(7deg);
    }
    100% {
      transform: scale(1) rotate(0deg);
    }
  }

  .nav-item span {
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  nav a {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #999;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  }

  nav a span {
    width: 100%;
    text-align: center;
    margin-top: 0px;
    font-size: 14px;
    margin-right: 10px;
    font-family: Source Han Sans SC-Regular;
  }

  nav a.active {
    color: #fff;
  }

  .slider-line {
    position: absolute;
    width: 0.5px;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.2);

    right: 0;
    margin-right: 2.5px;
  }

  .nav-item-mine {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #999;
    text-decoration: none;
    transition: color 0.2s ease-in-out;
  }

  .nav-item-mine img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-bottom: 5px;
  }

  .avatar-container-all {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: center;
    align-content: center;
  }

  .avatar-container {
    position: relative;
    width: 33px;
    height: 33px;
  }

  .avatar-container::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0);
    transition: background-color 0.3s ease;
    border-radius: 20px;
    z-index: 1;
  }

  .avatar-container:hover::after {
    background-color: rgba(0, 0, 0, 0);
    border-radius: 20px;
  }

  .nav-item-mine span {
    font-size: 20px;
    color: #fff;
    font-family: Source Han Sans SC-Regular;
  }

  .nav-item-mine .edit {
    width: 10px;
    height: 10px;
    border-radius: 0;
  }

  .nickname-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    /* margin-left: 3px; */
    height: 15px;
  }

  .nickname-container-notlogin {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-top: -5px;
  }

  .nickname-star {
    margin-top: 7px;
    margin-left: 3px;
    color: red;
    font-size: 15px;
    font-family: Source Han Sans SC-Regular;
  }

  .nickname {
    max-width: 50px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
    margin-left: 1px;
  }

  .nickname-notlogin {
    max-width: 70px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
  }

  .nav-item-mine img {
    width: 30px;
    height: 30px;
    align-items: center;
  }

  .nav-item-mine span {
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .nav-item-logout {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: #999;
    /* text-decoration: none; */
    /* transition: color 0.2s ease-in-out; */
    align-content: center;
  }

  .nav-item-logout img {
    width: 20px;
    height: 20px;
    align-items: center;
    margin-left: 8px;
    /* margin-right: 10px; */
  }

  .nav-item-logout span {
    font-size: 14px;
    font-family: Source Han Sans SC-Regular;
    display: none;
  }
}
</style>