/*
 * Discover API
 */

import request from '@/utils/request';

// 类别接口
export function categoryListApi() {
    return request({
      url: '/api/interlib-content/category/type/list',
      method: 'GET',
    });
}

// 题材接口
export function genresListApi(params) {
    return request({
      url: '/api/interlib-content/category/genres/list',
      method: 'GET',
      params
    });
}

// 年份接口
export function yearsListApi() {
    return request({
      url: '/api/interlib-content/category/years/list',
      method: 'GET',
    });
}

//发现页-列表接口
export function hotApi(data) {
    return request({
      url: '/api/interlib-content/content/hot',
      method: 'POST',
      data
    });
}

// 年代接口
export function agesListApi() {
    return request({
      url: '/api/interlib-content/category/era',
      method: 'GET',
    });
}

// 搜索命中回调接口
export function searchResultClicBackApi(params) {
    return request({
      url: '/api/interlib-content/content/hit_search',
      method: 'GET',
      params
    });
}


