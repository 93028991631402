/*
 * 用户API
 */

import request from '@/utils/request';

//登录接口
export function loginApi(data) {
    return request({
        url: '/api/interlib-user/user/login',
        method: 'POST',
        data,
    });
}

//获取验证码接口
export function sendCodeApi(params) {
    return request({
        url: '/api/interlib-user/user/sendCode',
        method: 'GET',
        params,
    });
}

//忘记密码接口
export function forgetPasswordApi(data) {
    return request({
        url: '/api/interlib-user/user/forget/password',
        method: 'PUT',
        data,
    });
}

//用户国家接口 groupCode = user_country
//公司类型接口 groupCode = company_nature
export function sysDictInfoApi(params) {
    return request({
        url: '/api/interlib-sys/sys/dict',
        method: 'GET',
        params,
    });
}

//注册接口
export function registerApi(data) {
    return request({
        url: '/api/interlib-user/user',
        method: 'POST',
        data,
    });
}

//获取用户信息
export function userInfoApi() {
    return request({
        url: '/api/interlib-user/user/info',
        method: 'GET',
    });
}

//修改基本信息接口
export function saveUserInfoApi(data) {
    return request({
        url: '/api/interlib-user/user/saveUserInfo',
        method: 'PUT',
        data,
    });
}

//修改密码接口
export function changePasswordApi(data) {
    return request({
        url: '/api/interlib-user/user/forget/password',
        method: 'PUT',
        data,
    });
}

//个人偏好接口
export function allPreferenceListApi(data) {
    return request({
        url: '/api/interlib-content/preference',
        method: 'PUT',
        data,
    });
}

//偏好标签接口
export function userPreferenceApi() {
    return request({
        url: '/api/interlib-content/preference',
        method: 'GET',
    });
}

//保存头像接口
export function saveAvatarApi(data) {
    return request({
        url: `/api/interlib-user/user/avatar/${data}`,
        method: 'PUT',
    });
}