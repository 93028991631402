<template>
  <el-select v-model="selectedValue"
             :placeholder="placeholder"
             filterable
             clearable
             @change="handleChange">
    <el-option v-for="item in items"
               :key="item.id"
               :label="languageType == 'en' ? item.nameEn : item.name"
               :value="item.id"></el-option>
  </el-select>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      default: () => []
    },
    placeholder: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      selectedValue: '',
      languageType: "",
    };
  },
  methods: {
    handleChange (value) {
      // 将选中的数据通过事件返回给父视图
      this.$emit('selected', value, this.type);
    },
    reloadLocationLanguageData () {
      // 检查本地存储中的语言类型
      const language = localStorage.getItem('locationLanguageType')

      if (language == 'zh') {
        this.languageType = 'zh'
        this.$i18n.locale = 'zh';
      } else {
        localStorage.setItem('locationLanguageType', 'en')
        this.languageType = 'en'
        this.$i18n.locale = 'en';
      }
    },
  },
  created () {
    this.reloadLocationLanguageData()
    this.displayItems = this.items;
  }
};
</script>


<style lang="less" scoped>
.el-select ::v-deep .el-input__inner {
  border-radius: 16px !important;
  border-color: transparent !important;
  border: 0.5px solid #fff;
  height: 40px;
  padding: 0px 15px 2px 15px;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  border: 0.5px solid #fff !important;
  line-height: 1;
}

::v-deep .el-input__icon {
  line-height: 40px;
}

.el-input--suffix .el-input__inner {
  border: 0.5px solid #fff !important;
  line-height: 1;
}

.el-input__inner:focus {
  border: 0.5px solid #fff !important;
}

.el-select {
  font-size: 14px;
  font-family: Source Han Sans SC-Regular;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.el-input input::placeholder {
  color: #9b9b9b;
  font-size: 16px;
  font-family: Source Han Sans SC-Regular;
}

.el-select-dropdown__item.selected {
  // background-color: rgba(26, 86, 254, 0.2);
  background-color: rgba(155, 155, 155, 0.2);
  font-size: 14px;
  color: #fff;
  font-family: Source Han Sans SC-Regular;
}

.el-select-dropdown__item {
  font-size: 14px;
  color: #9b9b9b;
  display: flex;
  align-items: center;
  font-family: Source Han Sans SC-Regular;
  background-color: transparent;
}
</style>

<style>
.el-select-dropdown {
  border: 0.5px solid #fff;
  background-image: url("@/assets/bg/input-bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: top top;
  border-radius: 12px;
  object-fit: cover;
}

.el-popper .popper__arrow,
.el-popper .popper__arrow::after {
  border-style: none;
}

.el-popper[x-placement^="bottom"] {
  margin-top: 0px;
}
</style>


<style lang="less" scoped>
@media only screen and (max-width: 1024px) {
  .el-select ::v-deep .el-input__inner {
    border-radius: 16px !important;
    border-color: transparent !important;
    border: 0.5px solid #fff;
    height: 40px;
    padding: 0px 15px 2px 15px;
    background-color: transparent;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    border: 0.5px solid #fff !important;
    line-height: 1;
  }

  ::v-deep .el-input__icon {
    line-height: 40px;
  }

  .el-input--suffix .el-input__inner {
    border: 0.5px solid #fff !important;
    line-height: 1;
  }

  .el-input__inner:focus {
    border: 0.5px solid #fff !important;
  }

  .el-select {
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
    overflow: hidden;
    display: flex;
    align-items: center;
  }

  .el-input input::placeholder {
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Regular;
  }

  .el-select-dropdown__item.selected {
    // background-color: rgba(26, 86, 254, 0.2);
    background-color: rgba(155, 155, 155, 0.2);
    font-size: 12px;
    color: #fff;
    font-family: Source Han Sans SC-Regular;
  }

  .el-select-dropdown__item {
    font-size: 12px;
    color: #9b9b9b;
    display: flex;
    align-items: center;
    font-family: Source Han Sans SC-Regular;
    background-color: transparent;
  }
}
</style>
