// store/deatilEra.js

const state = {
  eraId: null, // 跳转详情页后使用的era
};

const mutations = {
  SET_DEATIL_ERA_VISIBLE(state, id) {
    state.eraId = id;
  }
};

const actions = {
  setDeatilEra({ commit }, id) {
    commit('SET_DEATIL_ERA_VISIBLE', id);
  },
 removeDeatilEra({ commit }) {
    commit('SET_DEATIL_ERA_VISIBLE', null);
  },
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
};
