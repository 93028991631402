/*
 * 店铺Store API
 */

import request from '@/utils/request';


// 店铺-片单列表接口
export function storeFilmListApi(data) {
    return request({
      url: '/api/interlib-content/shop/img_text_film_list',
      method: 'POST',
      data
    });
}