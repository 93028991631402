<template>
  <div class="main-container">
    <el-input clearable
              :placeholder="placeholder"
              v-model="inputValue"
              @input="handleInput">
    </el-input>

    <div v-if="countdown === 0"
         class="countdown"
         @click="handleGetCode">
      {{ countdown > 0 ? countdown + 's' : $t('mine_verfication_code') }}
    </div>
    <div v-else
         class="countdown-disable">
      {{ countdown > 0 ? countdown + 's' : $t('mine_verfication_code') }}
    </div>

  </div>

</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: ''
    },
  },
  data () {
    return {
      inputValue: this.value,
      countdown: 0
    };
  },
  watch: {
    value (newValue) {
      this.inputValue = newValue;
    },
  },
  methods: {
    handleInput (value) {
      this.inputValue = value;
      this.$emit('input', value);
    },
    handleGetCode () {
      const email = localStorage.getItem('signUpInputEmail');
      console.log("signUpInputEmail:", email);
      console.log("signUpInputEmail.length:", email.length);

      if (email.length === 0) {
        this.$emit('noEmail');
        return
      }
      if (this.countdown === 0) {
        this.countdown = 59;
        const timer = setInterval(() => {
          if (this.countdown > 0) {
            this.countdown--;
          } else {
            clearInterval(timer);
          }
        }, 1000);
        this.$emit('get-code', this.countdown); // 触发自定义事件，并传递倒计时时间给父视图
      }
    }
  },
};
</script>

<style lang="less" scoped>
.main-container {
  display: flex;
  flex-direction: row;
  border: 0.5px solid #fff;
  border-radius: 16px;
  height: 40px;
}

::v-deep .el-input__inner {
  border-radius: 16px !important;
  border: 0.5px solid transparent;
  height: 40px !important;
  padding: 0px 15px 2px 15px;
  background-color: transparent;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  line-height: 1;
}

::v-deep .el-input__icon {
  line-height: 40px !important;
}

.el-input--suffix .el-input__inner {
  border: 0.5px solid transparent !important;
  line-height: 1;
}

.el-input__inner:focus {
  border: 0.5px solid transparent !important;
}

.el-input input::placeholder {
  color: #9b9b9b;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
}

.countdown {
  width: 80px;
  height: 22px;
  margin-top: 9px;
  margin-right: 9px;
  color: #fff;
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  background-image: linear-gradient(
    to right,
    #ff036a,
    #d764e0,
    #6e2ff4,
    #1a56fe
  );
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

.countdown-disable {
  width: 80px;
  height: 22px;
  margin-top: 9px;
  margin-right: 9px;
  color: rgb(146, 146, 146);
  font-size: 14px;
  font-family: Source Han Sans SC-Normal;
  background-color: #fff;
  border-radius: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>


<style lang="less" scoped>
@media only screen and (max-width: 1024px) {
  .main-container {
    display: flex;
    flex-direction: row;
    border: 0.5px solid #fff;
    border-radius: 16px;
    height: 40px;
  }

  ::v-deep .el-input__inner {
    border-radius: 16px !important;
    border: 0.5px solid transparent;
    height: 40px !important;
    padding: 0px 15px 2px 15px;
    background-color: transparent;
    color: #fff;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    line-height: 1;
  }

  ::v-deep .el-input__icon {
    line-height: 40px !important;
  }

  .el-input--suffix .el-input__inner {
    border: 0.5px solid transparent !important;
    line-height: 1;
  }

  .el-input__inner:focus {
    border: 0.5px solid transparent !important;
  }

  .el-input input::placeholder {
    color: #9b9b9b;
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
  }

  .countdown {
    width: 80px;
    height: 22px;
    margin-top: 9px;
    margin-right: 9px;
    color: #fff;
    font-size: 14px;
    font-family: Source Han Sans SC-Normal;
    background-image: linear-gradient(
      to right,
      #ff036a,
      #d764e0,
      #6e2ff4,
      #1a56fe
    );
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .countdown-disable {
    width: 80px;
    height: 22px;
    margin-top: 9px;
    margin-right: 9px;
    color: rgb(146, 146, 146);
    font-size: 12px;
    font-family: Source Han Sans SC-Normal;
    background-color: #fff;
    border-radius: 18px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
