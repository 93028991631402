/*
 * 店铺 - API
 */

import request from '@/utils/request';

//获取店铺信息数据
export function getShopDataApi(params) {
    return request({
        url: '/api/interlib-content/shop/info',
        method: 'GET',
        params
    });
}

//获取接待大厅list数据
export function getSaloonListDataApi(data) {
    return request({
        url: '/api/interlib-content/shop/subject_film_list',
        method: 'POST',
        data
    });
}

//获取接待大厅筛选条件areaData数据
export function getSaloonAreaDataApi(params) {
    return request({
        url: '/api/interlib-content/shop/region_list',
        method: 'GET',
        params
    });
}

//获取VIP大厅所需权限数据
export function getVipPowerApi() {
    return request({
        url: '/api/interlib-content/shop/is_vip',
        method: 'GET',
    });
}

//获取店铺首页banner
export function getBannerApi(params) {
    return request({
        url: '/api/interlib-content/shop/banner_list',
        method: 'GET',
        params
    });
}

//获取店铺首页合集list
export function getSubjectListApi(params) {
    return request({
        url: '/api/interlib-content/shop/subject_list',
        method: 'GET',
        params
    });
}

//获取店铺接待室 申请VIP
export function getApplyVipApi(params) {
    return request({
        url: '/api/interlib-content/shop/apply_vip',
        method: 'GET',
        params
    });
}

//获取店铺详情 留言提交接口
export function handleMessageSubmitApi(params) {
    return request({
        url: '/api/interlib-content/shop/message',
        method: 'GET',
        params
    });
}

//获取预上传链接-api
export function putPresignedUrlApi(params) {
    return request({
        url: '/api/interlib-sys/sys/file/signBucket',
        method: 'GET',
        params
    });
}

//contactUs接口
export function contactUsApi(data) {
    return request({
      url: '/api/interlib-content/contactUs',
      method: 'POST',
      data
    });
}

//历史记录接口
export function historyApi() {
    return request({
      url: '/api/interlib-content/content/history',
      method: 'POST',
    });
}

//联系我们(片单时长数据）- api
export function contactUsSumUrlApi() {
    return request({
        url: '/api/interlib-content/contactUs/sum',
        method: 'GET'
    });
}

//申请使用接口
export function tryForFreeApi(data) {
    return request({
      url: '/api/interlib-sys/trial_apply/submit_apply',
      method: 'POST',
      data
    });
}