/*
 * 电子名片Card API
 */

import request from '@/utils/request';


// 电子名片-接口
export function cardDetailApi(data) {
    return request({
      url: '/api/interlib-content/card/cardInfo',
      method: 'POST',
      data
    });
}